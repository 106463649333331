import React, { useEffect, useState } from "react";
import UserService from "../../services/userService";
import { useDispatch, useSelector } from "react-redux";
import { updpateUserInfo } from "../../actions/User";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { logout } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const ProfilePage = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [myProfileData, setMyProfileData] = useState({});
  const { user: currentUser } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch clients from the backend
  useEffect(() => {
    const fetchMyprofile = async () => {
      try {
        const response = await UserService.fetchMyprofile();
        console.log(response.data.myProfile);
        setMyProfileData(response.data.myProfile);
        setName(response.data.myProfile.user_name);
      } catch (error) {
        console.error("Error fetching clients", error);
      }
    };
    fetchMyprofile();
  }, []);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  // Function to handle save changes
  const handleSaveChanges = (e) => {
    // Show confirmation popup
    const confirmUpdate = window.confirm(
      "Are you sure you want to update your information?"
    );

    if (confirmUpdate && passwordValid) {
      // Simulate API call
      dispatch(updpateUserInfo(name, password))
        .then((data) => {
          // Handle successful response
          alert("Information updated successfully!");
        })
        .catch((error) => {
          // Handle error response
          console.error("Error updating information:", error);
          alert("Failed to update information. Please try again.");
        });
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };

  const validatePassword = (password) => {
    const isValid = password.length >= 8 && /[\W_]/.test(password); // At least 8 chars and one special character
    setPasswordValid(isValid);
  };

  const handleLogout = () => {
    dispatch(logout())
      .then(() => navigate("/login"))
      .catch((error) => {
        console.log(error);
      });
  };

  if (!currentUser) {
    return (
      <>
        <Helmet>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="Type some info" />
          <meta name="author" content="Type name" />

          <title></title>
        </Helmet>
        <Navbar />
        <div className="login-popup d-flex justify-content-center align-items-center vh-100 bg-light">
          <div className="popup-content p-4 bg-white shadow rounded text-center">
            <h3 className="mb-3">Login Required</h3>
            <p className="mb-4">Please log in or register to view your cart.</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary me-2"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => navigate("/registrationForm")}
              >
                Register
              </button>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div className="container mt-5 pb-5">
        <h2 className="text-center mb-4">Profile Page</h2>
        <div className="d-flex justify-content-end">
          <button onClick={handleLogout} className="btn btn-outline-secondary">
            Logout
          </button>
        </div>

        {/* Tabs */}

        <div className="card p-4 mt-3 shadow-sm">
          <div className="col-md-6 pb-5">
            <div className="form-group">
              <strong>Customer Code: </strong>&nbsp;{" "}
              {myProfileData.user_code_d365}
            </div>
          </div>
          <h5>Personal Information</h5>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <strong>Username: </strong>&nbsp; {myProfileData.user_name}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </button>
                  </div>
                </div>
              </div>
              {!passwordValid && (
                <small className="text-danger">
                  Password must be at least 8 characters long and include a
                  special character.
                </small>
              )}
            </div>
          </div>

          <h5 className="mt-4">Additional Information</h5>

          <div className="row">
            <div className="col-md-6">
              <p>
                <strong>First Name:</strong> {myProfileData.firstName}
              </p>
              <p>
                <strong>Last Name:</strong> {myProfileData.lastName}
              </p>
              <p>
                <strong>Company Name:</strong> {myProfileData.user_company_name}
              </p>
              <p>
                <strong>City:</strong> {myProfileData.city}
              </p>
              <p>
                <strong>State:</strong> {myProfileData.state}
              </p>
              <p>
                <strong>Country:</strong> {myProfileData.country}
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <strong>Email:</strong> {myProfileData.email_add}
              </p>
              <p>
                <strong>Mobile Number:</strong> {myProfileData.mobile_number}
              </p>
              <p>
                <strong>Business Type:</strong>{" "}
                {myProfileData.select_business_type}
              </p>
              <p>
                <strong>Estimated MRO Spend:</strong>{" "}
                {myProfileData.estimated_mro_spend}
              </p>
              <p>
                <strong>Industry:</strong> {myProfileData.select_industry}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <p>
                <strong>Sonepar Sales Associates:</strong>{" "}
                {myProfileData.sonepar_sales_associates}
              </p>
              <p>
                <strong>Nature of Business:</strong>{" "}
                {myProfileData.nature_of_business}
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <strong>Number of Employees:</strong>{" "}
                {myProfileData.no_of_employee}
              </p>
              <p>
                <strong>Products Looking For:</strong>{" "}
                {myProfileData.product_looking_for}
              </p>
            </div>
          </div>

          <button className="btn btn-primary mt-3" onClick={handleSaveChanges}>
            Save Changes
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfilePage;
