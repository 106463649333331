import React from "react";

const PaginationFooter = ({ page, limit, setPage, setLimit, totalPages }) => {
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };
  const renderPageNumbers = () => {
    const pages = [];
    const delta = 2; // Number of pages to show before and after the current page

    // Determine the page numbers to display
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 || // Always show the first page
        i === totalPages || // Always show the last page
        (i >= page - delta && i <= page + delta) // Show a few pages around the current page
      ) {
        pages.push(
          <li key={i} className={`page-item ${page === i ? "active" : ""}`}>
            {page === i ? (
              <span className="page-link">{i}</span>
            ) : (
              <button className="page-link" onClick={() => handlePageChange(i)}>
                {i}
              </button>
            )}
          </li>
        );
      } else if (
        i === page - delta - 1 || // Ellipsis before current range
        i === page + delta + 1 // Ellipsis after current range
      ) {
        pages.push(
          <li key={i} className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
      }
    }

    return pages;
  };
  return (
    <footer className="d-flex my-4">
      <div className="ms-auto me-3">
        <select
          name="page_limit"
          className="form-select"
          value={limit}
          onChange={(e) => {
            setLimit(e.target.value);
          }}
        >
          <option value="10">Show 10</option>
          <option value="20">Show 20</option>
          <option value="40">Show 40</option>
          <option value="100">Show 100</option>
        </select>
      </div>
      <nav>
        <ul className="pagination">
          <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              Prev
            </button>
          </li>
          {/* Page Numbers */}
          {renderPageNumbers()}
          <li className={`page-item ${page === totalPages ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default PaginationFooter;
