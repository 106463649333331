import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const HelperFunction = {
  encrypt: (text) => {
    return CryptoJS.AES.encrypt(text.toString(), SECRET_KEY).toString();
  },

  decrypt: (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  },
};

export default HelperFunction;
