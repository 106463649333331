import React from "react";

const FilteredElements = ({
  selectedFilters,
  removeFilter,
  clearAllFilter,
}) => {
  return (
    <div className="mb-3">
      {selectedFilters?.map((filter) => (
        <span
          key={filter.type + "-" + filter.id}
          className="chips border bg-gray-light"
        >
          {filter.name}{" "}
          <button
            type="button"
            className="custom-close-btn py-0"
            aria-label="Remove filter"
            onClick={() => removeFilter(filter.type, filter.id)}
          >
            &times;
          </button>
        </span>
      ))}

      {selectedFilters.length > 0 && (
        <button
          type="button"
          className="custom-close-btn py-0 "
          aria-label="Remove filter"
          style={{ fontSize: "1rem" }}
          onClick={clearAllFilter}
        >
          Clear all filter
        </button>
      )}
    </div>
  );
};

export default FilteredElements;
