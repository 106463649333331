import React from "react";

const Footer = () => {
  return (
    // <footer classNameName="section-footer footer-dark bg-dark">
    //   <div classNameName="container">
    //     <section classNameName="footer-main padding-y">
    //       <div classNameName="row">
    //         <aside classNameName="col-12 col-sm-12 col-lg-4">
    //           <article classNameName="me-lg-5">
    //             <img
    //               src="logo-white.png"
    //               height="44"
    //               classNameName="logo-footer"
    //               alt="Logo"
    //             />
    //             <p classNameName="mt-3 text-white-50">
    //               Here is some information about our company. We have been
    //               working since 1990 and still growing. This is just a good HTML
    //               template.
    //             </p>
    //           </article>
    //         </aside>
    //         <aside classNameName="col-6 col-sm-4 col-lg-2">
    //           <h6 classNameName="title">Quick Links</h6>
    //           <ul classNameName="list-menu mb-4">
    //             <li>
    //               <a href="#">About us</a>
    //             </li>
    //             <li>
    //               <a href="/shop">shop</a>
    //             </li>
    //             <li>
    //               <a href="#">Contact Us</a>
    //             </li>
    //           </ul>
    //         </aside>

    //         <aside classNameName="col-6 col-sm-4 col-lg-2"></aside>

    //         <aside classNameName="col-6 col-sm-4 col-lg-2">
    //           <h6 classNameName="title">Information</h6>
    //           <ul classNameName="list-menu mb-4">
    //             <li>
    //               <a href="#">Privacy Policy</a>
    //             </li>
    //             <li>
    //               <a href="#">Terms and Condition</a>
    //             </li>
    //           </ul>
    //         </aside>
    //       </div>
    //     </section>
    //     <hr classNameName="my-0" />
    //     <section classNameName="footer-bottom d-flex justify-content-between">
    //       <div classNameName="text-white-50">© 2018- 2023 Templatemount.</div>
    //     </section>
    //   </div>
    // </footer>

    <footer className="section-footer bg-blue-light">
      <div className="container">
        <section className="footer-main padding-y">
          <div className="row">
            <aside className="col-12 col-sm-12 col-lg-4">
              <article className="me-lg-4">
                <img
                  src="/Sonepar-logo-White-RGB-600x183.png"
                  height="200!"
                  className="logo-footer"
                />
                <p className="mt-3 text-white">
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; About Sonepar Web Shop - Quick
                  Line{" "}
                </p>
                <nav className="mb-4">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;;&nbsp;
                  <a
                    className="btn btn-icon btn-light"
                    title="Facebook"
                    target="_blank"
                    href="https://www.facebook.com/soneparindia"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className="btn btn-icon btn-light mx-2"
                    title="Instagram"
                    target="_blank"
                    href="https://www.instagram.com/soneparindia/"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    className="btn btn-icon btn-light"
                    title="Linkedin"
                    target="_blank"
                    href="https://www.linkedin.com/company/soneparindia/"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a
                    className="btn btn-icon btn-light mx-2"
                    title="Linkedin"
                    target="_blank"
                    href="https://x.com/SoneparIndia/"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </nav>
              </article>
            </aside>
            <aside className="col-6 col-sm-4 col-lg-2">
              <h6 className="title-white"> &nbsp;&nbsp;&nbsp;&nbsp;Store</h6>
              <ul className="list-menu-white mb-4 ">
                <li>
                  {" "}
                  <a href="#">About us</a>
                </li>

                <li>
                  {" "}
                  <a href="#">Categories</a>
                </li>
                <li>
                  {" "}
                  <a href="#">Shop</a>
                </li>
              </ul>
            </aside>
            <aside className="col-6 col-sm-4 col-lg-2">
              <h6 className="title-white">
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp;Information
              </h6>
              <ul className="list-menu-white  mb-4">
                <li>
                  {" "}
                  <a href="#">Help center</a>
                </li>

                <li>
                  {" "}
                  <a href="#">Shipping info</a>
                </li>
                <li>
                  {" "}
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  {" "}
                  <a href="#">Disclaimer</a>
                </li>
              </ul>
            </aside>
            <aside className="col-6 col-sm-4  col-lg-2">
              <h6 className="title-white"> &nbsp;&nbsp;&nbsp;&nbsp;Support</h6>
              <ul className="list-menu-white  mb-4">
                <li>
                  {" "}
                  <a href="#"> Help center </a>
                </li>
                <li>
                  {" "}
                  <a href="#"> Documents </a>
                </li>

                <li>
                  {" "}
                  <a href="#"> My Orders </a>
                </li>
              </ul>
            </aside>
          </div>
        </section>
        <hr className="my-0" />
        <section className="footer-bottom d-flex justify-content-between">
          <div className="title-white">© 2020- 2024 Sonepar.</div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
