import React, { useEffect, useMemo, useState } from "react";
import ModService from "../../../services/modService";
import { useTable, useSortBy } from "react-table";
import ReactPaginate from "react-paginate";

const QuickQuery = () => {
  const [queries, setQueries] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchQueries = async () => {
      setLoading(true);
      try {
        const response = await ModService.getQueryMessage({
          page: currentPage + 1,
          limit: 10,
        });
        setQueries(response.data.queries);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching sales orders", error);
      }
      setLoading(false);
    };

    fetchQueries();
  }, [currentPage]);

  const columns = useMemo(
    () => [
      { Header: "Name", accessor: "name" },
      { Header: "Email", accessor: "email" },
      { Header: "Phone", accessor: "phone" },
      { Header: "Company", accessor: "company_name" },
      { Header: "Message", accessor: "message" },
    ],
    []
  );

  const data = useMemo(() => queries, [queries]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Queries</h1>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <table
            {...getTableProps()}
            className="table table-hover table-bordered"
          >
            <thead className="table">
              {headerGroups.map((headerGroup, headerGroupIndex) => {
                const { key, ...rest } = headerGroup.getHeaderGroupProps(); // Extract the key
                return (
                  <tr key={`headerGroup-${headerGroupIndex}`} {...rest}>
                    {headerGroup.headers.map((column, columnIndex) => {
                      const { key, ...restColumnProps } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <th
                          key={`header-${columnIndex}`} // Use key explicitly
                          {...restColumnProps} // Spread the rest of the props
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                const { key, ...rest } = row.getRowProps(); // Destructure key
                return (
                  <tr key={`row-${rowIndex}`} {...rest}>
                    {row.cells.map((cell, cellIndex) => {
                      const { key, ...restCellProps } = cell.getCellProps(); // Destructure key for cells
                      return (
                        <td
                          key={`cell-${rowIndex}-${cellIndex}`}
                          {...restCellProps}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default QuickQuery;
