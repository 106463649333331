import React, { useState, useEffect } from "react";
import ModService from "../../../services/modService"; // Make sure to update ModService for Industry endpoints

const IndustryPage = () => {
  const [industries, setIndustries] = useState([]); // State to hold industries
  const [editingId, setEditingId] = useState(null); // ID of the item being edited
  const [editName, setEditName] = useState(""); // New name for editing
  const [newName, setNewName] = useState(""); // New industry to be added

  // Fetch industries on component mount
  useEffect(() => {
    fetchIndustries();
  }, []);

  const fetchIndustries = async () => {
    try {
      const response = await ModService.getIndustries(); // Replace with your API endpoint
      setIndustries(response.data);
    } catch (error) {
      console.error("Error fetching industries:", error);
    }
  };

  const handleEdit = (id, name) => {
    setEditingId(id);
    setEditName(name);
  };

  const handleSave = async (id) => {
    try {
      await ModService.updateIndustry(id, editName); // Replace with your API endpoint
      setEditingId(null);
      fetchIndustries(); // Refresh the list
    } catch (error) {
      console.error("Error updating industry:", error);
    }
  };

  const handleAdd = async () => {
    if (!newName.trim()) {
      alert("Please enter a valid name.");
      return;
    }

    try {
      await ModService.addIndustry(newName); // Replace with your API endpoint
      setNewName("");
      fetchIndustries(); // Refresh the list
    } catch (error) {
      console.error("Error adding industry:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this industry?")) {
      try {
        await ModService.deleteIndustry(id); // Replace with your API endpoint
        fetchIndustries(); // Refresh the list
      } catch (error) {
        console.error("Error deleting industry:", error);
      }
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Manage Industries</h2>

      {/* Add new industry */}
      <div className="input-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Add new industry"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleAdd}>
          Add
        </button>
      </div>

      {/* Industries table */}
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {industries.map((industry) => (
            <tr key={industry.id}>
              <td>{industry.id}</td>
              <td>
                {editingId === industry.id ? (
                  <input
                    type="text"
                    className="form-control"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                  />
                ) : (
                  industry.name
                )}
              </td>
              <td>
                {editingId === industry.id ? (
                  <>
                    <button
                      className="btn btn-success btn-sm me-2"
                      onClick={() => handleSave(industry.id)}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={() => setEditingId(null)}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-warning btn-sm me-2"
                      onClick={() => handleEdit(industry.id, industry.name)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(industry.id)}
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IndustryPage;
