import React, { useState } from "react";
import "../../stylings/css/Login.css";
import Navbar from "../../components/Navbar/Navbar";
import { login } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import QuickQuery from "../../components/common/QuickQuery";
import UserService from "../../services/userService";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleSubmitQuickQuery = async (data) => {
    try {
      await UserService.submitQueryForm(data);
      alert(
        "Your message has been sent successfully, Our team will contact you regarding this as soon as possible"
      );
    } catch (error) {
      alert("Something went wrong, Please try again.");
      console.log(error);
    }
  };

  const { errorMessage } = useSelector((state) => state.message);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(login(username?.toLowerCase(), password))
      .then((res) => {
        navigate(res);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <Navbar />

      <div className="login-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2>Login</h2>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div>
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Login</button>
          <label className="py-2">
            Not Registered? click here to{" "}
            <a href="/registrationForm">register</a>
          </label>

          <label className="text-end">
            <a href="/forgot-password">Forgot Password?</a>
          </label>
        </form>
        <div className="row justify-content-center mt-5">
          <div className="col-auto text-center">
            <button
              className="btn btn-link p-0 text-decoration-none custom-link"
              onClick={handleShow}
              style={{ color: "orange", background: "none" }}
            >
              For any query, click here to fill the form
            </button>
          </div>
        </div>
        <QuickQuery
          show={showModal}
          handleClose={handleClose}
          handleSubmit={handleSubmitQuickQuery}
        />
      </div>
      <Footer />
    </>
  );
};

export default Login;
