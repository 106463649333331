import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const TermsAndCondition = () => {
  return (
    <>
      <Navbar />
      <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
        <h1>Terms and Conditions</h1>
        <p>Last updated: December 4, 2024</p>

        <p>
          Welcome to Sonepar India Please read these terms and conditions
          carefully before using our service.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using our service, you agree to be bound by these
          terms and conditions. If you do not agree to these terms, you may not
          access or use the service.
        </p>

        <h2>2. Use of Service</h2>
        <p>
          You agree to use our service only for lawful purposes and in
          accordance with these terms. Prohibited uses include, but are not
          limited to:
        </p>
        <ul>
          <li>Engaging in any illegal activities.</li>
          <li>
            Attempting to gain unauthorized access to our systems or networks.
          </li>
          <li>Disrupting the functionality of our service.</li>
        </ul>

        <h2>3. User Accounts</h2>
        <p>
          If you create an account on our platform, you are responsible for
          maintaining the confidentiality of your account information. You agree
          to notify us immediately of any unauthorized use of your account.
        </p>

        <h2>4. Intellectual Property</h2>
        <p>
          All content and materials on our platform, including but not limited
          to text, graphics, logos, and software, are the property of [Your
          Application Name] or its licensors and are protected by copyright and
          other intellectual property laws.
        </p>

        <h2>5. Termination</h2>
        <p>
          We reserve the right to suspend or terminate your access to our
          service at any time for any reason, including violation of these
          terms.
        </p>

        <h2>6. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, [Your Application Name] shall
          not be liable for any indirect, incidental, or consequential damages
          arising from your use of the service.
        </p>

        <h2>7. Governing Law</h2>
        <p>
          These terms shall be governed by and construed in accordance with the
          laws of [Your Jurisdiction].
        </p>

        <h2>8. Changes to Terms</h2>
        <p>
          We reserve the right to update or modify these terms at any time. We
          will notify you of any changes by posting the new terms on this page.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndCondition;
