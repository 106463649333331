import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, updateCartItem } from "../../actions/cart";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { submitOrder } from "../../actions/User";
import HelperFunction from "../../services/helperFunction";
import UserService from "../../services/userService";

// Cart Page Component
const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [updatedCartItems, setUpdatedCartItems] = useState([]);
  const [editedQuantity, setEditedQuantity] = useState({});
  const { user: currentUser } = useSelector((state) => state.auth);
  const [message, setMessage] = useState(null);
  useEffect(() => {
    if (!currentUser) return;

    UserService.getCart().then((cartItems) => {
      const itemsWithCalculations = cartItems.data.cart.map((item) => ({
        ...item,
        totalAmount: +item.quantity * +item.price,
        totalGst: +item.quantity * (+item.price * +item.tax * 0.01),
      }));
      setUpdatedCartItems(itemsWithCalculations);
      calculateTotals(itemsWithCalculations);
    });
  }, [currentUser]);

  const calculateTotals = (items) => {
    const totalAmount = items.reduce(
      (sum, item) => sum + +item.totalAmount + +item.totalGst,
      0
    );
    const totalQuantity = items.reduce((sum, item) => sum + +item.quantity, 0);

    setTotalAmount(totalAmount);
    setTotalQuantity(totalQuantity);
  };

  const handleRemoveItem = (itemId) => {
    dispatch(removeFromCart(itemId))
      .then(() => {
        const filteredItems = updatedCartItems.filter(
          (item) => item.product_id !== itemId
        );
        setUpdatedCartItems(filteredItems);
        calculateTotals(filteredItems);
        alert("Item removed successfully");
      })
      .catch((error) => {
        alert("Something went wrong, please try again later");
      });
  };

  const handleUpdateQuantity = (itemId, quantity) => {
    if (quantity > 0) {
      dispatch(updateCartItem({ id: itemId, quantity }))
        .then(() => {
          const updatedItems = updatedCartItems.map((item) =>
            item.product_id === itemId
              ? {
                  ...item,
                  quantity,
                  totalAmount: +quantity * +item.price,
                  totalGst: +quantity * (+item.price * +item.tax * 0.01),
                }
              : item
          );
          setUpdatedCartItems(updatedItems);
          calculateTotals(updatedItems);
          setEditedQuantity((prev) => ({ ...prev, [itemId]: undefined }));
          alert("Item quantity updated successfully");
        })
        .catch((error) => {
          alert("Something went wrong, please try again later");
          console.log(error);
        });
    }
  };

  const handleQuantityChange = (itemId, quantity) => {
    setEditedQuantity((prev) => ({
      ...prev,
      [itemId]: quantity,
    }));
  };

  const handleSubmitOrder = () => {
    const total_amount_without_gst = updatedCartItems.reduce(
      (sum, item) => sum + +item.totalAmount,
      0
    );
    const gst = updatedCartItems.reduce((sum, item) => sum + +item.totalGst, 0);

    const finalCartItem = {
      total_amount: total_amount_without_gst,
      gst: gst,
      net_amount: totalAmount,
      total_quantity: totalQuantity,
      items: [...updatedCartItems],
    };

    dispatch(submitOrder(finalCartItem))
      .then(() => {
        alert("Order placed successfully");
        setUpdatedCartItems([]);
        setTotalAmount(0);
        setTotalQuantity(0);
        setMessage(
          "Order submitted successfully, You can continue shopping or you can check your order status in my orders"
        );
      })
      .catch((error) => {
        console.log(error);
        alert("Something went wrong, please try again in sometime.");
      });
  };

  if (!currentUser) {
    return (
      <>
        <Helmet>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="Type some info" />
          <meta name="author" content="Type name" />

          <title></title>
        </Helmet>
        <Navbar />
        <div className="login-popup d-flex justify-content-center align-items-center vh-100 bg-light">
          <div className="popup-content p-4 bg-white shadow rounded text-center">
            <h3 className="mb-3">Login Required</h3>
            <p className="mb-4">Please log in or register to view your cart.</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary me-2"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => navigate("/registrationForm")}
              >
                Register
              </button>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="Type some info" />
        <meta name="author" content="Type name" />

        <title>Website layout sample </title>
      </Helmet>
      <Navbar />
      <section className="cart-page container min-vh-100">
        {message ? (
          <h2 className="mb-4 text-center mt-5">{message}</h2>
        ) : (
          <>
            <h2 className="mb-4 text-center">Shopping Cart</h2>
            <div className="cart-items">
              {updatedCartItems.map((item) => (
                <div
                  key={item.product_id}
                  className="cart-item card mb-3 shadow-sm border-0"
                >
                  <div className="card-body d-flex justify-content-between align-items-center">
                    {/* Thumbnail Section */}
                    <div className="item-thumbnail me-3">
                      <img
                        src={
                          process.env.REACT_APP_BACKEND_SERVER +
                          "/images/" +
                          item.main_pics
                        } // Use a default image if thumbnail is not available
                        alt={item.name}
                        className="img-thumbnail"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }} // Adjust size and fit
                      />
                    </div>

                    <div className="item-info">
                      <Link
                        to={`/product/${encodeURIComponent(
                          HelperFunction.encrypt(item.product_id)
                        )}`}
                      >
                        <h5 className="card-title">{item.name}</h5>
                      </Link>
                      <p className="card-text">Price: ₹ {item.price}</p>
                      <p className="card-text">Tax: {item.tax}%</p>
                      <p className="card-text">
                        Total GST: ₹ {item.totalGst.toFixed(2)}
                      </p>
                      <p className="card-text">
                        Total Price (including tax): ₹{" "}
                        {(+item.totalAmount + +item.totalGst).toFixed(2)}
                      </p>
                    </div>
                    <div className="item-controls d-flex align-items-center">
                      <input
                        type="number"
                        min={item.moq}
                        value={editedQuantity[item.product_id] ?? item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(
                            item.product_id,
                            parseInt(e.target.value, 10)
                          )
                        }
                        className="form-control me-2"
                      />
                      <button
                        className="btn btn-secondary me-2"
                        onClick={() =>
                          handleUpdateQuantity(
                            item.product_id,
                            editedQuantity[item.product_id] ?? item.quantity
                          )
                        }
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleRemoveItem(item.product_id)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="cart-summary mt-4 text-center">
              <h4>Total Items: {totalQuantity}</h4>
              <h4>Total Price: ₹ {totalAmount.toFixed(2)}</h4>
              {totalQuantity !== 0 && (
                <button
                  className="btn btn-success mt-4"
                  onClick={handleSubmitOrder}
                >
                  Submit Order
                </button>
              )}
            </div>
          </>
        )}
      </section>

      <Footer />
    </>
  );
};

export default Cart;
