import api from "./api";

const registerUser = (userData) => {
  return api.post("/user/registerUser", {
    userData,
  });
};

const addToCart = (cartData) => {
  return api.post("/user/addToCart", {
    cartData,
  });
};

const removeFromCart = (cartData) => {
  return api.post("/user/removeFromCart", {
    cartData,
  });
};

const clearCart = (cartData) => {
  return api.post("/user/clearCart", {
    cartData,
  });
};

const updateCartItem = (cartData) => {
  return api.post("/user/updateCartItem", {
    cartData,
  });
};

const submitOrder = (cartData) => {
  return api.post("/user/submitOrder", {
    cartData,
  });
};

const updpateUserInfo = (userData) => {
  return api.post("/user/updpateUserInfo", {
    userData,
  });
};

const getBrand = (params) => {
  return api.get("/user/getBrand", {
    params: params,
  });
};

const getCategory = (params) => {
  return api.get("/user/getCategory", {
    params: params,
  });
};

const getMaxPrice = (params) => {
  return api.get("/user/getMaxPrice", {
    params: params,
  });
};

const getProducts = (params) => {
  return api.get("/user/getProducts", {
    params: params,
  });
};

const getProductById = (params) => {
  return api.get("/user/getProductById", {
    params: params,
  });
};

const fetchMyprofile = (params) => {
  return api.get("/user/fetchMyprofile", {
    params: params,
  });
};

const fetchOrderHistory = (params) => {
  return api.get("/user/fetchOrderHistory", {
    params: params,
  });
};

const getCart = () => {
  return api.get("/user/getCart");
};

const getBanners = () => {
  return api.get("/user/getBanners");
};

const getBusinessType = () => {
  return api.get("/user/business-type");
};

const getIndustries = () => {
  return api.get("/user/industry");
};

const getEmployeeOptions = () => {
  return api.get("/user/employee-options");
};

const getMROOptions = () => {
  return api.get("/user/mro-spend");
};

const getProductNeededOptions = () => {
  return api.get("/user/product-needed");
};

const submitQueryForm = (formData) => {
  return api.post("/user/submit-query", formData);
};
//Update Data

const UserService = {
  registerUser,
  addToCart,
  removeFromCart,
  clearCart,

  updateCartItem,
  updpateUserInfo,

  getBrand,
  getCategory,
  getMaxPrice,
  getProducts,
  getProductById,
  fetchMyprofile,
  fetchOrderHistory,
  getBanners,
  getCart,
  submitOrder,

  getBusinessType,
  getIndustries,
  getEmployeeOptions,
  getMROOptions,
  getProductNeededOptions,
  submitQueryForm,
};

export default UserService;
