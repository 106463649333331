import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth";
import message from "./message";
import cart from "./Cart";

export default combineReducers({
  auth,
  message,
  cart,
});
