import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ModService from "../../../services/modService"; // Service to fetch/update employee data
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Icons for password toggle
import { updateEmployeeProfile } from "../../../actions/modertor"; // Redux action to update profile

const ProfilePage = () => {
  const dispatch = useDispatch();

  // State for employee data and password fields
  const [employeeData, setEmployeeData] = useState({
    user_name: "",
    email_add: "",
    mobile_number: "",
    password: "", // New password field
  });

  // State for password visibility toggle
  const [showPassword, setShowPassword] = useState(false);

  // Fetch employee profile data on component mount
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await ModService.getEmployeeProfile(); // Assuming this API fetches the employee data
        setEmployeeData({
          ...response.data.user[0],
          password: "", // Password is kept blank initially
        });
      } catch (error) {
        console.error("Error fetching employee data", error);
      }
    };

    fetchEmployeeData();
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle password toggle visibility
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Handle form submission to update employee info and password
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(updateEmployeeProfile(employeeData))
      .then(() => {
        alert("Profile updated successfully!");
        setEmployeeData((prevData) => ({
          ...prevData,
          password: "",
        }));
        // Optionally, refetch employee data to reflect changes
      })
      .catch((error) => {
        console.error("Error updating profile", error);
      });
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow p-4">
            <h2 className="text-center mb-4">Employee Profile</h2>
            <form onSubmit={handleSubmit}>
              {/* Username - can't be changed */}
              <div className="mb-3">
                <label className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control"
                  name="user_name"
                  value={employeeData.user_name}
                  readOnly // Make this field read-only
                />
              </div>

              {/* Email - can't be changed */}
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email_add"
                  value={employeeData.email_add}
                  readOnly // Make this field read-only
                />
              </div>

              {/* Mobile Number - editable */}
              <div className="mb-3">
                <label className="form-label">Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile_number"
                  value={employeeData.mobile_number}
                  onChange={handleChange}
                />
              </div>

              {/* Password - changeable */}
              <div className="mb-3 position-relative">
                <label className="form-label">Change Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    name="password"
                    value={employeeData.password}
                    onChange={handleChange}
                    placeholder="Enter new password"
                  />
                  {/* Toggle show/hide password */}
                  <span
                    className="input-group-text"
                    style={{ cursor: "pointer" }}
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>

              {/* Submit Button */}
              <button type="submit" className="btn btn-primary w-100">
                Update Profile
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
