import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addEmployee } from "../../../actions/modertor";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const AddEmployeePage = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [mobileValid, setMobileValid] = useState(true);
  const [errorMessages, setErrorMessages] = useState({
    user_name: "",
    email_add: "",
    mobile_number: "",
  });
  const [employeeData, setEmployeeData] = useState({
    user_name: "",
    firstName: "",
    lastName: "",
    email_add: "",
    mobile_number: "",
    password: "",
    allowedPages: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Specific validations
    if (name === "password") validatePassword(value);
    if (name === "email_add") validateEmail(value);
    if (name === "mobile_number") validateMobile(value);
  };

  const handlePermissionsChange = (e) => {
    const { value, checked } = e.target;
    const pageId = parseInt(value, 10);

    setEmployeeData((prevData) => {
      if (checked) {
        return {
          ...prevData,
          allowedPages: prevData.allowedPages.includes(pageId)
            ? prevData.allowedPages
            : [...prevData.allowedPages, pageId],
        };
      } else {
        return {
          ...prevData,
          allowedPages: prevData.allowedPages.filter((id) => id !== pageId),
        };
      }
    });
  };

  const validatePassword = (password) => {
    const isValid = password.length >= 8 && /[\W_]/.test(password);

    if (!isValid) {
    }

    setPasswordValid(isValid);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    if (!isValid) {
      setErrorMessages((prev) => ({
        ...prev,
        email_add: "Email address not valid",
      }));
      setEmailValid(false);
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        email_add: null,
      }));
      setEmailValid(true);
    }
  };

  const validateMobile = (mobile) => {
    const mobileRegex = /^\d{10}$/;
    const isValid = mobileRegex.test(mobile);

    if (!isValid) {
      setErrorMessages((prev) => ({
        ...prev,
        mobile_number: "Mobile Number not valid",
      }));
      setMobileValid(false);
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        mobile_number: null,
      }));
      setMobileValid(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessages({});
    if (passwordValid && emailValid && mobileValid) {
      dispatch(addEmployee(employeeData))
        .then(() => {
          alert("Employee added successfully!");
          setEmployeeData({
            user_name: "",
            email_add: "",
            mobile_number: "",
            password: "",
            firstName: "",
            lastName: "",
            allowedPages: [],
          });
        })
        .catch((error) => {
          if (error.status === 501) {
            if (error.message.includes(1)) {
              setErrorMessages((prev) => ({
                ...prev,
                user_name: "Duplicate user_name",
              }));
            }
            if (error.message.includes(2)) {
              setErrorMessages((prev) => ({
                ...prev,
                email_add: "Duplicate email address",
              }));
            }
            if (error.message.includes(3)) {
              setErrorMessages((prev) => ({
                ...prev,
                mobile_number: "Duplicate mobile number",
              }));
            }
          } else {
            console.error("Error adding employee", error);
          }
        });
    } else {
      console.error("Please ensure all validations are met");
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow p-4">
            <h2 className="text-center mb-4">Add New Employee</h2>
            <form onSubmit={handleSubmit}>
              <div className="row">
                {/* Employee Name */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Employee Username<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="user_name"
                    className={`form-control ${
                      errorMessages.user_name ? "is-invalid" : ""
                    }`}
                    value={employeeData.user_name}
                    onChange={handleChange}
                    required
                  />
                  {errorMessages.user_name && (
                    <div className="invalid-feedback">
                      {errorMessages.user_name}
                    </div>
                  )}
                </div>

                {/* Email */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Email<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    name="email_add"
                    className={`form-control ${
                      errorMessages.email_add ? "is-invalid" : ""
                    }`}
                    value={employeeData.email_add}
                    onChange={(e) => {
                      handleChange(e);
                      validateEmail(e.target.value);
                    }}
                    required
                  />
                  {errorMessages.email_add && (
                    <div className="invalid-feedback">
                      {errorMessages.email_add}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    First Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className={`form-control `}
                    value={employeeData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Last Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className={`form-control `}
                    value={employeeData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* Mobile Number */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Mobile Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="mobile_number"
                    className={`form-control ${
                      errorMessages.mobile_number ? "is-invalid" : ""
                    }`}
                    value={employeeData.mobile_number}
                    onChange={(e) => {
                      handleChange(e);
                      validateMobile(e.target.value);
                    }}
                    required
                  />
                  {errorMessages.mobile_number && (
                    <div className="invalid-feedback">
                      {errorMessages.mobile_number}
                    </div>
                  )}
                </div>

                {/* Password with Show/Hide toggle */}
                <div className="col-md-6 mb-3 position-relative">
                  <label className="form-label">
                    Password<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className={`form-control ${
                        !passwordValid ? "is-invalid" : ""
                      }`}
                      value={employeeData.password}
                      onChange={handleChange}
                      required
                    />
                    <span
                      className="input-group-text"
                      style={{ cursor: "pointer" }}
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  {!passwordValid && (
                    <div className="invalid-feedback d-block">
                      Password must be at least 8 characters long and include a
                      special character.
                    </div>
                  )}
                </div>
              </div>

              {/* Permissions (Checkboxes) */}
              <div className="mb-3">
                <label className="form-label">Permissions</label>
                <div className="form-check">
                  {/* {pages.map((page) => ( */}
                  <div key={1} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={1}
                      id={`page-1`}
                      onChange={handlePermissionsChange}
                      checked={employeeData.allowedPages.includes(1)}
                    />
                    <label className="form-check-label" htmlFor={`page-1`}>
                      Dashboard
                    </label>
                  </div>
                  {/* ))} */}
                </div>
                <div className="form-check">
                  {/* {pages.map((page) => ( */}
                  <div key={2} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={2}
                      id={`page-2`}
                      onChange={handlePermissionsChange}
                      checked={employeeData.allowedPages.includes(2)}
                    />
                    <label className="form-check-label" htmlFor={`page-2`}>
                      Orders
                    </label>
                  </div>
                  {/* ))} */}
                </div>

                <div className="form-check">
                  {/* {pages.map((page) => ( */}
                  <div key={3} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={3}
                      id={`page-3`}
                      onChange={handlePermissionsChange}
                      checked={employeeData.allowedPages.includes(3)}
                    />
                    <label className="form-check-label" htmlFor={`page-3`}>
                      Products
                    </label>
                  </div>
                  {/* ))} */}
                </div>

                <div className="form-check">
                  {/* {pages.map((page) => ( */}
                  <div key={4} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={4}
                      id={`page-4`}
                      onChange={handlePermissionsChange}
                      checked={employeeData.allowedPages.includes(4)}
                    />
                    <label className="form-check-label" htmlFor={`page-4`}>
                      Clients
                    </label>
                  </div>
                  {/* ))} */}
                </div>

                <div className="form-check">
                  {/* {pages.map((page) => ( */}
                  <div key={5} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={5}
                      id={`page-5`}
                      onChange={handlePermissionsChange}
                      checked={employeeData.allowedPages.includes(5)}
                    />
                    <label className="form-check-label" htmlFor={`page-5`}>
                      Admin
                    </label>
                  </div>
                  {/* ))} */}
                </div>

                <div className="form-check">
                  {/* {pages.map((page) => ( */}
                  <div key={6} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={6}
                      id={`page-6`}
                      onChange={handlePermissionsChange}
                      checked={employeeData.allowedPages.includes(6)}
                    />
                    <label className="form-check-label" htmlFor={`page-6`}>
                      Reports
                    </label>
                  </div>
                  {/* ))} */}
                </div>
              </div>

              {/* Submit Button */}
              <button type="submit" className="btn btn-primary w-100">
                Add Employee
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmployeePage;
