import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ element: Component }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  // console.log(currentUser);

  if (currentUser) {
    return <Navigate to={currentUser.link} replace />;
  }

  return <Component />;
};

export default PublicRoute;
