// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// Import Bootstrap Bundle JS (which includes Popper.js)

import "./stylings/css/bootstrap.css?v=2.0";

import "./stylings/css/ui.css?v=2.0";
import "./stylings/css/responsive.css?v=2.0";

import "./stylings/fonts/fontawesome/css/all.min.css";

import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { RouterProvider } from "react-router-dom";
import router from "./router";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary"; // Import the ErrorBoundary
export default function App() {
  return (
    <div className="bg-light">
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </div>
  );
}
