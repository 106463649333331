import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ModService from "../../../services/modService";
import { updateSalesOrder } from "../../../actions/modertor";
import AsyncSelect from "react-select/async"; // For async dropdowns

const EditSalesOrderPage = ({
  salesOrder,
  setIsUpdated,
  handleEditCloseModal,
}) => {
  const dispatch = useDispatch();
  // console.log(salesOrder);
  // Sales order data state initialized with existing data
  const [salesOrderData, setSalesOrderData] = useState({
    id: salesOrder?.id || "",
    user_company_name: salesOrder?.user_company_name || "",
    firstName: salesOrder?.firstName || "",
    lastName: salesOrder?.lastName || "",
    user_code_d365: salesOrder?.client_name || "",
    client_id: salesOrder?.user_id || "", // Initialize with existing client_id
    sales_order_number: salesOrder?.sales_order_number || "",
    tracking_details: salesOrder?.tracking_details || "",
    invoice_details: salesOrder?.invoice_details || "",
    items: salesOrder?.items || [
      {
        product_id: "",
        rate: 0,
        net_amount: 0,
        gst: 0,
        gst_amount: 0,
        quantity: 0,
      },
    ],
    quantity: salesOrder?.quantity || 0,
    total_net_amount: salesOrder?.total_net_amount || 0,
    total_gst: salesOrder?.total_gst || 0,
    total_amount: salesOrder?.total_amount || 0,
  });

  const [totalQuantity, setTotalQuantity] = useState(
    salesOrder?.total_quantity || 0
  );
  const [totalNetAmount, setTotalNetAmount] = useState(
    salesOrder?.net_amount || 0
  );
  const [totalGST, setTotalGST] = useState(salesOrder?.gst || 0);
  const [totalAmount, setTotalAmount] = useState(salesOrder?.total_amount || 0);

  // Fetch product options for auto-complete
  const fetchProducts = async (inputValue) => {
    const response = await ModService.getProduct({ search: inputValue });
    return response.data.products.map((product) => ({
      value: product.id,
      label: product.product,
      rate: product.call_price || 0, // Set a default value to avoid uncontrolled warning
      gst: product.gst || 0, // Set a default value to avoid uncontrolled warning
    }));
  };

  // Handle product select in the sublist
  const handleProductSelect = (index, selectedOption) => {
    const updatedItems = [...salesOrderData.items];
    updatedItems[index].product_id = selectedOption.value || "";
    updatedItems[index].rate = selectedOption.rate || 0;
    updatedItems[index].gst = selectedOption.gst || 0;

    setSalesOrderData({ ...salesOrderData, items: updatedItems });
    calculateTotals(updatedItems);
  };

  // Add new item to the list
  const addItem = () => {
    setSalesOrderData({
      ...salesOrderData,
      items: [
        ...salesOrderData.items,
        {
          product_id: "",
          rate: 0,
          net_amount: 0,
          gst: 0,
          gst_amount: 0,
          quantity: 0,
        },
      ],
    });
  };

  // Handle item change
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...salesOrderData.items];
    updatedItems[index][field] = value || 0;

    if (field === "quantity" || field === "rate") {
      const netAmount = (
        +updatedItems[index].rate * +updatedItems[index].quantity
      ).toFixed(2);
      const gstAmount = (
        +netAmount * +(+updatedItems[index].gstPercentage / 100)
      ).toFixed(2);
      updatedItems[index].net_amount = netAmount;
      updatedItems[index].gst = gstAmount;
    }

    setSalesOrderData({ ...salesOrderData, items: updatedItems });
    calculateTotals(updatedItems);
  };

  // Remove item from the list
  const removeItem = (index) => {
    const updatedItems = [...salesOrderData.items];
    updatedItems.splice(index, 1);
    setSalesOrderData({ ...salesOrderData, items: updatedItems });
    calculateTotals(updatedItems);
  };

  // Calculate totals
  const calculateTotals = (items) => {
    const totalQuantity = items.reduce(
      (sum, item) => sum + (+item.quantity || 0),
      0
    );
    const totalNetAmount = items.reduce(
      (sum, item) => sum + (+item.net_amount || 0),
      0
    );
    const totalGST = items.reduce((sum, item) => sum + (+item.gst || 0), 0);
    const totalAmount = totalGST + totalNetAmount;
    setTotalQuantity(totalQuantity);
    setTotalNetAmount(totalNetAmount.toFixed(2));
    setTotalGST(totalGST.toFixed(2));
    setTotalAmount(totalAmount.toFixed(2));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedSalesOrder = {
      ...salesOrderData,
      quantity: totalQuantity,
      total_net_amount: totalNetAmount,
      total_gst: totalGST,
      total_amount: totalAmount,
    };

    dispatch(updateSalesOrder(updatedSalesOrder))
      .then(() => {
        alert("Sales Order updated successfully!");
        setIsUpdated(true);
        handleEditCloseModal();
      })
      .catch((error) => console.error("Error updating sales order", error));
  };

  return (
    <div className="card shadow p-4">
      <h2 className="text-center mb-4">Edit Sales Order</h2>

      <form onSubmit={handleSubmit}>
        {/* Display Client Details */}
        {salesOrderData.client_id && (
          <div className="mb-4">
            <h5>Client Details</h5>
            <p>
              <strong>First Name:</strong> {salesOrderData.firstName}
            </p>
            <p>
              <strong>Last Name:</strong> {salesOrderData.lastName}
            </p>
            <p>
              <strong>Sonepar_customer_code:</strong>{" "}
              {salesOrderData.user_code_d365}
            </p>
            <p>
              <strong>Company Name:</strong> {salesOrderData.user_company_name}
            </p>
          </div>
        )}
        <div className="col-md-3">
          <label className="form-label">Sales Order Number</label>
          <input
            className="form-control"
            value={salesOrderData.sales_order_number}
            onChange={(e) =>
              setSalesOrderData({
                ...salesOrderData,
                sales_order_number: e.target.value,
              })
            }
          />
        </div>

        {/* Items Sublist */}
        <h4>Order Items</h4>
        {salesOrderData.items.map((item, index) => (
          <div key={index} className="row mb-3">
            {/* Product Selection */}
            <div className="col-md-3">
              <label className="form-label">Product</label>
              <AsyncSelect
                cacheOptions
                loadOptions={fetchProducts}
                onChange={(option) => handleProductSelect(index, option)}
                defaultOptions={[
                  {
                    value: item.product_id,
                    label: item.product_name,
                  },
                ]}
                placeholder={item.product_name}
              />
            </div>

            {/* Quantity */}
            <div className="col-md-2">
              <label className="form-label">Quantity</label>
              <input
                type="number"
                className="form-control"
                value={item.quantity || 0}
                onChange={(e) =>
                  handleItemChange(index, "quantity", e.target.value)
                }
              />
            </div>

            {/* Rate */}
            <div className="col-md-2">
              <label className="form-label">Rate</label>
              <input
                type="number"
                className="form-control"
                value={item.rate || 0}
                readOnly
              />
            </div>

            {/* Net Amount */}
            <div className="col-md-2">
              <label className="form-label">Net Amount</label>
              <input
                type="number"
                className="form-control"
                value={item.net_amount || 0}
                readOnly
              />
            </div>

            {/* GST */}
            <div className="col-md-1">
              <label className="form-label">GST %</label>
              <input
                type="number"
                className="form-control"
                value={item.gstPercentage || 0}
                readOnly
              />
            </div>

            {/* GST Amount */}
            <div className="col-md-2">
              <label className="form-label">GST Amount</label>
              <input
                type="number"
                className="form-control"
                value={item.gst || 0}
                readOnly
              />
            </div>

            {/* Remove Item Button */}
            <div className="col-md-1 d-flex align-items-end">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => removeItem(index)}
              >
                Remove
              </button>
            </div>
          </div>
        ))}

        {/* Add Item Button */}
        <div className="mb-4">
          <button type="button" className="btn btn-secondary" onClick={addItem}>
            Add Item
          </button>
        </div>

        {/* Totals */}
        <h5>Total Quantity: {totalQuantity}</h5>
        <h5>Total Net Amount: {totalNetAmount}</h5>
        <h5>Total GST: {totalGST}</h5>
        <h5>Total Amount: {totalAmount}</h5>

        {/* Submit Button */}
        <button type="submit" className="btn btn-primary w-100">
          Update Sales Order
        </button>
      </form>
    </div>
  );
};

export default EditSalesOrderPage;
