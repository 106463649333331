import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/index.js";
import Footer from "../components/Footer/Footer.js";
const DashboardLayout = () => {
  return (
    <>
      <div className="d-flex vh-100 ">
        <Sidebar />
        <main className="flex-grow-1 p-3 overflow-auto">
          <Outlet />
        </main>
      </div>
      <Footer />
    </>
  );
};

export default DashboardLayout;
