import React, { useState } from "react";
import { addCategory } from "../../../actions/modertor";
import { useDispatch } from "react-redux";

const AddCategoryPage = () => {
  const [categoryData, setCategoryData] = useState({
    category_name: "",
  });
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(addCategory(categoryData))
      .then(() => {
        setError(false);
        alert("Category added successfully!");
        setCategoryData({ category_name: "" }); // Reset form
      })
      .catch((error) => {
        if (error.status === 501) {
          setError(error.message);
        } else {
          console.error("Error adding category:1", error.message);
        }
      });
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow p-4">
            <h2 className="text-center mb-4">Add New Category</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="category_name" className="form-label">
                  Category Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="category_name"
                  name="category_name"
                  value={categoryData.category_name}
                  onChange={handleChange}
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  placeholder="Enter category name"
                  required
                />
                {error && <div className="invalid-feedback">{error}</div>}
              </div>
              <div className="d-grid">
                <button type="submit" className="btn btn-primary">
                  Add Category
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategoryPage;
