import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateClient } from "../../../actions/modertor";
import UserService from "../../../services/userService";
// import { FaEye, FaEyeSlash } from "react-icons/fa";

const EditClientPage = ({ client, setIsUpdated, handleEditCloseModal }) => {
  const [clientData, setClientData] = useState({
    user_id: "",
    user_code_d365: "",
    firstName: "",
    lastName: "",
    user_name: "",
    user_company_name: "",
    city: "",
    state: "",
    country: "",
    email_add: "",
    mobile_number: "",
    select_business_type: "",
    estimated_MRO_spend: "",
    sonepar_sales_associates: "",
    nature_of_business: "",
    select_industry: "",
    no_of_employee: null,
    product_looking_for: "",
    active: true,
  });
  const [errorMessages, setErrorMessages] = useState({
    user_code_d365: "",
    user_name: "",
    email_add: "",
  });
  const dispatch = useDispatch();

  const [businessTypes, setBusinessTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [MRO, setMRO] = useState([]);
  const [EMPLOYEES, setEmployees] = useState([]);
  const [productNeeded, setProductNeeded] = useState([]);

  useEffect(() => {
    UserService.getBusinessType().then((res) => {
      setBusinessTypes(res.data);
    });
  }, []);

  useEffect(() => {
    UserService.getIndustries().then((res) => {
      setIndustries(res.data || []);
    });
  }, []);

  useEffect(() => {
    UserService.getMROOptions().then((res) => {
      setMRO(res.data || []);
    });
  }, []);

  useEffect(() => {
    UserService.getEmployeeOptions().then((res) => {
      setEmployees(res.data || []);
    });
  }, []);

  useEffect(() => {
    UserService.getProductNeededOptions().then((res) => {
      setProductNeeded(res.data || []);
    });
  }, []);

  // const [businessTypes] = useState([
  //   "Retail",
  //   "Wholesale",
  //   "Manufacturing",
  //   "Services",
  // ]);
  // const [industries] = useState([
  //   "Automotive",
  //   "Construction",
  //   "Energy",
  //   "Healthcare",
  // ]);

  // Populate client data in form on component load
  useEffect(() => {
    if (client) {
      setClientData({
        user_id: client.user_id || "",
        firstName: client.firstName || "",
        lastName: client.lastName || "",
        user_code_d365: client.user_code_d365 || "",
        user_name: client.user_name || "",
        user_company_name: client.user_company_name || "",
        city: client.city || "",
        state: client.state || "",
        country: client.country || "",
        email_add: client.email_add || "",
        mobile_number: client.mobile_number || "",
        select_business_type: client.select_business_type || "",
        estimated_MRO_spend: client.estimated_MRO_spend || "",
        sonepar_sales_associates: client.sonepar_sales_associates || "",
        nature_of_business: client.nature_of_business || "",
        select_industry: client.select_industry || "",
        no_of_employee: client.no_of_employee || null,
        product_looking_for: client.product_looking_for || "",
        active: client.active || false, // Boolean fields should default to false
      });
    }
  }, [client]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(updateClient(clientData))
      .then(() => {
        alert("Client updated successfully!");
        setIsUpdated(true);
        handleEditCloseModal();
      })
      .catch((error) => {
        if (error.status === 501) {
          if (error.message.includes(1)) {
            setErrorMessages((prev) => ({
              ...prev,
              user_code_d365: "Duplicate user_code_d365",
            }));
          }
          if (error.message.includes(2)) {
            setErrorMessages((prev) => ({
              ...prev,
              user_name: "Duplicate user_name",
            }));
          }
          if (error.message.includes(3)) {
            setErrorMessages((prev) => ({
              ...prev,
              email_add: "Duplicate email address",
            }));
          }
          if (error.message.includes(4)) {
            setErrorMessages((prev) => ({
              ...prev,
              mobile_number: "Duplicate mobile number",
            }));
          }
        } else {
          console.error("Error adding client", error);
        }
      });
  };

  return (
    <div className="  p-4">
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="row">
          {/* Sonepar Customer Code
           */}
          <div className="col-md-6 mb-3">
            <label className="form-label">Sonepar Customer Code</label>
            <input
              type="text"
              name="user_code_d365"
              className={`form-control ${
                errorMessages.user_code_d365 ? "is-invalid" : ""
              }`}
              value={clientData.user_code_d365}
              onChange={handleChange}
            />
            {errorMessages.user_code_d365 && (
              <div className="invalid-feedback">
                {errorMessages.user_code_d365}
              </div>
            )}
          </div>

          {/* User Name */}
          <div className="col-md-6 mb-3">
            <label className="form-label">
              User Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              required
              type="text"
              name="user_name"
              readOnly
              className={`form-control ${
                errorMessages.user_name ? "is-invalid" : ""
              }`}
              value={clientData.user_name}
              onChange={handleChange}
            />
            {errorMessages.user_name && (
              <div className="invalid-feedback">{errorMessages.user_name}</div>
            )}
          </div>
        </div>

        <div className="row">
          {/* Company Name */}
          <div className="col-md-6 mb-3">
            <label className="form-label">
              First Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              required
              type="text"
              name="firstName"
              className="form-control"
              value={clientData.firstName}
              onChange={handleChange}
            />
          </div>

          {/* City */}
          <div className="col-md-6 mb-3">
            <label className="form-label">
              Last Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              required
              type="text"
              name="lastName"
              className="form-control"
              value={clientData.lastName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          {/* Company Name */}
          <div className="col-md-6 mb-3">
            <label className="form-label">
              Company Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="user_company_name"
              readOnly
              className="form-control"
              value={clientData.user_company_name}
              onChange={handleChange}
            />
          </div>

          {/* City */}
          <div className="col-md-6 mb-3">
            <label className="form-label">City</label>
            <input
              type="text"
              name="city"
              className="form-control"
              value={clientData.city}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          {/* State */}
          <div className="col-md-6 mb-3">
            <label className="form-label">State</label>
            <input
              type="text"
              name="state"
              className="form-control"
              value={clientData.state}
              onChange={handleChange}
            />
          </div>

          {/* Country */}
          <div className="col-md-6 mb-3">
            <label className="form-label">Country</label>
            <input
              type="text"
              name="country"
              className="form-control"
              value={clientData.country}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          {/* Email */}
          <div className="col-md-6 mb-3">
            <label className="form-label">
              Email<span style={{ color: "red" }}>*</span>
            </label>
            <input
              required
              type="email"
              name="email_add"
              readOnly
              className={`form-control ${
                errorMessages.email_add ? "is-invalid" : ""
              }`}
              value={clientData.email_add}
              onChange={handleChange}
            />
            {errorMessages.email_add && (
              <div className="invalid-feedback">{errorMessages.email_add}</div>
            )}
          </div>

          {/* Mobile Number */}
          <div className="col-md-6 mb-3">
            <label className="form-label">
              Mobile Number<span style={{ color: "red" }}>*</span>
            </label>
            <input
              required
              type="text"
              name="mobile_number"
              readOnly
              className="form-control"
              value={clientData.mobile_number}
              onChange={handleChange}
            />
            {errorMessages.mobile_number && (
              <div className="invalid-feedback">
                {errorMessages.mobile_number}
              </div>
            )}
          </div>
        </div>

        {/* Business Type */}
        <div className="mb-3">
          <label className="form-label">Business Type</label>
          <select
            name="select_business_type"
            className="form-select"
            value={clientData.select_business_type}
            onChange={handleChange}
          >
            <option value="">Select Business Type</option>
            {businessTypes.map((item) => (
              <option key={"business" + item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        {/* Industry */}
        <div className="mb-3">
          <label className="form-label">Industry</label>
          <select
            name="select_industry"
            className="form-select"
            value={clientData.select_industry}
            onChange={handleChange}
          >
            <option value="">Select Industry</option>
            {industries.map((item) => (
              <option key={"industries" + item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className="row">
          {/* Estimated MRO Spend */}
          <div className="col-md-6 mb-3">
            <label className="form-label">Estimated MRO Spend</label>
            <select
              name="estimated_MRO_spend"
              className="form-select"
              value={clientData.estimated_MRO_spend}
              onChange={handleChange}
            >
              <option value="">Select MRO Spend</option>
              {MRO.map((item) => (
                <option key={"MRO" + item.name} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          {/* Sonepar Sales Associates */}
          <div className="col-md-6 mb-3">
            <label className="form-label">Sonepar Sales Associates</label>
            <input
              type="text"
              name="sonepar_sales_associates"
              className="form-control"
              value={clientData.sonepar_sales_associates}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Nature of Business */}
        <div className="mb-3">
          <label className="form-label">Nature of Business</label>
          <input
            type="text"
            name="nature_of_business"
            className="form-control"
            value={clientData.nature_of_business}
            onChange={handleChange}
          />
        </div>

        {/* Number of Employees */}
        <div className="mb-3">
          <label className="form-label">Number of Employees</label>
          <select
            name="no_of_employee"
            className="form-select"
            value={clientData.no_of_employee}
            onChange={handleChange}
          >
            <option value="">Select No of Employees</option>
            {EMPLOYEES.map((item) => (
              <option key={"employees" + item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        {/* Products Looking For */}
        <div className="mb-3">
          <label className="form-label">Products Looking For</label>
          <input
            type="text"
            name="product_looking_for"
            className="form-control"
            value={clientData.product_looking_for}
            onChange={handleChange}
          />
        </div>

        <button type="submit" className="btn btn-primary w-100">
          Update Client
        </button>
      </form>
    </div>
  );
};

export default EditClientPage;
