import React, { useCallback, useEffect, useMemo, useState } from "react";
import ModService from "../../../services/modService";
import { useTable, useSortBy } from "react-table";
import ReactPaginate from "react-paginate";
import { Modal } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import EditProduct from "./EditProduct";

const ProductListPage = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [showModal, setShowModal] = useState(false); // For view product modal visibility
  const [showEditModal, setShowEditModal] = useState(false); // For Edit product modal visibility
  const [selectedProduct, setSelectedProduct] = useState(null); // For selected product details
  // const navigate = useNavigate();

  // Fetch products from the backend
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Start loader
      try {
        const response = await ModService.getProduct({
          page: currentPage + 1,
          limit: 10,
        });
        setProducts(response.data.products);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching products", error);
      }
      setLoading(false); // Stop loader
    };

    fetchProducts();
  }, [currentPage]);

  const fetchProducts = async () => {
    setLoading(true); // Start loader
    try {
      const response = await ModService.getProduct({
        page: currentPage + 1,
        limit: 10,
      });
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching products", error);
    }
    setLoading(false); // Stop loader
  };

  const prodIsUpdated = (newValue) => {
    fetchProducts();
    setShowEditModal(false); //If Updated is true, close the Modal
  };
  // Handle product view in modal
  const handleViewProduct = useCallback((product) => {
    if (typeof product.images === "string") {
      product.images = JSON.parse(product.images);
    }

    if (typeof product.catalog === "string") {
      product.catalog = JSON.parse(product.catalog);
    }

    setSelectedProduct(product); // Set the product details in state
    setShowModal(true); // Open modal
  }, []);

  //Handle Edit Product
  const handleEditProduct = useCallback((product) => {
    if (typeof product.images === "string") {
      product.images = JSON.parse(product.images);
    }

    if (typeof product.catalog === "string") {
      product.catalog = JSON.parse(product.catalog);
    }
    setSelectedProduct(product);
    setShowEditModal(true);
  }, []);

  //Delete Product and refresh results
  // const deleteProduct = async (product) => {
  //   setLoading(true); // Start loader
  //   try {
  //     const response = await ModService.deleteProduct(product);
  //     if (response.status == 200) {
  //       fetchProducts();
  //     }
  //   } catch (error) {
  //     console.error("Error deleting product", error);
  //   }
  //   setLoading(false); // Stop loader
  // };

  const handleCloseModal = () => {
    setShowModal(false); // Close modal
    setSelectedProduct(null); // Clear selected product data
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false); // Close edit modal
    setSelectedProduct(null); // Clear selected product data
  };

  // Column definitions for react-table
  const columns = useMemo(
    () => [
      {
        Header: "Image",
        accessor: "main_pic",
        Cell: ({ row }) => (
          <img
            src={`${process.env.REACT_APP_BACKEND_SERVER}/images/${row.original.main_pics}`}
            alt="Product"
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop in case the default image also fails
              e.target.src = "/images/default.jpg"; // Set the default image
            }}
            className="img-thumbnail"
            style={{ width: "50px", height: "50px", objectFit: "cover" }}
          />
        ),
      },
      { Header: "Product Code", accessor: "productcode" },
      { Header: "Product Name", accessor: "product" },
      { Header: "Price", accessor: "mrp" },
      { Header: "Brand", accessor: "brand_name" },
      { Header: "Category", accessor: "cat_name" },
      { Header: "Sub Category", accessor: "sub_cat_name" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-link text-primary p-0 me-2"
              style={{ display: "inline", verticalAlign: "baseline" }}
              onClick={() => handleViewProduct(row.original)}
            >
              View
            </button>
            |
            <button
              className="btn btn-link text-primary p-0 ms-2"
              style={{ display: "inline", verticalAlign: "baseline" }}
              onClick={() => handleEditProduct(row.original)}
            >
              Edit
            </button>
            {/*              
            <button
              className="btn btn-link text-primary p-0 me-2"
              style={{ display: "inline", verticalAlign: "baseline" }}
            >
              Edit
            </button> */}
          </>
        ),
      },
    ],
    [handleViewProduct, handleEditProduct]
  );

  // Data for react-table
  const data = useMemo(() => products, [products]);

  // Table instance from react-table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns, data },
      useSortBy // Sorting functionality
    );

  // Handle pagination
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  // Handle search
  const handleSearch = async () => {
    setLoading(true); // Start loader
    try {
      const response = await ModService.getProduct({
        page: currentPage + 1,
        limit: 10,
        search: searchTerm,
      });
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error searching products", error);
    }
    setLoading(false); // Stop loader
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Product Page</h1>
      </div>

      {/* Search Bar */}
      <div className="input-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Search products..."
          value={searchTerm}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch(); // Trigger search when Enter is pressed
            }
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-outline-secondary" onClick={handleSearch}>
          Search
        </button>
      </div>

      {/* Loader */}
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {/* Product Table */}
          <table
            {...getTableProps()}
            className="table  table-hover table-bordered"
          >
            <thead className="table">
              {headerGroups.map((headerGroup, headerGroupIndex) => {
                const { key: headerGroupKey, ...headerGroupProps } =
                  headerGroup.getHeaderGroupProps(); // Extract key from headerGroup
                return (
                  <tr
                    key={headerGroupKey || headerGroupIndex}
                    {...headerGroupProps}
                  >
                    {headerGroup.headers.map((column, columnIndex) => {
                      const { key: columnKey, ...columnProps } =
                        column.getHeaderProps(column.getSortByToggleProps()); // Extract key from column
                      return (
                        <th
                          key={columnKey || columnIndex} // Fall back to index if key is not available
                          {...columnProps}
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} key={cell.column.id}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              forcePage={currentPage}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </>
      )}

      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && showModal ? (
            <div className=" p-4">
              {/* Product Information */}
              <div className="row">
                <div className="col-md-6 mb-3">
                  <strong>
                    <label className="form-label">Product Code</label>
                  </strong>
                  <p>{selectedProduct.productcode}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <strong>
                    <label className="form-label">Product</label>
                  </strong>
                  <p>{selectedProduct.product}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <strong>
                    <label className="form-label">Product Display Name</label>
                  </strong>
                  <p>{selectedProduct.productdisplayname}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <strong>
                    <label className="form-label">Specifications</label>
                  </strong>
                  <p>{selectedProduct.specifications}</p>
                </div>
              </div>

              {/* UOM and Pricing */}
              <div className="row">
                <div className="col-md-4 mb-3">
                  <strong>
                    <label className="form-label">UOM</label>
                  </strong>
                  <p>{selectedProduct.uom}</p>
                </div>
                <div className="col-md-4 mb-3">
                  <strong>
                    <label className="form-label">Call Price</label>
                  </strong>
                  <p>{selectedProduct.call_price}</p>
                </div>
                <div className="col-md-4 mb-3">
                  <strong>
                    <label className="form-label">MRP</label>
                  </strong>
                  <p>{selectedProduct.mrp}</p>
                </div>
              </div>

              {/* Brand, Category, Sub Category */}
              <div className="row">
                <div className="col-md-4 mb-3">
                  <strong>
                    <label className="form-label">Brand</label>
                  </strong>
                  <p>{selectedProduct.brand_name}</p>
                </div>
                <div className="col-md-4 mb-3">
                  <strong>
                    <label className="form-label">Category</label>
                  </strong>
                  <p>{selectedProduct.cat_name}</p>
                </div>
                <div className="col-md-4 mb-3">
                  <strong>
                    <label className="form-label">Sub Category</label>
                  </strong>
                  <p>{selectedProduct.sub_cat_name}</p>
                </div>
              </div>

              {/* GST and MOQ */}
              <div className="row">
                <div className="col-md-6 mb-3">
                  <strong>
                    <label className="form-label">GST</label>
                  </strong>
                  <p>{selectedProduct.gst}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <strong>
                    <label className="form-label">MOQ</label>
                  </strong>
                  <p>{selectedProduct.moq}</p>
                </div>
              </div>

              {/* Tags */}
              <div className="mb-3">
                <strong>
                  <label className="form-label">Tags</label>
                </strong>
                <p>{selectedProduct.tags || "None"}</p>
              </div>

              {/* Main Picture */}
              <div className="mb-3">
                <strong>
                  <label className="form-label">Main Picture</label>
                </strong>
                {selectedProduct.main_pics ? (
                  <img
                    src={`${process.env.REACT_APP_BACKEND_SERVER}/images/${selectedProduct.main_pics}`}
                    alt="Main"
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                      e.target.src = "/images/default.jpg"; // Set the default image
                    }}
                    className="img-thumbnail"
                    style={{ width: "150px" }}
                  />
                ) : (
                  <p>No main picture available.</p>
                )}
              </div>

              {/* Catalogs */}
              <div className="mb-3">
                <strong>
                  <label className="form-label">Catalog (PDF)</label>
                </strong>
                {selectedProduct.catalogs ? (
                  selectedProduct.catalogs.map((catalog, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <a
                        href={`${process.env.REACT_APP_BACKEND_SERVER}/${catalog.path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {catalog}
                      </a>
                    </div>
                  ))
                ) : (
                  <p>No catalogs available.</p>
                )}
              </div>

              {/* Render Multiple Images */}
              {selectedProduct.images && selectedProduct.images.length > 0 && (
                <div className="mb-3">
                  <label className="form-label">
                    <strong>Additional Images:</strong>
                  </label>
                  <div className="d-flex flex-wrap">
                    {selectedProduct.images.map((image, index) => (
                      <div key={index} className="me-2 mb-2">
                        <img
                          src={`${process.env.REACT_APP_BACKEND_SERVER}/${image.path}`}
                          alt={`Additional  ${index + 1}`}
                          className="img-thumbnail"
                          onError={(e) => {
                            e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                            e.target.src = "/images/default.jpg"; // Set the default image
                          }}
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Active Status */}
              <div className="mb-3 form-check">
                <label className="form-label">
                  <strong>Active:</strong>
                </label>
                <p>{selectedProduct.active ? "Active" : "Not Active"}</p>
              </div>
            </div>
          ) : (
            <p>No product selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Editing product details */}
      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && showEditModal ? (
            <EditProduct
              currentProduct={selectedProduct}
              prodIsUpdated={prodIsUpdated}
            />
          ) : (
            <p>No product selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseEditModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProductListPage;
