import React from "react";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import PublicRoute from "./components/routes/PublicRoute";

import Shop from "./pages/Public_pages/Shop";
import Home from "./pages/Public_pages/Home";
import Product from "./pages/Public_pages/Product";
import Login from "./pages/Public_pages/Login";
import Cart from "./pages/UserPages/Cart";
import RegistrationForm from "./pages/Public_pages/RegistrationForm";
import ProfilePage from "./pages/UserPages/ProfilePage";
import ProductListing from "./pages/UserPages/ProductListing";
import ErrorPage from "./components/ErrorBoundary/ErrorPage";
import ContactUsPage from "./pages/Public_pages/Contactus";
import Aboutus from "./pages/Public_pages/Aboutus";
import TermsAndCondition from "./pages/Public_pages/TermsAndCondition";
import ForgotPassword from "./pages/Public_pages/ForgotPassword";
import ResetPassword from "./pages/Public_pages/ResetPassword";
import OrderPage from "./pages/UserPages/OrderPage";
//Dashboard imports
import ProductList from "./pages/Admin_Pages/Products/ProductList";

import BrandPage from "./pages/Admin_Pages/Brand/BrandList";
import CategorySubcategoryPage from "./pages/Admin_Pages/Category-SubCategory/CategorySubcategoryPage";
import AddProductPage from "./pages/Admin_Pages/Products/AddProductPage";
import BulkProductUpload from "./pages/Admin_Pages/Products/BulkUpload";
import AddBrandPage from "./pages/Admin_Pages/Brand/AddBrandPage";
import AddCategoryPage from "./pages/Admin_Pages/Category-SubCategory/AddCategoryPage";
import AddSubCategoryPage from "./pages/Admin_Pages/Category-SubCategory/AddSubCategoryPage";
import AddClientPage from "./pages/Admin_Pages/Clients/AddClientPage";
import ClientListingPage from "./pages/Admin_Pages/Clients/ClientListingPage";
import PendingClientListPage from "./pages/Admin_Pages/Clients/PendingClientListPage";
import RejectedClientListPage from "./pages/Admin_Pages/Clients/RejectedClientListPage";
import EmployeeListingPage from "./pages/Admin_Pages/Admin/EmployeeListingPage";
import AddEmployeePage from "./pages/Admin_Pages/Admin/AddEmployeePage";
import EmployeeProfilePage from "./pages/Admin_Pages/Admin/ProfilePage";
import SalesOrderListPage from "./pages/Admin_Pages/Orders/SalesOrderListPage";
import AddSalesOrderPage from "./pages/Admin_Pages/Orders/AddSalesOrderPage";
import ApprovedOrderList from "./pages/Admin_Pages/Orders/ApprovedOrderList";
import RejectedOrderList from "./pages/Admin_Pages/Orders/RejectedOrderList";
import PendingOrderList from "./pages/Admin_Pages/dashboard/PendingOrderList";
import BannerList from "./pages/Admin_Pages/Banner/BannerList";
import Dashboard from "./pages/Admin_Pages/dashboard/Dashboard";
import BusinessTypePage from "./pages/Admin_Pages/Admin/BusineesType";
import IndustryPage from "./pages/Admin_Pages/Admin/Industry";
import MROSpendPage from "./pages/Admin_Pages/Admin/MroSpend";
import NumberOfEmployeesPage from "./pages/Admin_Pages/Admin/NoOfEmployees";
import ProductNeededPage from "./pages/Admin_Pages/Admin/ProductNeeded";
import DispatchPage from "./pages/Admin_Pages/Orders/DispatchPage";
import QuickQuery from "./pages/Admin_Pages/dashboard/QuickQuery";
//Reports Link
import PendingClientReport from "./pages/Admin_Pages/Reports/PendingClientListPage";

import DashboardLayout from "./layouts/DashboardLayout";

const router = createBrowserRouter([
  { path: "*", errorElement: <ErrorPage />, element: <Home /> },
  { path: "shop", errorElement: <ErrorPage />, element: <Shop /> },
  { path: "product/:id", errorElement: <ErrorPage />, element: <Product /> },
  { path: "cart", errorElement: <ErrorPage />, element: <Cart /> },
  { path: "aboutus", errorElement: <ErrorPage />, element: <Aboutus /> },
  {
    path: "/forgot-password",
    errorElement: <ErrorPage />,
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:hash",
    errorElement: <ErrorPage />,
    element: <ResetPassword />,
  },
  { path: "contact", errorElement: <ErrorPage />, element: <ContactUsPage /> },
  {
    path: "termsandcondition",
    errorElement: <ErrorPage />,
    element: <TermsAndCondition />,
  },
  {
    path: "registrationForm",
    errorElement: <ErrorPage />,
    element: <RegistrationForm />,
  },
  {
    path: "login",
    errorElement: <ErrorPage />,
    element: <PublicRoute element={Login} />,
  },
  { path: "myprofile", errorElement: <ErrorPage />, element: <ProfilePage /> },
  { path: "orders", errorElement: <ErrorPage />, element: <OrderPage /> },
  {
    path: "productListing",
    errorElement: <ErrorPage />,
    element: <ProductListing />,
  },
  {
    errorElement: <ErrorPage />,
    element: (
      <ProtectedRoute
        element={<DashboardLayout />}
        allowedRoles={[1, 2]} // Only users with role "1" can access this route
      />
    ),
    children: [
      {
        path: "productList",
        element: <ProductList />,
      },

      {
        path: "addProductPage",
        element: <AddProductPage />,
      },

      {
        path: "bulkProductUpload",
        element: <BulkProductUpload />,
      },
      {
        path: "brandList",
        element: <BrandPage />,
      },
      {
        path: "addBrandPage",
        element: <AddBrandPage />,
      },
      {
        path: "catogorySubCatagory",
        element: <CategorySubcategoryPage />,
      },
      {
        path: "addCategoryPage",
        element: <AddCategoryPage />,
      },
      {
        path: "addSubCategoryPage",
        element: <AddSubCategoryPage />,
      },
      {
        path: "clientListing",
        element: <ClientListingPage />,
      },
      {
        path: "addClientPage",
        element: <AddClientPage />,
      },
      {
        path: "pendingClientList",
        element: <PendingClientListPage />,
      },
      {
        path: "rejectedClientList",
        element: <RejectedClientListPage />,
      },
      {
        path: "employeeListing",
        element: <EmployeeListingPage />,
      },
      {
        path: "addEmployeePage",
        element: <AddEmployeePage />,
      },
      {
        path: "employeeProfile",
        element: <EmployeeProfilePage />,
      },
      {
        path: "salesOrderList",
        element: <SalesOrderListPage />,
      },
      {
        path: "addSalesOrderPage",
        element: <AddSalesOrderPage />,
      },
      {
        path: "approvedSalesOrder",
        element: <ApprovedOrderList />,
      },
      {
        path: "rejectedOrderList",
        element: <RejectedOrderList />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },

      {
        path: "pendingOrderList",
        element: <PendingOrderList />,
      },
      {
        path: "dispatchPage",
        element: <DispatchPage />,
      },

      {
        path: "pendingClientReport",
        element: <PendingClientReport />,
      },
      {
        path: "bannerList",
        element: <BannerList />,
      },
      {
        path: "businesstype",
        element: <BusinessTypePage />,
      },
      {
        path: "industry",
        element: <IndustryPage />,
      },
      {
        path: "mrospend",
        element: <MROSpendPage />,
      },
      {
        path: "noofemployees",
        element: <NumberOfEmployeesPage />,
      },
      {
        path: "productneeded",
        element: <ProductNeededPage />,
      },
      {
        path: "quickqueries",
        element: <QuickQuery />,
      },
    ],
  },
]);

export default router;
