import React, { useState, useEffect } from "react";
import ModService from "../../../services/modService"; // Ensure ModService is updated for MRO endpoints

const MROSpendPage = () => {
  const [mroOptions, setMROOptions] = useState([]); // State to hold MRO options
  const [editingId, setEditingId] = useState(null); // ID of the item being edited
  const [editName, setEditName] = useState(""); // New name for editing
  const [newName, setNewName] = useState(""); // New MRO spend option to be added

  // Fetch MRO options on component mount
  useEffect(() => {
    fetchMROOptions();
  }, []);

  const fetchMROOptions = async () => {
    try {
      const response = await ModService.getMROOptions(); // Replace with your API endpoint
      setMROOptions(response.data);
    } catch (error) {
      console.error("Error fetching MRO options:", error);
    }
  };

  const handleEdit = (id, name) => {
    setEditingId(id);
    setEditName(name);
  };

  const handleSave = async (id) => {
    try {
      await ModService.updateMROOption(id, editName); // Replace with your API endpoint
      setEditingId(null);
      fetchMROOptions(); // Refresh the list
    } catch (error) {
      console.error("Error updating MRO option:", error);
    }
  };

  const handleAdd = async () => {
    if (!newName.trim()) {
      alert("Please enter a valid option.");
      return;
    }

    try {
      await ModService.addMROOption(newName); // Replace with your API endpoint
      setNewName("");
      fetchMROOptions(); // Refresh the list
    } catch (error) {
      console.error("Error adding MRO option:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this option?")) {
      try {
        await ModService.deleteMROOption(id); // Replace with your API endpoint
        fetchMROOptions(); // Refresh the list
      } catch (error) {
        console.error("Error deleting MRO option:", error);
      }
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Manage MRO Spend Options</h2>

      {/* Add new MRO option */}
      <div className="input-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Add new option"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleAdd}>
          Add
        </button>
      </div>

      {/* MRO options table */}
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Option</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {mroOptions.map((option) => (
            <tr key={option.id}>
              <td>{option.id}</td>
              <td>
                {editingId === option.id ? (
                  <input
                    type="text"
                    className="form-control"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                  />
                ) : (
                  option.name
                )}
              </td>
              <td>
                {editingId === option.id ? (
                  <>
                    <button
                      className="btn btn-success btn-sm me-2"
                      onClick={() => handleSave(option.id)}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={() => setEditingId(null)}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-warning btn-sm me-2"
                      onClick={() => handleEdit(option.id, option.name)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(option.id)}
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MROSpendPage;
