import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ element, allowedRoles }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const location = useLocation();
  if (!currentUser) {
    // If the user is not logged in, redirect to the login page.
    return <Navigate to="/login" replace />;
  }

  if (
    allowedRoles &&
    !allowedRoles.some((role) => currentUser.roles.includes(role))
  ) {
    // If the user does not have the correct role, redirect to home.
    return <Navigate to="/" replace />;
  } else {
    if (currentUser.roles.includes(1)) {
      return element;
    }

    if (!currentUser.permissions.includes(location.pathname)) {
      // If the user's permissions do not include the current path, redirect to home
      return <Navigate to="/" replace />;
    }

    // If the user is logged in, has the correct role, and has the necessary permission, render the element.
    return element;
  }

  // If the user is logged in and has the correct role, render the element.
};

export default ProtectedRoute;
