import React from "react";

import Navbar from "../../components/Navbar/Navbar";
// import BreadcrumbSection from "../../components/BreadcrumbSection";
import Footer from "../../components/Footer/Footer";
import ProductDetail from "../../components/Product/ProductDetail";
// import ProductInfo from "../../components/Product/ProductInfo";
import PopularCategories from "../../components/Product/PopularCategories";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Product = () => {
  const { id } = useParams(); // Get the product ID from the URL
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="Type some info" />
        <meta name="author" content="Type name" />

        <title>Website layout sample </title>
      </Helmet>
      <Navbar />
      {/* <BreadcrumbSection /> */}
      <ProductDetail id={id} />
      {/* <ProductInfo /> */}
      <PopularCategories />
      <Footer />
    </>
  );
};

export default Product;
