import React, { useEffect, useState } from "react";
import UserService from "../../services/userService";
import HelperFunction from "../../services/helperFunction";

const PopularCategories = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    UserService.getProducts({ limit: 3 }).then((response) => {
      setProducts(response.data.products);
      setProducts(response.data.products);
    });
  }, []);

  return (
    <section className="padding-y bg-light">
      <div className="container">
        <header className="section-heading">
          <h3>Related Products</h3>
        </header>

        <div className="row">
          {products.map((product, index) => (
            <div className="col-lg-4" key={index}>
              <div>
                <figure className="card card-product-grid">
                  <a
                    href={`/product/${encodeURIComponent(
                      HelperFunction.encrypt(product.id)
                    )}`}
                    className="img-wrap"
                  >
                    <img
                      src={`${process.env.REACT_APP_BACKEND_SERVER}/images/${product.main_pics}`}
                      alt={product.productdisplayname}
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                        e.target.src = "/images/default.jpg"; // Set the default image
                      }}
                    />
                  </a>
                  <figcaption className="p-3 border-top">
                    <a
                      href={`/product/${encodeURIComponent(
                        HelperFunction.encrypt(product.id)
                      )}`}
                      className="title mb-1"
                    >
                      {product.productdisplayname}
                    </a>
                    <div className="price text-lg mb-2 fw-bold">
                      ₹ {product.call_price}
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PopularCategories;
