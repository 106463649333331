import api from "./api";

//Post Data

const addCategory = (categoryData) => {
  return api.post("/mod/addCategory", {
    categoryData,
  });
};

const addSubCategory = (subCategoryData) => {
  return api.post("/mod/addSubCategory", {
    subCategoryData,
  });
};

const addBrand = (brandData) => {
  return api.post("/mod/addBrand", brandData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const addProduct = (productData) => {
  return api.post("/mod/addProduct", productData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const bulkUploadProducts = (products) => {
  return api.post("/mod/bulkUploadProducts", {
    products,
  });
};

const addClient = (clientData) => {
  return api.post("/mod/addClient", {
    clientData,
  });
};

const addEmployee = (employeeData) => {
  return api.post("/mod/addEmployee", {
    employeeData,
  });
};

const addSalesOrder = (salesOrderData) => {
  return api.post("/mod/addSalesOrder", {
    salesOrderData,
  });
};

const addBanner = (bannerData) => {
  return api.post("/mod/addBanner", bannerData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateProduct = (productData) => {
  return api.post("/mod/updateProduct", productData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateEmployeeProfile = (employeeData) => {
  return api.post("/mod/updateEmployeeProfile", {
    employeeData,
  });
};

const updateClientStatus = (clientData) => {
  return api.post("/mod/updateClientStatus", {
    clientData,
  });
};

const updateEmployee = (employeeData) => {
  return api.post("/mod/updateEmployee", {
    employeeData,
  });
};

const updateBanner = (bannerData) => {
  return api.post("/mod/updateBanner", bannerData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateBrand = (brandData) => {
  return api.post("/mod/updateBrand", brandData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateCategory = (categoryData) => {
  return api.post("/mod/updateCategory", {
    categoryData,
  });
};

const updateSubCategory = (subcategoryData) => {
  return api.post("/mod/updateSubCategory", {
    subcategoryData,
  });
};

const updateClient = (clientData) => {
  return api.post("/mod/updateClient", {
    clientData,
  });
};

const updateSalesOrder = (orderData) => {
  return api.post("/mod/updateSalesOrder", {
    orderData,
  });
};

const updateSalesOrderStatus = (orderData) => {
  return api.post("/mod/updateSalesOrderStatus", {
    orderData,
  });
};
//Delete Data

const deleteProduct = (productData) => {
  return api.post("/mod/deleteProduct", {
    productData,
  });
};

const deleteBanner = (bannerData) => {
  return api.post("/mod/deleteBanner", {
    bannerData,
  });
};

//Get All Data

const getAllCategory = () => {
  return api.get("/mod/getAllCategory");
};

const getAllSubCategory = () => {
  return api.get("/mod/getAllSubCategory");
};

const getAllBrand = () => {
  return api.get("/mod/getAllBrand");
};

const getAllPages = () => {
  return api.get("/mod/getAllPages");
};

// Get data by fields

const getSubCategoryByCatId = (cat_id) => {
  return api.get("/mod/getSubCategoryByCatId", {
    params: {
      cat_id: cat_id,
    },
  });
};

const getBrand = (params) => {
  return api.get("/mod/getBrand", {
    params: params,
  });
};

const getProduct = (params) => {
  return api.get("/mod/getProduct", {
    params: params,
  });
};

const getCategory = (params) => {
  return api.get("/mod/getCategory", {
    params: params,
  });
};

const getSubCategory = (params) => {
  return api.get("/mod/getSubCategory", {
    params: params,
  });
};

const getClients = (params) => {
  return api.get("/mod/getClients", {
    params: params,
  });
};

const getEmployees = (params) => {
  return api.get("/mod/getEmployees", {
    params: params,
  });
};

const getEmployeeProfile = () => {
  return api.get("/mod/getEmployeeProfile");
};

const getSalesOrders = (params) => {
  return api.get("/mod/getSalesOrders", {
    params: params,
  });
};

const getDispatchedItems = (params) => {
  return api.get("/mod/getDispatchedItems", {
    params: params,
  });
};

const getOrderStatuses = () => {
  return api.get("/mod/getOrderStatuses");
};
const getQueryMessage = (params) => {
  return api.get("/mod/getQueryMessage", {
    params: params,
  });
};

//business Types
const getBusinessType = () => {
  return api.get("/mod/business-type");
};

const updateBusinessType = (id, editName) => {
  return api.put(`/mod/business-type/${id}`, { name: editName });
};

const addBusinessType = (newName) => {
  return api.post("/mod/business-type", { name: newName });
};

const deleteBusinessType = (id) => {
  return api.delete(`/mod/business-type/${id}`);
};

//industry Types
const getIndustries = () => {
  return api.get("/mod/industry");
};

const updateIndustry = (id, editName) => {
  return api.put(`/mod/industry/${id}`, { name: editName });
};

const addIndustry = (newName) => {
  return api.post("/mod/industry", { name: newName });
};

const deleteIndustry = (id) => {
  return api.delete(`/mod/industry/${id}`);
};

//employee-options Types
const getEmployeeOptions = () => {
  return api.get("/mod/employee-options");
};

const updateEmployeeOption = (id, editName) => {
  return api.put(`/mod/employee-options/${id}`, { name: editName });
};

const addEmployeeOption = (newName) => {
  return api.post("/mod/employee-options", { name: newName });
};

const deleteEmployeeOption = (id) => {
  return api.delete(`/mod/employee-options/${id}`);
};

//industry Types
const getMROOptions = () => {
  return api.get("/mod/mro-spend");
};

const updateMROOption = (id, editName) => {
  return api.put(`/mod/mro-spend/${id}`, { name: editName });
};

const addMROOption = (newName) => {
  return api.post("/mod/mro-spend", { name: newName });
};

const deleteMROOption = (id) => {
  return api.delete(`/mod/mro-spend/${id}`);
};

//industry Types
const getProductNeededOptions = () => {
  return api.get("/mod/product-needed");
};

const updateProductNeededOption = (id, editName) => {
  return api.put(`/mod/product-needed/${id}`, { name: editName });
};

const addProductNeededOption = (newName) => {
  return api.post("/mod/product-needed", { name: newName });
};

const deleteProductNeededOption = (id) => {
  return api.delete(`/mod/product-needed/${id}`);
};

const createDispatch = (dispatchData) => {
  return api.post(`/mod/dispatch`, dispatchData);
};

const ModService = {
  addCategory,
  addSubCategory,
  addBrand,
  addProduct,
  bulkUploadProducts,
  addClient,
  addEmployee,
  addSalesOrder,
  addBanner,

  updateProduct,
  updateEmployeeProfile,
  updateClientStatus,
  updateEmployee,
  updateBanner,
  updateBrand,
  updateCategory,
  updateSubCategory,
  updateClient,
  updateSalesOrder,
  updateSalesOrderStatus,

  deleteProduct,
  deleteBanner,

  getAllCategory,
  getAllSubCategory,
  getAllBrand,
  getAllPages,
  getDispatchedItems,

  getSubCategoryByCatId,
  getProduct,
  getBrand,
  getCategory,
  getSubCategory,
  getClients,
  getEmployees,
  getEmployeeProfile,
  getSalesOrders,
  getOrderStatuses,
  getQueryMessage,

  getBusinessType,
  updateBusinessType,
  addBusinessType,
  deleteBusinessType,
  getIndustries,
  addIndustry,
  updateIndustry,
  deleteIndustry,
  getEmployeeOptions,
  addEmployeeOption,
  updateEmployeeOption,
  deleteEmployeeOption,
  getMROOptions,
  addMROOption,
  updateMROOption,
  deleteMROOption,
  getProductNeededOptions,
  addProductNeededOption,
  updateProductNeededOption,
  deleteProductNeededOption,

  createDispatch,
};

export default ModService;
