import React, { useState, useEffect } from "react";
import ModService from "../../../services/modService";
import { updateProduct } from "../../../actions/modertor";
import { useDispatch } from "react-redux";

const EditProduct = ({ currentProduct, prodIsUpdated }) => {
  const [productData, setProductData] = useState({
    id: "",
    ProductCode: "",
    Product: "",
    ProductDisplayName: "",
    Specifications: "",
    UOM: "",
    CallPrice: "",
    MRP: "",
    GST: "",
    MOQ: "",
    Brand_Id: "",
    Cat_Id: "",
    Sub_Cat_ID: "",
    active: true,
    metaTitle: "",
    metaDescription: "",
    tags: [],
  });

  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [error, setError] = useState(false);
  const [images, setImages] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [mainPicture, setMainPicture] = useState(null);
  const [tagInput, setTagInput] = useState("");

  // // Fetch data for dropdowns
  // useEffect(() => {
  //   const fetchSubCategoryData = async () => {
  //     try {
  //       console.log("fetching subcatogry for id => ", productData.Cat_Id);
  //       const subCategoryResponse = await ModService.getSubCategoryByCatId(
  //         productData.Cat_Id
  //       );
  //       setSubCategories(subCategoryResponse.data);
  //       console.log("response from category", subCategoryResponse);
  //     } catch (error) {
  //       console.error("Error fetching SubCategory data", error);
  //     }
  //   };
  //   fetchSubCategoryData();
  // }, [productData.Cat_Id]);

  // Populate form data when currentProduct changes
  useEffect(() => {
    if (currentProduct) {
      // console.log("Current Product => ", currentProduct);
      setProductData({
        id: currentProduct.id,
        ProductCode: currentProduct.productcode,
        Product: currentProduct.product,
        ProductDisplayName: currentProduct.productdisplayname,
        Specifications: currentProduct.specifications,
        UOM: currentProduct.uom,
        CallPrice: currentProduct.call_price,
        MRP: currentProduct.mrp,
        GST: currentProduct.gst,
        MOQ: currentProduct.moq,
        Brand_Id: currentProduct.brand_id,
        Cat_Id: currentProduct.cat_id,
        Sub_Cat_ID: currentProduct.sub_cat_id,
        active: currentProduct.active,
        metaTitle: currentProduct.metaTitle,
        metaDescription: currentProduct.metaDescription,
        tags: currentProduct.tags?.split(";") || [],
      });

      setMainPicture(currentProduct.main_pics || null); // Reset main picture for editing
      setImages(currentProduct.images || []);
      setCatalogs(currentProduct.catalogs || []);
      // setTagInput();
      const fetchCategoryData = async () => {
        try {
          const categoryResponse = await ModService.getAllCategory();
          setCategories(categoryResponse.data);
        } catch (error) {
          console.error("Error fetching Category data", error);
        }
      };

      const fetchSubCategoryData = async () => {
        try {
          const subCategoryResponse = await ModService.getSubCategoryByCatId(
            currentProduct.cat_id
          );
          setSubCategories(subCategoryResponse.data);
          // console.log(subCategoryResponse);
        } catch (error) {
          console.error("Error fetching SubCategory data", error);
        }
      };

      const fetchBrandData = async () => {
        try {
          const brandResponse = await ModService.getAllBrand();
          setBrands(brandResponse.data);
        } catch (error) {
          console.error("Error fetching Brand data", error);
        }
      };

      fetchCategoryData();
      fetchSubCategoryData();
      fetchBrandData();
    }
  }, [currentProduct]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    }));
  };

  // Handle file uploads
  const handleMainPictureUpload = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setMainPicture(e.target.files[0]);
    } else {
      alert("Error: Only JPEG, JPG, and PNG formats are allowed.");
    }
  };
  const handleImageUpload = (e) => {
    const acceptedFormats = ["image/jpeg", "image/jpg", "image/png"];
    const files = Array.from(e.target.files);

    // Filter files based on accepted formats
    const validFiles = files.filter((file) =>
      acceptedFormats.includes(file.type)
    );

    // Check if there are any invalid files
    const invalidFiles = files.filter(
      (file) => !acceptedFormats.includes(file.type)
    );

    // If there are invalid files, show an error message
    if (invalidFiles.length > 0) {
      alert("Error: Only JPEG, JPG, and PNG formats are allowed.");
    }

    // Update the images array only with valid files
    setImages([...images, ...validFiles]);
  };
  const handleCatalogUpload = (e) => {
    const acceptedFormat = "application/pdf";
    const files = Array.from(e.target.files);

    // Filter files to accept only PDFs
    const validFiles = files.filter((file) => file.type === acceptedFormat);

    // Check if there are any invalid files
    const invalidFiles = files.filter((file) => file.type !== acceptedFormat);

    // If there are invalid files, show an error message
    if (invalidFiles.length > 0) {
      alert("Error: Only PDF files are allowed.");
    }

    // Update the catalogs array only with valid PDF files
    setCatalogs([...catalogs, ...validFiles]);
  };

  // Remove files
  const handleRemoveMainPicture = () => setMainPicture(null);
  const handleRemoveImage = (index) =>
    setImages(images.filter((_, i) => i !== index));
  const handleRemoveCatalog = (index) =>
    setCatalogs(catalogs.filter((_, i) => i !== index));

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData1 = new FormData();

    if (!mainPicture) {
      alert("Main Picture is required");
      return;
    }

    // Convert tags array to string
    const tagsString = productData.tags.join(";");

    // Append form data
    // formData.append("tags", tagsString);
    Object.keys(productData).forEach((key) => {
      if (key === "tags") {
        formData1.append(key, tagsString);
      } else {
        formData1.append(key, productData[key]);
      }
    });
    formData1.append("main_picture", mainPicture);
    // Append existing image URLs individually
    images.forEach((imageUrl, index) => {
      if (imageUrl.path) {
        // Append the existing image URL
        formData1.append(`existing_pictures[${index}]`, imageUrl.path);
      } else {
        // Append the new image file
        formData1.append("pictures", imageUrl);
      }
    });

    // Append existing catalog URLs individually
    catalogs.forEach((catalogUrl, index) => {
      if (catalogUrl.path) {
        // Append the existing catalog URL
        formData1.append(`existing_pdf[${index}]`, catalogUrl.path);
      } else {
        // Append the new catalog file
        formData1.append("catalogs", catalogUrl);
      }
    });

    dispatch(updateProduct(formData1))
      .then(() => {
        alert("Product Updated successfully!");
        prodIsUpdated(true);
        setProductData({
          id: "",
          ProductCode: "",
          Product: "",
          ProductDisplayName: "",
          Specifications: "",
          UOM: "",
          CallPrice: "",
          MRP: "",
          GST: "",
          MOQ: "",
          Brand_Id: "",
          Cat_Id: "",
          Sub_Cat_ID: "",
          active: true,
          metaTitle: "",
          metaDescription: "",
          tags: [],
        });
        setImages([]);
        setCatalogs([]);
        setMainPicture(null);
        setTagInput("");
      })
      .catch((error) => {
        if (error.status === 501) {
          setError(error.message);
        } else {
          console.error("Error adding product", error);
        }
      });
  };

  // Tag management
  const handleTagInput = (e) => setTagInput(e.target.value);
  const handleTagKeyPress = (e) => {
    if (e.key === "Enter" && tagInput.trim()) {
      setProductData((prevData) => ({
        ...prevData,
        tags: [...prevData.tags, tagInput.trim()],
      }));
      setTagInput("");
      e.preventDefault();
    }
  };
  const handleRemoveTag = (index) =>
    setProductData((prevData) => ({
      ...prevData,
      tags: prevData.tags.filter((_, i) => i !== index),
    }));

  return (
    <div className=" p-4">
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {/* Product Information */}
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="ProductCode" className="form-label">
              Product Code<span style={{ color: "red" }}>*</span>
            </label>
            <input
              required
              type="text"
              id="ProductCode"
              name="ProductCode"
              className={`form-control ${error ? "is-invalid" : ""}`}
              value={productData.ProductCode}
              onChange={handleChange}
            />
            {error && <div className="invalid-feedback">{error}</div>}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="Product" className="form-label">
              Product<span style={{ color: "red" }}>*</span>
            </label>
            <input
              required
              type="text"
              id="Product"
              name="Product"
              className="form-control"
              value={productData.Product}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="ProductDisplayName" className="form-label">
              Product Display Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              required
              type="text"
              id="ProductDisplayName"
              name="ProductDisplayName"
              className="form-control"
              value={productData.ProductDisplayName}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="Specifications" className="form-label">
              Specifications
            </label>
            <textarea
              id="Specifications"
              name="Specifications"
              className="form-control"
              value={productData.Specifications}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>

        {/* UOM and Pricing */}
        <div className="row">
          <div className="col-md-4 mb-3">
            <label htmlFor="UOM" className="form-label">
              UOM
            </label>
            <input
              type="text"
              id="UOM"
              name="UOM"
              className="form-control"
              value={productData.UOM}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="CallPrice" className="form-label">
              Call Price
            </label>
            <input
              type="number"
              id="CallPrice"
              name="CallPrice"
              className="form-control"
              value={productData.CallPrice}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="MRP" className="form-label">
              MRP
            </label>
            <input
              type="number"
              id="MRP"
              name="MRP"
              className="form-control"
              value={productData.MRP}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="GST" className="form-label">
              GST
            </label>
            <input
              type="number"
              id="GST"
              name="GST"
              className="form-control"
              value={productData.GST}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="MOQ" className="form-label">
              MOQ
            </label>
            <input
              type="number"
              id="MOQ"
              name="MOQ"
              className="form-control"
              value={productData.MOQ}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Dropdowns for Brand, Category, SubCategory */}
        <div className="row">
          <div className="col-md-4 mb-3">
            <label htmlFor="Brand_Id" className="form-label">
              Brand
            </label>
            <select
              id="Brand_Id"
              name="Brand_Id"
              className="form-select"
              value={productData.Brand_Id}
              onChange={handleChange}
            >
              <option value="">Select Brand</option>
              {brands.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.brand_name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="Cat_Id" className="form-label">
              Category
            </label>
            <select
              id="Cat_Id"
              name="Cat_Id"
              className="form-select"
              value={productData.Cat_Id}
              onChange={handleChange}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.cat_name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="sub_cat_id" className="form-label">
              Sub Category
            </label>
            <select
              id="sub_cat_id"
              name="sub_cat_id"
              className="form-select"
              value={productData.Sub_Cat_ID}
              onChange={handleChange}
            >
              <option value="">Select Sub Category</option>
              {subCategories.map((subCategory) => (
                <option key={subCategory.id} value={subCategory.id}>
                  {subCategory.sub_cat_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Tags Input */}
        <div className="mb-3">
          <label className="form-label">Tags</label>
          <input
            type="text"
            className="form-control"
            value={tagInput}
            onChange={handleTagInput}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleTagKeyPress(e);
              }
            }}
            placeholder="Type and press Enter to add tags"
          />
          <div className="tags-list mt-2">
            {productData.tags.map((tag, index) => (
              <span key={index} className="badge bg-primary me-2">
                {tag}{" "}
                <button
                  type="button"
                  className="btn-close btn-sm"
                  onClick={() => handleRemoveTag(index)}
                ></button>
              </span>
            ))}
          </div>

          <div className="mb-3">
            <label htmlFor="metaTitle" className="form-label">
              Meta Title
            </label>
            <input
              type="text"
              id="metaTitle"
              name="metaTitle"
              className="form-control"
              value={productData.metaTitle}
              onChange={handleChange}
            />
          </div>
          <div className=" mb-3">
            <label htmlFor="metaDescription" className="form-label">
              Meta Description
            </label>
            <textarea
              id="metaDescription"
              name="metaDescription"
              className="form-control"
              value={productData.metaDescription}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>

        {/* Main Picture */}
        <div className="mb-3">
          <label htmlFor="main_picture" className="form-label">
            Main Picture <span style={{ color: "red" }}>*</span>
          </label>
          {mainPicture == null && (
            <input
              type="file"
              id="main_picture"
              className="form-control"
              accept="image/*"
              onChange={handleMainPictureUpload}
              required
            />
          )}
          {mainPicture && (
            <div className="mt-2">
              <img
                src={
                  mainPicture instanceof Blob
                    ? URL.createObjectURL(mainPicture)
                    : `${process.env.REACT_APP_BACKEND_SERVER}/images/${mainPicture}`
                }
                alt="Preview"
                className="img-thumbnail"
                style={{ width: "150px" }}
              />
              <button
                type="button"
                className="btn btn-danger mt-2"
                onClick={handleRemoveMainPicture}
              >
                Remove Main Picture
              </button>
            </div>
          )}
        </div>

        {/* Images */}
        <div className="mb-3">
          <label htmlFor="pictures" className="form-label">
            Pictures
          </label>
          <input
            type="file"
            multiple
            id="pictures"
            className="form-control"
            accept="image/*"
            onChange={handleImageUpload}
          />
          <div className="mt-2">
            {images.map((image, index) => (
              <div key={index} className="d-inline-block me-2">
                <img
                  src={
                    image instanceof Blob
                      ? URL.createObjectURL(image)
                      : `${process.env.REACT_APP_BACKEND_SERVER}/${image.path}`
                  }
                  alt="Preview"
                  className="img-thumbnail"
                  style={{ width: "100px" }}
                />
                <button
                  type="button"
                  className="btn btn-danger btn-sm mt-1"
                  onClick={() => handleRemoveImage(index)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Catalogs (PDFs) */}
        <div className="mb-3">
          <label htmlFor="catalogs" className="form-label">
            Catalog (PDF)
          </label>
          <input
            type="file"
            multiple
            id="catalogs"
            className="form-control"
            accept="application/pdf"
            onChange={handleCatalogUpload}
          />
          <div className="mt-2">
            {catalogs.map((catalog, index) => (
              <div key={index} className="d-flex align-items-center mb-2">
                <span>{catalog.name}</span>
                <button
                  type="button"
                  className="btn btn-danger btn-sm ms-2"
                  onClick={() => handleRemoveCatalog(index)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Active Checkbox */}
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="active"
            name="active"
            checked={productData.active}
            onChange={handleChange}
          />
          <label htmlFor="active" className="form-check-label">
            Active
          </label>
        </div>

        {/* Submit Button */}
        <button type="submit" className="btn btn-primary">
          Update
        </button>
      </form>
    </div>
  );
};

export default EditProduct;
