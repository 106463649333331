import axiosInstance from "./api";
import TokenService from "./token.service";
// import { refreshToken } from "../actions/auth";
import { logout } from "../actions/auth";
import { ERROR_MESSAGE } from "../actions/types";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (
        originalConfig.url !== "/auth/signin" &&
        originalConfig.url !== "/auth/signup" &&
        err.response
      ) {
        // Access Token was expired
        if (
          err.response.status === 401
          // && !originalConfig._retry
        ) {
          // originalConfig._retry = true;

          // try {
          //   const rs = await axiosInstance.post("/auth/refreshtoken", {
          //     refreshToken: TokenService.getLocalRefreshToken(),
          //   });

          //   const { accessToken } = rs.data;

          //   dispatch(refreshToken(accessToken));
          //   TokenService.updateLocalAccessToken(accessToken);

          //   return axiosInstance(originalConfig);
          // } catch (_error) {
          dispatch({
            type: ERROR_MESSAGE,
            payload: "You've been logged out. Please log in again",
          });
          dispatch(logout());
          return Promise.reject(err);
          // }
        } else if (err.response.status === 403 && originalConfig._retry) {
          dispatch(logout());
          return Promise.reject();
        } else {
          return Promise.reject(err);
        }
      } else {
        return Promise.reject(err);
      }
    }
  );
};

export default setup;
