import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

const ContactUsPage = () => {
  return (
    <>
      <Navbar />
      <div className="contact-us-page">
        {/* Header Section */}
        <section className="contact-header text-center py-5 bg-light">
          <div className="container">
            <h1 className="display-4 fw-bold">Contact Us</h1>
            <p className="lead">
              We'd love to hear from you! Please reach out with any questions or
              comments.
            </p>
          </div>
        </section>

        {/* Contact Information and Form Section */}
        <section className="contact-info py-5">
          <div className="container">
            <div className="row">
              {/* Contact Details */}
              <div className="col-md-6 mb-4">
                <h2 className="h3 fw-bold">Get in Touch</h2>
                <p>
                  <strong>Address:</strong>
                  <br />
                  1234 Main Street
                  <br />
                  City, State ZIP
                  <br />
                  Country
                </p>
                <p>
                  <strong>Phone:</strong> (123) 456-7890
                  <br />
                  <strong>Email:</strong> info@example.com
                </p>
              </div>

              {/* Contact Form */}
              <div className="col-md-6">
                <h2 className="h3 fw-bold">Send Us a Message</h2>
                <form>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Your Name *
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Your Email *
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="subject" className="form-label">
                      Subject *
                    </label>
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Message *
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="5"
                      className="form-control"
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>

        {/* Map Section */}
        {/* <section className="contact-map py-5 bg-light">
          <div className="container text-center">
            <h2 className="h3 fw-bold mb-4">Our Location</h2>
            <iframe
              title="Company Location"
              src="https://www.google.com/maps/embed?pb=!1m18!..." // Add your map link here
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </section> */}
      </div>
      <Footer />
    </>
  );
};

export default ContactUsPage;
