import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";
import ReactPaginate from "react-paginate";
import ModService from "../../../services/modService";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap"; // Modal for viewing details
import EditOrderPage from "./EditSalesOrderPage";
import { useDispatch } from "react-redux";
import { updateSalesOrderStatus } from "../../../actions/modertor";
import moment from "moment";

const SalesOrderListPage = () => {
  const [salesOrders, setSalesOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false); // For modal visibility
  const [showModal, setShowModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSalesOrders = async () => {
      setLoading(true);
      try {
        const response = await ModService.getSalesOrders({
          page: currentPage + 1,
          limit: 10,
          search: searchTerm,
        });
        setSalesOrders(response.data.orders);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching sales orders", error);
      }
      setLoading(false);
      setIsUpdated(false);
    };

    fetchSalesOrders();
  }, [currentPage, searchTerm, isUpdated]);

  useEffect(() => {
    const fetchStatus = async () => {
      setLoading(true);
      try {
        const response = await ModService.getOrderStatuses();
        setStatuses(response.data);
        // setSalesOrders(response.data.orders);
      } catch (error) {
        console.error("Error fetching sales orders", error);
      }
    };

    fetchStatus();
  }, []);

  const updateOrderStatus = (id, status, sales_order_no, order) => {
    if (status === "Approved" && !sales_order_no) {
      alert("Please add Sales Order no. before approving");
      handleCloseModal();
      handleEditViewOrder(order);

      return;
    }
    dispatch(updateSalesOrderStatus({ id, status })).then(() => {
      alert("Order Status Update Successfully");
      setIsUpdated(true);
    });
  };

  const columns = useMemo(
    () => [
      { Header: "Order ID", accessor: "id" },
      { Header: "Sales Or. No.", accessor: "sales_order_number" },
      { Header: "Client Code", accessor: "client_name" },
      {
        Header: "Cust. Name",
        accessor: "fullName", // Virtual accessor (not directly in the data)
        Cell: ({ row }) => `${row.original.firstName} ${row.original.lastName}`, // Combine first and last name
      },
      { Header: "username", accessor: "user_name" },
      {
        Header: "Order Date",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("DD MMM YYYY"),
      },
      { Header: "Total Amount", accessor: "total_amount" },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => (
          <span
            className={`badge text-white ${
              cell.value === "Approved"
                ? "bg-success"
                : cell.value === "inprogress"
                ? "bg-warning"
                : "bg-danger"
            }`}
          >
            {cell.value}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-link text-primary p-0 me-2"
              style={{ display: "inline", verticalAlign: "baseline" }}
              onClick={() => handleViewOrder(row.original)}
            >
              View
            </button>

            {row.original.status === "Approved" ? (
              ""
            ) : (
              <button
                className="btn btn-link text-primary p-0 ms-2"
                style={{ display: "inline", verticalAlign: "baseline" }}
                onClick={() => handleEditViewOrder(row.original)}
              >
                Edit
              </button>
            )}
          </>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => salesOrders, [salesOrders]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleSearch = async () => {
    setLoading(true); // Set loading to true when search starts
    try {
      const response = await ModService.getSalesOrders({
        page: currentPage + 1,
        limit: 10,
        search: searchTerm,
      });
      setSalesOrders(response.data.orders);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error searching clients", error);
    }
    setLoading(false); // Set loading to false after search completes
  };

  const handleAddSalesOrderClick = () => {
    navigate("/addSalesOrderPage");
  };

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  // Handle Edit employee view in modal
  const handleEditViewOrder = (order) => {
    setSelectedOrder(order); // Set the employee details in state
    setShowEditModal(true); // Open modal
  };

  const handleEditCloseModal = () => {
    setShowEditModal(false); // Close modal
    setSelectedOrder(null); // Clear selected employee data
  };

  const handleCloseDispatchModal = () => {
    setShowDispatchModal(false); // Close dispatch modal
    setSelectedOrder(null); // Clear selected order
  };

  const handleConfirmDispatch = (order) => {
    // Calculate dispatch items and totals
    const dispatchItems = order.items
      .filter((item) => item.dispatch_quantity > 0) // Only include items with a dispatch quantity
      .map((item) => {
        const price = +item.net_amount / +item.quantity || 0;
        const gst = (+item.gst / +item.net_amount) * 100 || 0;
        const netAmount = item.dispatch_quantity * price;
        const totalGst = (netAmount * gst) / 100;
        const totalAmount = netAmount + totalGst;

        return {
          order_item_id: item.id,
          dispatch_quantity: item.dispatch_quantity,
          net_amount: netAmount.toFixed(2),
          total_gst: totalGst.toFixed(2),
          total_amount: totalAmount.toFixed(2),
        };
      });

    // Calculate dispatch totals
    const totalNetAmount = dispatchItems.reduce(
      (sum, item) => sum + parseFloat(item.net_amount),
      0
    );
    const totalGst = dispatchItems.reduce(
      (sum, item) => sum + parseFloat(item.total_gst),
      0
    );
    const totalAmount = dispatchItems.reduce(
      (sum, item) => sum + parseFloat(item.total_amount),
      0
    );

    if (!order.invoice_id || !order.tracking_id) {
      alert("Please enter both Invoice ID and Tracking ID.");
      return;
    }

    if (dispatchItems.length === 0) {
      alert("Please select at least one item to dispatch.");
      return;
    }

    // Call an API to save the dispatch data
    ModService.createDispatch({
      order_id: order.id,
      invoice_id: order.invoice_id,
      tracking_id: order.tracking_id,
      net_amount: totalNetAmount.toFixed(2), // Total net amount for the dispatch
      total_gst: totalGst.toFixed(2), // Total GST for the dispatch
      total_amount: totalAmount.toFixed(2), // Total amount including GST for the dispatch
      items: dispatchItems, // Individual item details
    })
      .then(() => {
        alert("Dispatch created successfully.");
        setIsUpdated(true); // Trigger refresh of data
        handleCloseDispatchModal();
      })
      .catch((error) => {
        console.error("Error creating dispatch:", error);
        alert("Failed to create dispatch. Please try again.");
      });
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Sales Orders</h1>
        <button className="btn btn-primary" onClick={handleAddSalesOrderClick}>
          Add Sales Order
        </button>
      </div>

      <div className="input-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Search sales orders..."
          value={searchTerm}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-outline-secondary" onClick={handleSearch}>
          Search
        </button>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <table
            {...getTableProps()}
            className="table table-hover table-bordered"
          >
            <thead className="table">
              {headerGroups.map((headerGroup, headerGroupIndex) => {
                const { key, ...rest } = headerGroup.getHeaderGroupProps(); // Extract the key
                return (
                  <tr key={`headerGroup-${headerGroupIndex}`} {...rest}>
                    {headerGroup.headers.map((column, columnIndex) => {
                      const { key, ...restColumnProps } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <th
                          key={`header-${columnIndex}`} // Use key explicitly
                          {...restColumnProps} // Spread the rest of the props
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                const { key, ...rest } = row.getRowProps(); // Destructure key
                return (
                  <tr key={`row-${rowIndex}`} {...rest}>
                    {row.cells.map((cell, cellIndex) => {
                      const { key, ...restCellProps } = cell.getCellProps(); // Destructure key for cells
                      return (
                        <td
                          key={`cell-${rowIndex}-${cellIndex}`}
                          {...restCellProps}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </>
      )}

      {/* Modal for viewing order details */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder ? (
            <div>
              <div className="containe">
                <div className="row">
                  <div className="col">
                    <h5>Order ID: {selectedOrder.id}</h5>
                  </div>
                  <div className="col">
                    <h5>Status: {selectedOrder.status}</h5>
                  </div>
                </div>
              </div>
              <p>
                <strong>Order Number:</strong>{" "}
                {selectedOrder.sales_order_number}
              </p>
              <p>
                <strong>Client Code:</strong> {selectedOrder.client_name}
              </p>
              <p>
                <strong>Order Date:</strong>{" "}
                {moment(selectedOrder.created_at).format("DD MMM YYYY")}
              </p>
              <p>
                <strong>Total Amount:</strong> ₹{selectedOrder.total_amount}
              </p>
              <p>
                <strong>Status:</strong> {selectedOrder.status}
              </p>

              {/* Render the items array as a table */}
              <h6>Order Items</h6>
              {selectedOrder.items && selectedOrder.items.length > 0 ? (
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Product ID</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>Net Amount(₹)</th>
                      <th>GST(₹)</th>
                      <th>Total Amount(₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOrder.items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.productcode}</td>
                        <td>{item.product_name}</td>
                        <td>{item.quantity}</td>
                        <td>₹{item.net_amount}</td>
                        <td>₹{item.gst}</td>
                        <td>₹{item.total_amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No items found for this order.</p>
              )}
            </div>
          ) : (
            <p>No order selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedOrder?.status !== "Approved" &&
            selectedOrder?.status !== "Rejected" && (
              <>
                {statuses
                  .filter((item) => item.name !== selectedOrder?.status)
                  .map((item) => {
                    return (
                      <button
                        key={item.name}
                        className={`btn  ${
                          item.name === "Rejected"
                            ? "btn-danger"
                            : "btn-primary"
                        }`}
                        onClick={() => {
                          updateOrderStatus(
                            selectedOrder.id,
                            item.name,
                            selectedOrder.sales_order_number,
                            selectedOrder
                          );
                          handleCloseModal();
                        }}
                      >
                        {item.name === "Approved"
                          ? "Approve"
                          : item.name === "Rejected"
                          ? "Reject"
                          : item.name}
                      </button>
                    );
                  })}
                <br />
                <p>
                  Order details are final once approved, you won't be able to
                  edit them in the future.
                </p>
              </>
            )}

          {/* {selectedOrder?.status === "pending" ? (
            <button className="btn btn-primary" onClick={handleCloseModal}>
              Push to D365
            </button>
          ) : (
            ""
          )} */}
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Editing employee details */}
      <Modal
        show={showEditModal}
        onHide={handleEditCloseModal}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditOrderPage
            salesOrder={selectedOrder}
            setIsUpdated={setIsUpdated}
            handleEditCloseModal={handleEditCloseModal}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleEditCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDispatchModal}
        onHide={handleCloseDispatchModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Dispatch Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder ? (
            <div>
              <h5>Order ID: {selectedOrder.id}</h5>
              <p>
                <strong>Order Number:</strong>{" "}
                {selectedOrder.sales_order_number}
              </p>
              <p>
                <strong>Client Code:</strong> {selectedOrder.client_name}
              </p>
              <p>
                <strong>Order Date:</strong>{" "}
                {moment(selectedOrder.created_at).format("DD MMM YYYY")}
              </p>
              <p>
                <strong>Total Amount:</strong> ₹{selectedOrder.total_amount}
              </p>

              {/* Input fields for Invoice ID and Tracking ID */}
              <div className="mb-3">
                <label htmlFor="invoiceId" className="form-label">
                  Invoice ID
                </label>
                <input
                  type="text"
                  id="invoiceId"
                  className="form-control"
                  value={selectedOrder.invoice_id || ""}
                  onChange={(e) =>
                    setSelectedOrder({
                      ...selectedOrder,
                      invoice_id: e.target.value,
                    })
                  }
                  placeholder="Enter Invoice ID"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="trackingId" className="form-label">
                  Tracking ID
                </label>
                <input
                  type="text"
                  id="trackingId"
                  className="form-control"
                  value={selectedOrder.tracking_id || ""}
                  onChange={(e) =>
                    setSelectedOrder({
                      ...selectedOrder,
                      tracking_id: e.target.value,
                    })
                  }
                  placeholder="Enter Tracking ID"
                />
              </div>

              <h6>Balance Items</h6>
              {selectedOrder.items && selectedOrder.items.length > 0 ? (
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Product ID</th>
                      <th>Quantity</th>
                      <th>Dispatched</th>
                      <th>Balance</th>
                      <th>Dispatch Quantity</th>
                      <th>Price (₹)</th>
                      <th>GST (%)</th>
                      <th>Net Amount (₹)</th>
                      <th>Total GST (₹)</th>
                      <th>Total Amount (₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOrder.items.map((item, index) => {
                      const balance =
                        item.quantity - (item.dispatched_quantity || 0);
                      const dispatchQuantity = item.dispatch_quantity || 0;
                      const price = +item.net_amount / +item.quantity || 0;
                      const gst = (+item.gst / +item.net_amount) * 100 || 0;
                      const netAmount = dispatchQuantity * price;
                      const totalGst = (netAmount * gst) / 100;
                      const totalAmount = netAmount + totalGst;

                      return (
                        <tr key={index}>
                          <td>{item.productcode}</td>
                          <td>{item.quantity}</td>
                          <td>{item.dispatched_quantity || 0}</td>
                          <td>{balance}</td>
                          <td>
                            <input
                              type="number"
                              min="0"
                              max={balance}
                              className="form-control"
                              value={dispatchQuantity}
                              onChange={(e) => {
                                const value = Math.min(
                                  balance,
                                  parseInt(e.target.value) || 0
                                );
                                const updatedItems = [...selectedOrder.items];
                                updatedItems[index].dispatch_quantity = value;
                                setSelectedOrder({
                                  ...selectedOrder,
                                  items: updatedItems,
                                });
                              }}
                            />
                          </td>
                          <td>{price.toFixed(2)}</td>
                          <td>{gst.toFixed(2)}</td>
                          <td>₹{netAmount.toFixed(2)}</td>
                          <td>₹{totalGst.toFixed(2)}</td>
                          <td>₹{totalAmount.toFixed(2)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>No items found for this order.</p>
              )}

              {/* Dispatched Summary */}
              <div className="mt-4">
                <h6>Dispatched Summary</h6>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Total Net Amount (₹)</th>
                      <th>Total GST (₹)</th>
                      <th>Total Amount (₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        ₹
                        {selectedOrder.items
                          .reduce(
                            (sum, item) =>
                              sum +
                              (item.dispatch_quantity || 0) *
                                (+item.net_amount / +item.quantity || 0),
                            0
                          )
                          .toFixed(2)}
                      </td>
                      <td>
                        ₹
                        {selectedOrder.items
                          .reduce((sum, item) => {
                            const dispatchQuantity =
                              item.dispatch_quantity || 0;
                            const price =
                              +item.net_amount / +item.quantity || 0;
                            const gst =
                              (+item.gst / +item.net_amount) * 100 || 0;
                            const netAmount = dispatchQuantity * price;
                            return sum + (netAmount * gst) / 100;
                          }, 0)
                          .toFixed(2)}
                      </td>
                      <td>
                        ₹
                        {selectedOrder.items
                          .reduce((sum, item) => {
                            const dispatchQuantity =
                              item.dispatch_quantity || 0;
                            const price =
                              +item.net_amount / +item.quantity || 0;
                            const gst =
                              (+item.gst / +item.net_amount) * 100 || 0;
                            const netAmount = dispatchQuantity * price;
                            const totalGst = (netAmount * gst) / 100;
                            return sum + netAmount + totalGst;
                          }, 0)
                          .toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <button
                className="btn btn-primary mt-3"
                onClick={() => handleConfirmDispatch(selectedOrder)}
              >
                Confirm Dispatch
              </button>
            </div>
          ) : (
            <p>No order selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleCloseDispatchModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SalesOrderListPage;
