import React, { useState, useRef } from "react";
import { addBrand } from "../../../actions/modertor";
import { useDispatch } from "react-redux";

const AddBrandPage = () => {
  const [brandImage, setbrandImage] = useState(null);
  const [brandImageBanner, setbrandImageBanner] = useState(null);

  const [brandData, setBrandData] = useState({
    brand_name: "",
  });
  const picturesInputRef = useRef(null);
  const picturesBannerInputRef = useRef(null);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrandData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle file uploads
  const handleBrandImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setbrandImage(e.target.files[0]);
    } else {
      alert("Error: Only JPEG, JPG, and PNG formats are allowed.");
    }
  };

  // Handle file uploads
  const handleBrandImageBannerUpload = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setbrandImageBanner(e.target.files[0]);
    } else {
      alert("Error: Only JPEG, JPG, and PNG formats are allowed.");
    }
  };
  // Remove files
  const handleRemoveBrandImage = () => setbrandImage(null);

  // Remove files
  const handleRemoveBrandImageBanner = () => setbrandImageBanner(null);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!brandImage) {
      alert("Brand Image is required");
      return;
    }

    const formData = new FormData();

    formData.append("brand_name", brandData.brand_name);
    formData.append("brandImage", brandImage);
    formData.append("brandImageBanner", brandImageBanner);

    dispatch(addBrand(formData))
      .then(() => {
        alert("Brand added successfully!");
        setBrandData({ brand_name: "" }); // Reset form
        setbrandImage(null);
        setError(false);
        if (picturesInputRef.current) {
          picturesInputRef.current.value = null;
        }
        if (picturesBannerInputRef.current) {
          picturesBannerInputRef.current.value = null;
        }
      })
      .catch((error) => {
        console.log(error.status);
        if (error.status === 501) {
          setError(error.message);
        } else {
          console.error("Error adding brand:", error.message);
        }
      });
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow p-4">
            <h2 className="text-center mb-4">Add New Brand</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <label htmlFor="brand_name" className="form-label">
                  Brand Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="brand_name"
                  name="brand_name"
                  value={brandData.brand_name}
                  onChange={handleChange}
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  placeholder="Enter brand name"
                  required
                />
                {error && <div className="invalid-feedback">{error}</div>}
              </div>

              {/* Main Picture */}
              <div className="mb-3">
                <label htmlFor="main_picture" className="form-label">
                  Main Picture <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="file"
                  id="brandImage"
                  className="form-control"
                  accept="image/*"
                  onChange={handleBrandImageUpload}
                  required
                  ref={picturesInputRef}
                />
                {brandImage && (
                  <div className="mt-2">
                    <img
                      src={URL.createObjectURL(brandImage)}
                      alt="Preview"
                      className="img-thumbnail"
                      style={{ width: "150px" }}
                    />
                    <button
                      type="button"
                      className="btn btn-danger mt-2"
                      onClick={handleRemoveBrandImage}
                    >
                      Remove Main Picture
                    </button>
                  </div>
                )}
              </div>

              {/* Main Picture */}
              <div className="mb-3">
                <label htmlFor="banner_picture" className="form-label">
                  Banner Picture <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="file"
                  id="bannerImage"
                  className="form-control"
                  accept="image/*"
                  onChange={handleBrandImageBannerUpload}
                  required
                  ref={picturesBannerInputRef}
                />
                {brandImageBanner && (
                  <div className="mt-2">
                    <img
                      src={URL.createObjectURL(brandImageBanner)}
                      alt="Preview"
                      className="img-thumbnail"
                      style={{ width: "150px" }}
                    />
                    <button
                      type="button"
                      className="btn btn-danger mt-2"
                      onClick={handleRemoveBrandImageBanner}
                    >
                      Remove Banner Picture
                    </button>
                  </div>
                )}
              </div>

              <div className="d-grid">
                <button type="submit" className="btn btn-primary">
                  Add Brand
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBrandPage;
