import React, { useEffect, useState } from "react";
import UserService from "../../services/userService";
import "./custom.css";
// import HeaderCard from "./HeaderCard";
import FilteredElements from "./FilteredElements";
import ProductGrid from "./ProductGrid";

const SidebarFilter = ({
  brand,
  setBrandData,
  category,
  setCategoryData,
  searchTerm,
  setSearchTerm,
  setBrandBannerImage,
  brandBannerImage,
}) => {
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [maxMrp, setMaxMrp] = useState(0);
  const [prices, setPrices] = useState({ min: "", max: "" });
  const [priceFilter, setPriceFilter] = useState({ min: "", max: "" });

  useEffect(() => {
    if (brand) {
      setSelectedBrands((prev) => {
        const id = parseInt(brand.id);
        if (!prev.includes(id)) {
          return [...prev, id];
        }
        return prev;
      });

      setSelectedFilters((prev) => {
        if (
          !prev.some(
            (filter) => filter.id === brand.id && filter.type === "brand"
          )
        ) {
          return [
            ...prev,
            { id: brand.id, name: brand.brand_name, type: "brand" },
          ];
        }
        return prev;
      });

      setBrandBannerImage(brand.banner);

      setBrandData(null); // Reset the brand prop
    }
  }, [brand, setBrandData, setBrandBannerImage]);

  useEffect(() => {
    if (category) {
      setSelectedCategories((prev) => {
        const id = parseInt(category.id);
        if (!prev.includes(id)) {
          return [...prev, id];
        }
        return prev;
      });

      setSelectedFilters((prev) => {
        if (
          !prev.some(
            (filter) => filter.id === category.id && filter.type === "category"
          )
        ) {
          return [
            ...prev,
            {
              id: category.id,
              name: category.cat_name,
              type: "category",
            },
          ];
        }
        return prev;
      });

      setCategoryData(null); // Reset the category prop
    }
  }, [category, setCategoryData]);

  const handleCheckboxChange = (type, selectedItem) => {
    const { id, cat_name, brand_name } = selectedItem || {};

    if (type === "category" && selectedCategories.includes(id)) {
      // Remove category
      setSelectedCategories((prev) =>
        prev.filter((categoryId) => categoryId !== id)
      );
      setSelectedFilters((prev) =>
        prev.filter(
          (filter) => !(parseInt(filter.id) === id && filter.type === type)
        )
      );
    } else if (type === "brand") {
      if (selectedBrands.includes(id)) {
        // Remove the brand
        const updatedBrands = selectedBrands.filter(
          (brandId) => brandId !== id
        );
        setSelectedBrands(updatedBrands);
        setSelectedFilters((prev) =>
          prev.filter(
            (filter) => !(parseInt(filter.id) === id && filter.type === type)
          )
        );

        // Show the banner only if one brand remains
        if (updatedBrands.length === 1) {
          const remainingBrand = brands.find(
            (brand) => brand.id === updatedBrands[0]
          );
          setBrandBannerImage(remainingBrand?.banner || null);
        } else {
          setBrandBannerImage(null); // No banner if 0 or multiple brands
        }
      } else {
        // Add the brand
        if (!selectedBrands.includes(id)) {
          const updatedBrands = [...selectedBrands, id];
          setSelectedBrands(updatedBrands);
          setSelectedFilters((prev) => [
            ...prev,
            { id, name: brand_name, type },
          ]);

          // Show the banner only if this is the only selected brand
          if (updatedBrands.length === 1) {
            const selectedBrand = brands.find((brand) => brand.id === id);
            setBrandBannerImage(selectedBrand?.banner || null);
          } else {
            setBrandBannerImage(null); // No banner if multiple brands
          }
        }
      }
    } else if (type === "price") {
      const minVal = prices.min !== "" ? parseFloat(prices.min) : null;
      const maxVal = prices.max !== "" ? parseFloat(prices.max) : null;

      // Validate that min and max are numbers if they are provided
      if (
        (minVal === null || !isNaN(minVal)) &&
        (maxVal === null || !isNaN(maxVal))
      ) {
        if (minVal !== null && maxVal !== null && minVal > maxVal) {
          alert("Minimum price cannot be greater than maximum price.");
          return;
        }

        setPriceFilter(prices);

        // Remove existing price filter if any
        setSelectedFilters((prev) => {
          const filtered = prev.filter((filter) => filter.type !== "price");
          // Construct filter name based on provided min and max
          let filterName = "Price: ";
          if (minVal !== null && maxVal !== null) {
            filterName += `₹${minVal} - ₹${maxVal}`;
          } else if (minVal !== null) {
            filterName += `From ₹${minVal}`;
          } else if (maxVal !== null) {
            filterName += `Up to ₹${maxVal}`;
          } else {
            // If neither min nor max is provided, do not add the filter
            return filtered;
          }

          return [
            ...filtered,
            {
              id: "price",
              name: filterName,
              type: "price",
              min: minVal,
              max: maxVal,
            },
          ];
        });
      } else {
        alert("Please enter valid numerical values for price.");
      }
    } else {
      // Add to selected lists
      if (type === "category") {
        if (!selectedCategories.includes(id)) {
          setSelectedCategories((prev) => [...prev, id]);
          setSelectedFilters((prev) => [...prev, { id, name: cat_name, type }]);
        }
      }
    }
  };

  const removeFilter = (type, filterId) => {
    // Remove filter from both lists when the user removes a filter
    if (type === "category") {
      setSelectedCategories((prev) => {
        const id = parseInt(filterId);
        const updatedCategory = prev.filter((categoryId) => categoryId !== id);
        return updatedCategory;
      });
    } else if (type === "brand") {
      const updatedBrands = selectedBrands.filter(
        (brandId) => brandId !== parseInt(filterId)
      );
      setSelectedBrands(updatedBrands);

      // Show the banner only if one brand remains
      if (updatedBrands.length === 1) {
        const remainingBrand = brands.find(
          (brand) => brand.id === updatedBrands[0]
        );
        setBrandBannerImage(remainingBrand?.banner || null);
      } else {
        setBrandBannerImage(null); // No banner if 0 or multiple brands
      }
    } else if (type === "price") {
      setPriceFilter({ min: "", max: "" });
    }

    setSelectedFilters((prev) =>
      prev.filter((filter) => !(filter.id === filterId && filter.type === type))
    );
  };

  const clearAllFilter = () => {
    setSelectedBrands([]);
    setSelectedCategories([]);
    setSelectedFilters([]);
    setPriceFilter({ min: "", max: "" });
  };

  useEffect(() => {
    UserService.getBrand({ limit: 999999 }).then((response) => {
      setBrands(response.data.brands);
    });
  }, []);

  useEffect(() => {
    UserService.getCategory({ limit: 999999 }).then((response) => {
      setCategories(response.data.categories);
    });
  }, []);

  useEffect(() => {
    UserService.getMaxPrice().then((response) => {
      // console.log(response.data.maxMRP);
      setMaxMrp(response.data.maxMRP);
    });
  }, []);

  return (
    <>
      <aside className="col-xl-2 col-lg-3">
        <div
          className="fw-bold text-dark py-3 d-block border-top cursor-pointer"
          data-bs-toggle="collapse"
          data-bs-target="#aside_filter"
          role="button"
        >
          Show filter
        </div>

        {/* ===== Card for sidebar filter ===== */}
        <div id="aside_filter" className="collapse d-lg-block mb-5">
          {/* Filter by Brand */}
          <article>
            <button
              className="fw-bold text-dark d-block btn btn-link outline-0 px-0"
              data-bs-toggle="collapse"
              data-bs-target="#collapse_aside_brands"
            >
              <i className="icon-control fa fa-chevron-down"></i> Brand
            </button>
            <div
              className="collapse show scrollable-container"
              id="collapse_aside_brands"
            >
              {brands.map((brand) => (
                <div key={"brand-" + brand.id}>
                  <label className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={brand.id}
                      checked={selectedBrands.includes(brand.id)}
                      onChange={() => handleCheckboxChange("brand", brand)}
                    />
                    <span className="form-check-label">{brand.brand_name}</span>
                  </label>
                </div>
              ))}
            </div>
          </article>

          {/* Filter by Category */}
          <article>
            <button
              className="fw-bold text-dark d-block btn btn-link outline-0 px-0"
              data-bs-toggle="collapse"
              data-bs-target="#collapse_aside_categories"
            >
              <i className="icon-control fa fa-chevron-down"></i> Category
            </button>
            <div
              className="collapse show scrollable-container"
              id="collapse_aside_categories"
            >
              {categories.map((category) => (
                <div key={"category-" + category.id}>
                  <label className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={category.id}
                      checked={selectedCategories.includes(category.id)}
                      onChange={() =>
                        handleCheckboxChange("category", category)
                      }
                    />
                    <span className="form-check-label">
                      {category.cat_name}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </article>

          {/* Filter by Price */}
          <article>
            <button
              className="fw-bold text-dark d-block btn btn-link outline-0 px-0"
              data-bs-toggle="collapse"
              data-bs-target="#collapse_aside_price"
            >
              <i className="icon-control fa fa-chevron-down"></i> Price Range
            </button>
            <div className="collapse show" id="collapse_aside_price">
              <div className="pb-3">
                <div className="row mb-2 g-2">
                  <div className="col-6">
                    <label htmlFor="min" className="form-label">
                      Min
                    </label>
                    <input
                      className="form-control"
                      id="min"
                      placeholder="₹0"
                      min={0}
                      value={prices.min}
                      onChange={(e) =>
                        setPrices({ ...prices, min: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="max" className="form-label">
                      Max
                    </label>
                    <input
                      className="form-control"
                      id="max"
                      max={maxMrp}
                      placeholder={"₹" + maxMrp}
                      value={prices.max}
                      onChange={(e) =>
                        setPrices({ ...prices, max: e.target.value })
                      }
                    />
                  </div>
                </div>
                <button
                  className="btn btn-light text-primary w-100"
                  type="button"
                  onClick={() => handleCheckboxChange("price")}
                >
                  Apply
                </button>
              </div>
            </div>
          </article>
        </div>
      </aside>
      <main className="col-xl-10 col-lg-9 d-block overflow-scroll">
        {/* <HeaderCard /> */}
        <FilteredElements
          selectedFilters={selectedFilters}
          removeFilter={removeFilter}
          clearAllFilter={clearAllFilter}
        />
        <ProductGrid
          brandBannerImage={brandBannerImage}
          selectedBrands={selectedBrands}
          selectedCategories={selectedCategories}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          minPrice={priceFilter.min !== "" ? parseFloat(priceFilter.min) : null}
          maxPrice={priceFilter.max !== "" ? parseFloat(priceFilter.max) : null}
        />
      </main>
    </>
  );
};

export default SidebarFilter;
