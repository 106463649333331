import React, { useState, useEffect } from "react";
import ModService from "../../../services/modService";

const BusinessTypePage = () => {
  const [businessTypes, setBusinessTypes] = useState([]); // State to hold business types
  const [editingId, setEditingId] = useState(null); // ID of the item being edited
  const [editName, setEditName] = useState(""); // New name for editing
  const [newName, setNewName] = useState(""); // New business type to be added

  // Fetch business types on component mount
  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  const fetchBusinessTypes = async () => {
    try {
      const response = await ModService.getBusinessType();
      // console.log(response); // Replace with your API endpoint
      setBusinessTypes(response.data);
    } catch (error) {
      console.error("Error fetching business types:", error);
    }
  };

  const handleEdit = (id, name) => {
    setEditingId(id);
    setEditName(name);
  };

  const handleSave = async (id) => {
    try {
      await ModService.updateBusinessType(id, editName); // Replace with your API endpoint
      setEditingId(null);
      fetchBusinessTypes(); // Refresh the list
    } catch (error) {
      console.error("Error updating business type:", error);
    }
  };

  const handleAdd = async () => {
    if (!newName.trim()) {
      alert("Please enter a valid name.");
      return;
    }

    try {
      await ModService.addBusinessType(newName); // Replace with your API endpoint
      setNewName("");
      fetchBusinessTypes(); // Refresh the list
    } catch (error) {
      console.error("Error adding business type:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this business type?")) {
      try {
        await ModService.deleteBusinessType(id); // Replace with your API endpoint
        fetchBusinessTypes(); // Refresh the list
      } catch (error) {
        console.error("Error deleting business type:", error);
      }
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Manage Business Types</h2>

      {/* Add new business type */}
      <div className="input-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Add new business type"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleAdd}>
          Add
        </button>
      </div>

      {/* Business types table */}
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {businessTypes.map((type) => (
            <tr key={type.id}>
              <td>{type.id}</td>
              <td>
                {editingId === type.id ? (
                  <input
                    type="text"
                    className="form-control"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                  />
                ) : (
                  type.name
                )}
              </td>
              <td>
                {editingId === type.id ? (
                  <>
                    <button
                      className="btn btn-success btn-sm me-2"
                      onClick={() => handleSave(type.id)}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={() => setEditingId(null)}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-warning btn-sm me-2"
                      onClick={() => handleEdit(type.id, type.name)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(type.id)}
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BusinessTypePage;
