import React from "react";
import FirstComponent from "./PendingOrderList"; // Assuming this is your first component
import SecondComponent from "./PendingClientListPage"; // Add your second component
// import ThirdComponent from "./PendingOrderList"; // Add your third component
// import FourthComponent from "./PendingOrderList"; // Add your fourth component

const Dashboard = () => {
  return (
    <div className="container-fluid">
      {/* First component - Full width */}
      <div className="row">
        <div className="col-12">
          <div className="p-3 mb-4 bg-white border rounded shadow-sm">
            <FirstComponent />
          </div>
        </div>
      </div>

      {/* Second component - Full width */}
      <div className="row mt-4">
        <div className="col-12">
          <div className="p-3 mb-4 bg-white border rounded shadow-sm">
            <SecondComponent />
          </div>
        </div>
      </div>

      {/* Third and Fourth components - Half width each, side by side */}
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="p-3 mb-4 bg-white border rounded shadow-sm">
            Complete Order Count (Current month)
            {/* <ThirdComponent /> */}
          </div>
        </div>
        <div className="col-md-6">
          <div className="p-3 mb-4 bg-white border rounded shadow-sm">
            {/* <FourthComponent /> */}
            Top 5 product quantity
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
