import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const Aboutus = () => {
  return (
    <>
      <Navbar />
      <div className="purpose-page">
        {/* Hero Section */}
        <section className="hero-section text-center py-5 bg-light">
          <div className="container">
            <h1 className="display-4 fw-bold">Our Purpose</h1>
            <p className="lead">
              Empowering people and organizations with innovative solutions for
              a sustainable future.
            </p>
          </div>
        </section>

        {/* Mission Section */}
        <section className="mission-section text-center py-5">
          <div className="container">
            <h2 className="h2 fw-bold">Our Mission</h2>
            <p className="lead">
              To be the leading provider of electrical products, solutions, and
              related services, enhancing the productivity and sustainability of
              our customers.
            </p>
          </div>
        </section>

        {/* Values Section */}
        <section className="values-section py-5 bg-light">
          <div className="container">
            <h2 className="h2 fw-bold text-center mb-4">Our Values</h2>
            <div className="row">
              <div className="col-md-6 col-lg-3 text-center mb-4">
                <h3 className="h5 fw-bold">Customer Focus</h3>
                <p>
                  We prioritize our customers' needs and strive to exceed their
                  expectations.
                </p>
              </div>
              <div className="col-md-6 col-lg-3 text-center mb-4">
                <h3 className="h5 fw-bold">Innovation</h3>
                <p>
                  We embrace change and continuously seek new ways to improve.
                </p>
              </div>
              <div className="col-md-6 col-lg-3 text-center mb-4">
                <h3 className="h5 fw-bold">Integrity</h3>
                <p>We conduct our business with honesty and transparency.</p>
              </div>
              <div className="col-md-6 col-lg-3 text-center mb-4">
                <h3 className="h5 fw-bold">Sustainability</h3>
                <p>
                  We are committed to sustainable practices that benefit society
                  and the environment.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Aboutus;
