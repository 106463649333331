import { SET_MESSAGE, ERROR_MESSAGE } from "./types";

import ModService from "../services/modService";

export const addCategory = (categoryData) => (dispatch) => {
  return ModService.addCategory(categoryData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      console.log(error.status);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const addSubCategory = (subCategoryData) => (dispatch) => {
  return ModService.addSubCategory(subCategoryData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const addBrand = (brandData) => (dispatch) => {
  return ModService.addBrand(brandData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const addProduct = (productData) => (dispatch) => {
  return ModService.addProduct(productData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const addClient = (clientData) => (dispatch) => {
  return ModService.addClient(clientData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const addEmployee = (employeeData) => (dispatch) => {
  return ModService.addEmployee(employeeData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const addSalesOrder = (salesOrderData) => (dispatch) => {
  return ModService.addSalesOrder(salesOrderData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const updateProduct = (productData) => (dispatch) => {
  return ModService.updateProduct(productData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const bulkUploadProducts = (products) => (dispatch) => {
  return ModService.bulkUploadProducts(products).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const updateEmployeeProfile = (employeeData) => (dispatch) => {
  return ModService.updateEmployeeProfile(employeeData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const updateClientStatus = (clientData) => (dispatch) => {
  return ModService.updateClientStatus(clientData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const updateBrand = (brandData) => (dispatch) => {
  return ModService.updateBrand(brandData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const updateCategory = (categoryData) => (dispatch) => {
  return ModService.updateCategory(categoryData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const updateSubCategory = (subcategoryData) => (dispatch) => {
  return ModService.updateSubCategory(subcategoryData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const updateClient = (clientData) => (dispatch) => {
  return ModService.updateClient(clientData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, status: error.response?.status });
    }
  );
};

export const updateSalesOrder = (orderData) => (dispatch) => {
  return ModService.updateSalesOrder(orderData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const updateSalesOrderStatus = (orderData) => (dispatch) => {
  return ModService.updateSalesOrderStatus(orderData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const deleteProduct = (productData) => (dispatch) => {
  return ModService.deleteProduct(productData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const updateEmployee = (employeeData) => (dispatch) => {
  return ModService.updateEmployee(employeeData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      const role =
        (error.response && error.response.data && error.response.data.role) |
        dispatch({
          type: ERROR_MESSAGE,
          payload: message,
        });

      return Promise.reject({ message, role });
    }
  );
};

export const addBanner = (bannerData) => (dispatch) => {
  return ModService.addBanner(bannerData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const updateBanner = (bannerData) => (dispatch) => {
  return ModService.updateBanner(bannerData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const deleteBanner = (bannerData) => (dispatch) => {
  return ModService.deleteBanner(bannerData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};
