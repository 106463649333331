import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../actions/auth";

const ResetPassword = () => {
  const { hash } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [validationError, setValidationError] = useState("");
  const dispatch = useDispatch();

  // Password validation function
  const validatePassword = (password) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    if (!/[A-Z]/.test(password)) {
      return "Password must include at least one uppercase letter.";
    }
    if (!/[a-z]/.test(password)) {
      return "Password must include at least one lowercase letter.";
    }
    if (!/[0-9]/.test(password)) {
      return "Password must include at least one digit.";
    }
    if (!/[\W_]/.test(password)) {
      return "Password must include at least one special character.";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    // Validate password
    const validationMessage = validatePassword(newPassword);
    if (validationMessage) {
      setValidationError(validationMessage);
      return;
    }
    setValidationError("");

    dispatch(resetPassword(hash, newPassword))
      .then(() => {
        alert("Password reset successful");
        setNewPassword(""); // Clear the password field
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div className="container mt-5">
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label>New Password</label>
          <input
            type="password"
            className={`form-control ${validationError ? "is-invalid" : ""}`}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          {validationError && (
            <div className="invalid-feedback">{validationError}</div>
          )}
        </div>
        <button type="submit" className="btn btn-primary">
          Reset Password
        </button>
      </form>
      {message && <p className="text-success mt-3">{message}</p>}
      {error && <p className="text-danger mt-3">{error}</p>}
    </div>
  );
};

export default ResetPassword;
