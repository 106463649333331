import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";
import ReactPaginate from "react-paginate";
import ModService from "../../../services/modService";
import { Modal } from "react-bootstrap"; // Modal for viewing details
import moment from "moment";

const RejectedOrderList = () => {
  const [salesOrders, setSalesOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchSalesOrders = async () => {
      setLoading(true);
      try {
        const response = await ModService.getSalesOrders({
          page: currentPage + 1,
          limit: 10,
          status: "rejected",
        });
        setSalesOrders(response.data.orders);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching sales orders", error);
      }
      setLoading(false);
    };
    fetchSalesOrders();
  }, [currentPage]);

  const columns = useMemo(
    () => [
      { Header: "Order ID", accessor: "id" },
      { Header: "Sales Or. No", accessor: "sales_order_number" },
      { Header: "Client Code", accessor: "client_name" },
      {
        Header: "Cust. Name",
        accessor: "fullName", // Virtual accessor (not directly in the data)
        Cell: ({ row }) => `${row.original.firstName} ${row.original.lastName}`, // Combine first and last name
      },

      { Header: "username", accessor: "user_name" },
      {
        Header: "Order Date",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("DD MMM YYYY"),
      },
      { Header: "Total Amount", accessor: "total_amount" },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => (
          <span
            className={`badge text-white ${
              cell.value === "approved"
                ? "bg-success"
                : cell.value === "inprogress"
                ? "bg-warning"
                : "bg-danger"
            }`}
          >
            {cell.value}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-link text-primary p-0 me-2"
              style={{ display: "inline", verticalAlign: "baseline" }}
              onClick={() => handleViewOrder(row.original)}
            >
              View
            </button>
          </>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => salesOrders, [salesOrders]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleSearch = async () => {
    setLoading(true); // Set loading to true when search starts
    try {
      const response = await ModService.getSalesOrders({
        page: currentPage + 1,
        limit: 10,
        search: searchTerm,
        status: "rejected",
      });
      setSalesOrders(response.data.orders);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error searching clients", error);
    }
    setLoading(false); // Set loading to false after search completes
  };

  const handleViewOrder = (order) => {
    console.log(order);
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Sales Orders</h1>
      </div>

      <div className="input-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Search sales orders..."
          value={searchTerm}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-outline-secondary" onClick={handleSearch}>
          Search
        </button>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <table
            {...getTableProps()}
            className="table table-hover table-bordered"
          >
            <thead className="table">
              {headerGroups.map((headerGroup, headerGroupIndex) => {
                const { key, ...rest } = headerGroup.getHeaderGroupProps(); // Extract the key
                return (
                  <tr key={`headerGroup-${headerGroupIndex}`} {...rest}>
                    {headerGroup.headers.map((column, columnIndex) => {
                      const { key, ...restColumnProps } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <th
                          key={`header-${columnIndex}`} // Use key explicitly
                          {...restColumnProps} // Spread the rest of the props
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                const { key, ...rest } = row.getRowProps(); // Destructure key
                return (
                  <tr key={`row-${rowIndex}`} {...rest}>
                    {row.cells.map((cell, cellIndex) => {
                      const { key, ...restCellProps } = cell.getCellProps(); // Destructure key for cells
                      return (
                        <td
                          key={`cell-${rowIndex}-${cellIndex}`}
                          {...restCellProps}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </>
      )}

      {/* Modal for viewing order details */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder ? (
            <div>
              <h5>Order ID: {selectedOrder.id}</h5>
              <p>
                <strong>Order Number:</strong>{" "}
                {selectedOrder.sales_order_number}
              </p>
              <p>
                <strong>Client Code:</strong> {selectedOrder.client_name}
              </p>
              <p>
                <strong>Order Date:</strong>{" "}
                {moment(selectedOrder.created_at).format("DD MMM YYYY")}
              </p>
              <p>
                <strong>Total Amount:</strong> ₹{selectedOrder.total_amount}
              </p>
              <p>
                <strong>Status:</strong> {selectedOrder.status}
              </p>

              {/* Render the items array as a table */}
              <h6>Order Items</h6>
              {selectedOrder.items && selectedOrder.items.length > 0 ? (
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Product ID</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>Net Amount(₹)</th>
                      <th>GST(₹)</th>
                      <th>Total Amount(₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOrder.items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.product_id}</td>
                        <td>{item.product_name}</td>
                        <td>{item.quantity}</td>
                        <td>{item.net_amount}</td>
                        <td>{item.gst}</td>
                        <td>{item.total_amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No items found for this order.</p>
              )}
            </div>
          ) : (
            <p>No order selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RejectedOrderList;
