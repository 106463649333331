// QuantityCellEditor.js
import React, { useEffect, useRef } from "react";

const QuantityCellEditor = (props) => {
  const inputRef = useRef(null);
  const { moq } = props.node.data;

  useEffect(() => {
    // Focus on the input when the editor is opened
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    });
  }, []);

  // const getValue = () => {
  //   let value = parseInt(inputRef.current.value, 10);
  //   if (isNaN(value) || value < moq) {
  //     value = moq;
  //   }
  //   return value;
  // };

  // const isCancelAfterEnd = () => {
  //   let value = parseInt(inputRef.current.value, 10);
  //   return value < moq;
  // };

  const onKeyDown = (event) => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.stopPropagation();
    }
  };

  return (
    <input
      type="number"
      ref={inputRef}
      defaultValue={props.value}
      min={moq}
      style={{
        width: "100%",
        height: "100%",
        border: "none",
        outline: "none",
        textAlign: "center",
      }}
      onKeyDown={onKeyDown}
    />
  );
};

export default QuantityCellEditor;
