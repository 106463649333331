import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";
import ReactPaginate from "react-paginate";
import ModService from "../../../services/modService";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { updateCategory, updateSubCategory } from "../../../actions/modertor";
import { useDispatch } from "react-redux";

const CategorySubcategoryPage = () => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categorySearchTerm, setCategorySearchTerm] = useState("");
  const [subCategorySearchTerm, setSubCategorySearchTerm] = useState("");
  const [currentCategoryPage, setCurrentCategoryPage] = useState(0);
  const [currentSubCategoryPage, setCurrentSubCategoryPage] = useState(0);
  const [totalCategoryPages, setTotalCategoryPages] = useState(1);
  const [totalSubCategoryPages, setTotalSubCategoryPages] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null); // Selected category for edit
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // Selected subcategory for edit
  const [showCategoryModal, setShowCategoryModal] = useState(false); // Modal visibility for categories
  const [showSubCategoryModal, setShowSubCategoryModal] = useState(false); // Modal visibility for subcategories
  const [categoriesOption, setCategoriesOption] = useState([]);

  const navigate = useNavigate();

  // Fetch categories for the dropdown
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await ModService.getAllCategory();
        setCategoriesOption(response.data);
      } catch (error) {
        console.error("Error fetching categories", error);
      }
    };

    fetchCategories();
  }, []);

  // Fetch categories function
  const fetchCategories = async (params) => {
    try {
      const response = await ModService.getCategory({
        page: currentCategoryPage + 1,
        search: categorySearchTerm,
      });
      setCategories(response.data.categories || []);
      setTotalCategoryPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  // Fetch subcategories function
  const fetchSubCategories = async () => {
    try {
      const response = await ModService.getSubCategory({
        page: currentSubCategoryPage + 1,
        search: subCategorySearchTerm,
      });
      setSubCategories(response.data.subcategories || []);
      setTotalSubCategoryPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching subcategories", error);
    }
  };

  // Fetch categories and subcategories from the backend on page load and when any dependencies change
  useEffect(() => {
    // Fetch categories function
    const fetchCategories = async () => {
      try {
        const response = await ModService.getCategory({
          page: currentCategoryPage + 1,
          search: categorySearchTerm,
        });
        setCategories(response.data.categories || []);
        setTotalCategoryPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching categories", error);
      }
    };

    // Fetch subcategories function
    const fetchSubCategories = async () => {
      try {
        const response = await ModService.getSubCategory({
          page: currentSubCategoryPage + 1,
          search: subCategorySearchTerm,
        });
        setSubCategories(response.data.subcategories || []);
        setTotalSubCategoryPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching subcategories", error);
      }
    };

    fetchCategories();
    fetchSubCategories();
  }, [
    currentCategoryPage,
    currentSubCategoryPage,
    categorySearchTerm,
    subCategorySearchTerm,
  ]);

  // Edit category
  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setShowCategoryModal(true);
  };

  // Edit subcategory
  const handleEditSubCategory = (subCategory) => {
    setSelectedSubCategory(subCategory);
    setShowSubCategoryModal(true);
  };

  // Handle category update submission
  const handleUpdateCategory = async (e) => {
    e.preventDefault();

    dispatch(updateCategory(selectedCategory))
      .then(() => {
        alert("Category updated successfully");
        setShowCategoryModal(false);
        setSelectedCategory(null);
        fetchCategories(); // Refresh data
        fetchSubCategories(); // Refresh data
      })
      .catch((error) => {
        if (error.status === 501) {
          alert(error.message);
        } else {
          console.log(error);
        }
      });
  };

  // Handle subcategory update submission
  const handleUpdateSubCategory = async (e) => {
    e.preventDefault();
    dispatch(updateSubCategory(selectedSubCategory))
      .then(() => {
        alert("Subcategory updated successfully");
        setShowSubCategoryModal(false);
        setSelectedSubCategory(null);
        fetchSubCategories(); // Refresh data
      })
      .catch((error) => {
        if (error.status === 501) {
          alert(error.message);
        } else {
          console.log(error);
        }
      });
  };

  // Column definitions for categories table
  const categoryColumns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Category Name", accessor: "cat_name" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-link text-primary p-0 ms-2"
              onClick={() => handleEditCategory(row.original)}
            >
              Edit
            </button>
          </>
        ),
      },
    ],
    []
  );

  // Column definitions for subcategories table
  const subCategoryColumns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Subcategory Name", accessor: "sub_cat_name" },
      { Header: "Category Name", accessor: "cat_name" },
      // { Header: "Category Name", accessor: "C" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-link text-primary p-0 ms-2"
              onClick={() => handleEditSubCategory(row.original)}
            >
              Edit
            </button>
          </>
        ),
      },
    ],
    []
  );

  // Table instances for categories and subcategories
  const categoryTableInstance = useTable(
    { columns: categoryColumns, data: categories },
    useSortBy
  );

  const subCategoryTableInstance = useTable(
    { columns: subCategoryColumns, data: subCategories },
    useSortBy
  );

  // Pagination handlers
  const handleCategoryPageClick = (data) => {
    setCurrentCategoryPage(data.selected);
  };

  const handleSubCategoryPageClick = (data) => {
    setCurrentSubCategoryPage(data.selected);
  };

  // Search handlers
  const handleCategorySearch = () => {
    setCurrentCategoryPage(0); // Reset to the first page when searching
    fetchCategories(); // Trigger fetching categories when search is performed
  };

  const handleSubCategorySearch = () => {
    setCurrentSubCategoryPage(0); // Reset to the first page when searching
    fetchSubCategories(); // Trigger fetching subcategories when search is performed
  };

  const handleAddCategory = () => {
    navigate("/addCategoryPage"); // Adjust the route to your actual add category page route
  };

  const handleAddSubCategory = () => {
    navigate("/addSubCategoryPage"); // Adjust the route to your actual add subcategory page route
  };

  return (
    <div className="container mt-5">
      <div className="row">
        {/* Categories Section */}
        <div className="col-md-6">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2>Categories</h2>
            <button className="btn btn-primary" onClick={handleAddCategory}>
              Add Category
            </button>
          </div>

          {/* Category Search */}
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search categories..."
              value={categorySearchTerm}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleCategorySearch(); // Trigger search when Enter is pressed
                }
              }}
              onChange={(e) => setCategorySearchTerm(e.target.value)}
            />
            <button
              className="btn btn-outline-secondary"
              onClick={handleCategorySearch}
            >
              Search
            </button>
          </div>

          {/* Categories Table */}
          <table
            {...categoryTableInstance.getTableProps()}
            className="table table-bordered table-hover"
          >
            <thead className="table">
              {categoryTableInstance.headerGroups.map(
                (headerGroup, headerGroupIndex) => {
                  const { key, ...restHeaderGroupProps } =
                    headerGroup.getHeaderGroupProps(); // Extract the key
                  return (
                    <tr key={key || headerGroupIndex} {...restHeaderGroupProps}>
                      {headerGroup.headers.map((column, columnIndex) => {
                        const { key, ...restColumnProps } =
                          column.getHeaderProps(column.getSortByToggleProps()); // Extract the key
                        return (
                          <th key={key || columnIndex} {...restColumnProps}>
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        );
                      })}
                    </tr>
                  );
                }
              )}
            </thead>
            <tbody {...categoryTableInstance.getTableBodyProps()}>
              {categoryTableInstance.rows.map((row, rowIndex) => {
                categoryTableInstance.prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps(); // Extract the key
                return (
                  <tr key={key || rowIndex} {...restRowProps}>
                    {row.cells.map((cell, cellIndex) => {
                      const { key, ...restCellProps } = cell.getCellProps(); // Extract the key
                      return (
                        <td key={key || cellIndex} {...restCellProps}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Category Pagination */}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={totalCategoryPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handleCategoryPageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>

        {/* Subcategories Section */}
        <div className="col-md-6">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2>Subcategories</h2>
            <button className="btn btn-primary" onClick={handleAddSubCategory}>
              Add Subcategory
            </button>
          </div>

          {/* Subcategory Search */}
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search subcategories..."
              value={subCategorySearchTerm}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubCategorySearch(); // Trigger search when Enter is pressed
                }
              }}
              onChange={(e) => setSubCategorySearchTerm(e.target.value)}
            />
            <button
              className="btn btn-outline-secondary"
              onClick={handleSubCategorySearch}
            >
              Search
            </button>
          </div>

          {/* Subcategories Table */}
          <table
            {...subCategoryTableInstance.getTableProps()}
            className="table table-bordered table-hover"
          >
            <thead className="table">
              {subCategoryTableInstance.headerGroups.map(
                (headerGroup, headerGroupIndex) => {
                  const { key, ...restHeaderGroupProps } =
                    headerGroup.getHeaderGroupProps(); // Extract the key
                  return (
                    <tr key={key || headerGroupIndex} {...restHeaderGroupProps}>
                      {headerGroup.headers.map((column, columnIndex) => {
                        const { key, ...restColumnProps } =
                          column.getHeaderProps(column.getSortByToggleProps()); // Extract the key
                        return (
                          <th key={key || columnIndex} {...restColumnProps}>
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        );
                      })}
                    </tr>
                  );
                }
              )}
            </thead>
            <tbody {...subCategoryTableInstance.getTableBodyProps()}>
              {subCategoryTableInstance.rows.map((row, rowIndex) => {
                subCategoryTableInstance.prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps(); // Extract the key
                return (
                  <tr key={key || rowIndex} {...restRowProps}>
                    {row.cells.map((cell, cellIndex) => {
                      const { key, ...restCellProps } = cell.getCellProps(); // Extract the key
                      return (
                        <td key={key || cellIndex} {...restCellProps}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Subcategory Pagination */}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={totalSubCategoryPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handleSubCategoryPageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>

      {/* Modal for editing category */}
      <Modal
        show={showCategoryModal}
        onHide={() => setShowCategoryModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCategory && (
            <form onSubmit={handleUpdateCategory}>
              <div className="form-group">
                <label>Category Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedCategory.cat_name}
                  onChange={(e) =>
                    setSelectedCategory({
                      ...selectedCategory,
                      cat_name: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary mt-3">
                Update Category
              </button>
            </form>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showSubCategoryModal}
        onHide={() => setShowSubCategoryModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Subcategory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedSubCategory && (
            <form onSubmit={handleUpdateSubCategory}>
              <div className="form-group">
                <label>Subcategory Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedSubCategory.sub_cat_name}
                  onChange={(e) =>
                    setSelectedSubCategory({
                      ...selectedSubCategory,
                      sub_cat_name: e.target.value,
                    })
                  }
                  required
                />
              </div>

              {/* Category Dropdown */}
              <div className="mb-3">
                <label htmlFor="cat_id" className="form-label">
                  Category
                </label>
                <select
                  id="cat_id"
                  name="cat_id"
                  value={selectedSubCategory.cat_id}
                  onChange={(e) =>
                    setSelectedSubCategory({
                      ...selectedSubCategory,
                      cat_id: e.target.value,
                    })
                  }
                  className="form-select"
                  required
                >
                  <option value="">Select Category</option>
                  {categoriesOption.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.cat_name}
                    </option>
                  ))}
                </select>
              </div>

              <button type="submit" className="btn btn-primary mt-3">
                Update Subcategory
              </button>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CategorySubcategoryPage;
