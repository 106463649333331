import React, { useMemo, useState } from "react";
import ModService from "../../../services/modService"; // Assuming you have this service for API requests
import { useTable, useSortBy } from "react-table";
import ReactPaginate from "react-paginate";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

const PendingClientListPage = () => {
  const [clients, setClients] = useState([]); // Store clients data
  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const [totalPages, setTotalPages] = useState(1); // For pagination
  const [searchTerm, setSearchTerm] = useState(""); // For searching clients
  const [loading, setLoading] = useState(false); // To show loading state
  const [totalClients, setTotalClients] = useState(0);
  const [createdAfterDate, setCreatedAfterDate] = useState(""); // For date filter
  const [statusFilters, setStatusFilters] = useState([]); // For status multiselect

  const fetchClients = async () => {
    setLoading(true); // Set loading to true when the fetch starts
    try {
      const response = await ModService.getClients({
        page: currentPage + 1, // API expects pages starting from 1
        limit: 10, // Limiting the number of records per page
        search: searchTerm,
        status: statusFilters.join(","), // Send status as comma-separated string
        createdAfterDate: createdAfterDate,
      });
      setClients(response.data.clients); // Set fetched clients data
      setTotalPages(response.data.totalPages); // Set total number of pages
      setTotalClients(response.data.count);
    } catch (error) {
      console.error("Error fetching clients", error);
    }
    setLoading(false); // Set loading to false after the data is loaded
  };

  // Column definitions for react-table
  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "user_code_d365" },
      { Header: "Client Name", accessor: "user_name" },
      { Header: "Company", accessor: "user_company_name" },
      { Header: "Phone", accessor: "mobile_number" },
      { Header: "Email", accessor: "email_add" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("DD MMM YYYY"),
      },
    ],
    []
  );

  // Data for react-table
  const data = useMemo(() => clients, [clients]);

  // Table instance from react-table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns, data },
      useSortBy // Sorting functionality
    );

  // Handle pagination
  const handlePageClick = (data) => {
    setCurrentPage(data.selected); // Change page when user clicks pagination
  };

  // Handle search
  const handleSearch = async () => {
    setCurrentPage(0); // Reset to first page on new search
    fetchClients();
  };

  // Handle status filter change
  const handleStatusFilterChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setStatusFilters([...statusFilters, value]);
    } else {
      setStatusFilters(statusFilters.filter((status) => status !== value));
    }
  };

  // Handle CSV download
  const handleDownloadCSV = () => {
    const headers = [
      "ID",
      "Client Name",
      "Company",
      "Phone",
      "Email",
      "Status",
      "Created At",
    ];
    const rows = clients.map((client) => [
      client.user_code_d365,
      client.user_name,
      client.user_company_name,
      client.mobile_number,
      client.email_add,
      client.status,
      moment(client.created_at).format("DD MMM YYYY"),
    ]);

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += headers.join(",") + "\n";

    rows.forEach((row) => {
      csvContent += row.map((value) => `"${value}"`).join(",") + "\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "clients_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Handle PDF download
  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    doc.text("Clients Report", 14, 16);

    const headers = [
      [
        "ID",
        "Client Name",
        "Company",
        "Phone",
        "Email",
        "Status",
        "Created At",
      ],
    ];

    const data = clients.map((client) => [
      client.user_code_d365,
      client.user_name,
      client.user_company_name,
      client.mobile_number,
      client.email_add,
      client.status,
      moment(client.created_at).format("DD MMM YYYY"),
    ]);

    doc.autoTable({
      startY: 20,
      head: headers,
      body: data,
    });

    doc.save("clients_report.pdf");
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Client Report</h1>
      </div>

      {/* Filters */}
      <div className="row mb-4">
        {/* Search Bar */}
        <div className="col-md-4 mb-3">
          <label htmlFor="searchTerm" className="form-label">
            Search Clients:
          </label>
          <input
            type="text"
            id="searchTerm"
            className="form-control"
            placeholder="Search clients..."
            value={searchTerm}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch(); // Trigger search when Enter is pressed
              }
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Created After Date */}
        <div className="col-md-4 mb-3">
          <label htmlFor="createdAfterDate" className="form-label">
            Created After Date:
          </label>
          <input
            type="date"
            id="createdAfterDate"
            className="form-control"
            value={createdAfterDate}
            onChange={(e) => setCreatedAfterDate(e.target.value)}
          />
        </div>

        {/* Status Multiselect */}
        <div className="col-md-4 mb-3">
          <label className="form-label">Status:</label>
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="statusPending"
                value="pending"
                checked={statusFilters.includes("pending")}
                onChange={handleStatusFilterChange}
              />
              <label className="form-check-label" htmlFor="statusPending">
                Pending
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="statusApproved"
                value="approved"
                checked={statusFilters.includes("approved")}
                onChange={handleStatusFilterChange}
              />
              <label className="form-check-label" htmlFor="statusApproved">
                Approved
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="statusRejected"
                value="rejected"
                checked={statusFilters.includes("rejected")}
                onChange={handleStatusFilterChange}
              />
              <label className="form-check-label" htmlFor="statusRejected">
                Rejected
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Search and Download Buttons */}
      <div className="mb-4">
        <button className="btn btn-primary me-2" onClick={handleSearch}>
          Search
        </button>
        <button className="btn btn-success me-2" onClick={handleDownloadCSV}>
          Download CSV
        </button>
        <button className="btn btn-danger" onClick={handleDownloadPDF}>
          Download PDF
        </button>
      </div>

      {/* Total Clients */}
      <div className="mb-4">
        <label>
          Total Clients: &nbsp;<strong>{totalClients}</strong>
        </label>
      </div>

      {/* Loader */}
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {/* Client Table */}
          <table
            {...getTableProps()}
            className="table table-hover table-bordered"
          >
            <thead className="table">
              {headerGroups.map((headerGroup, headerGroupIndex) => {
                const { key: headerGroupKey, ...headerGroupProps } =
                  headerGroup.getHeaderGroupProps(); // Extract key from headerGroup
                return (
                  <tr
                    key={headerGroupKey || headerGroupIndex}
                    {...headerGroupProps}
                  >
                    {headerGroup.headers.map((column, columnIndex) => {
                      const { key: columnKey, ...columnProps } =
                        column.getHeaderProps(column.getSortByToggleProps()); // Extract key from column
                      return (
                        <th
                          key={columnKey || columnIndex} // Fall back to index if key is not available
                          {...columnProps}
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row); // Prepare the row for rendering
                return (
                  <tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} key={cell.column.id}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PendingClientListPage;
