// ErrorPage.js
import React from "react";

function ErrorPage({ error, errorInfo }) {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Something went wrong.</h1>
      <p style={styles.paragraph}>We're sorry for the inconvenience.</p>
      <summary>Click here to go to home page</summary>
      <a href="/">HomePage</a>
    </div>
  );
}

const styles = {
  container: {
    padding: "2rem",
    textAlign: "center",
  },
  heading: {
    color: "#ff0000",
  },
  paragraph: {
    fontSize: "1.2rem",
  },
  details: {
    marginTop: "1rem",
    textAlign: "left",
    display: "inline-block",
  },
};

export default ErrorPage;
