import api from "./api";
import TokenService from "./token.service";

const registerUser = (data) => {
  return api.post("/auth/signup", {
    data,
  });
};

const login = async (username, password) => {
  const response = await api.post("/auth/signin", {
    username,
    password,
  });
  // console.log(response);
  if (response.data.accessToken) {
    TokenService.setUser(response.data);
  }
  return response.data;
};

const logout = async () => {
  return api.post("/auth/logOut").then(async (response) => {
    TokenService.removeUser();
    return response.data;
  });
};

const forgotPassword = (email) => {
  return api.post("/user/forgotPassword", {
    email,
  });
};

const resetPassword = (hash, password) => {
  return api.post("/user/resetPassword", {
    hash,
    password,
  });
};

const verifyEmail = (id, email) => {
  return api.get("/auth/verifyemail", {
    params: {
      id,
      email,
    },
  });
};

const AuthService = {
  registerUser,
  login,
  logout,
  forgotPassword,
  resetPassword,
  verifyEmail,
};

export default AuthService;
