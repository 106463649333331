import React from "react";
import SideBarFilter from "./SideBarFilter";
const ContentSection = ({
  brandData,
  setBrandData,
  categoryData,
  setCategoryData,
  searchTerm,
  setSearchTerm,
  setBrandBannerImage,
  brandBannerImage,
}) => {
  return (
    <section>
      <div className="container ">
        <div className="row ">
          <SideBarFilter
            brandBannerImage={brandBannerImage}
            setBrandBannerImage={setBrandBannerImage}
            brand={brandData}
            setBrandData={setBrandData}
            category={categoryData}
            setCategoryData={setCategoryData}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
      </div>
    </section>
  );
};

export default ContentSection;
