import UserService from "../services/userService";
import { SET_MESSAGE, ERROR_MESSAGE, CLEAR_CART } from "./types";

export const registerUser = (userData) => (dispatch) => {
  return UserService.registerUser(userData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      const id =
        error.response && error.response.data && error.response.data.code;

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, id });
    }
  );
};

export const submitOrder = (cartData) => (dispatch) => {
  return UserService.submitOrder(cartData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      dispatch({ type: CLEAR_CART });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      const id =
        error.response && error.response.data && error.response.data.code;

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message, id });
    }
  );
};

export const updpateUserInfo = (userData) => (dispatch) => {
  return UserService.updpateUserInfo(userData).then(
    (data) => {
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message });
    }
  );
};
