import React, { useEffect, useMemo, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { AgGridReact } from "ag-grid-react";
import QuantityCellRenderer from "../../components/Shop/QuantityCellRenderer";

// Import styles
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import UserService from "../../services/userService";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

const ProductListing = () => {
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();

  // Define the columns
  const [columnDefs] = useState([
    { field: "id", headerName: "ID", hide: true },
    {
      field: "main_pics",
      headerName: "Image",
      cellRenderer: (params) => {
        return (
          <img
            src={`${process.env.REACT_APP_BACKEND_SERVER}/images/${params.value}`}
            alt={params.data.productdisplayname}
            style={{ width: "40px", height: "70px" }}
          />
        );
      },
    },
    {
      field: "product",
      headerName: "Product Name",
      sortable: true,
    },
    {
      field: "brand_name",
      headerName: "Brand",
      sortable: true,
    },
    {
      field: "cat_name",
      headerName: "Category",
      sortable: true,
    },
    {
      field: "sub_cat_name",
      headerName: "Subcategory",
      sortable: true,
    },
    {
      field: "moq",
      headerName: "MOQ",
      sortable: true,
    },
    { field: "call_price", headerName: "Price", sortable: true, filter: true },
    {
      field: "quantity",
      headerName: "Quantity",
      editable: true,
      cellEditor: QuantityCellRenderer,
      cellRenderer: (params) => params.value, // Optional
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
    };
  }, []);

  useEffect(() => {
    UserService.getProducts({ limit: 99999 }).then((res) => {
      const productsWithQuantity = res.data.products.map((product) => ({
        ...product,
        quantity: product.moq,
      }));
      console.log(productsWithQuantity);
      setRowData(productsWithQuantity);
    });
  }, []);

  const addToCart = () => {
    const selectedNodes = gridRef.current.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);

    // Filter out items with quantity >= MOQ
    const itemsToAdd = selectedData.filter((item) => item.quantity >= item.moq);

    if (itemsToAdd.length === 0) {
      alert("Please select items and ensure quantities meet MOQ.");
      return;
    } else {
      alert("selected items will be added to cart");

      // Map the items to the format expected by the cart
      const itemsForCart = itemsToAdd.map((product) => ({
        id: product.id,
        name: product.productdisplayname,
        price: product.mrp,
        tax: product.gst,
        quantity: product.quantity,
        image: product.main_pics,
      }));

      // Dispatch the action with the array of items
      dispatch(addToCart(itemsForCart));
    }

    console.log(itemsToAdd);
  };

  const onGridReady = (params) => {
    gridRef.current = params.api;
    gridRef.current.sizeColumnsToFit();
    gridRef.current.setGridOption("quickFilterText", "");
    // gridRef.current.api.setGridOption("quickFilterText", "new filter text");
  };

  // Use AG Grid's quick filter for searching
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    gridRef.current.setGridOption("quickFilterText", query);
  };

  // // Handle selection changes
  // const onSelectionChanged = () => {
  //   const selectedNodes = gridRef.current.api.getSelectedNodes();
  //   const selectedIds = selectedNodes.map((node) => node.data.id);
  //   console.log(selectedIds);
  //   setSelectedProductIds(selectedIds);
  // };

  // Re-select rows after data change
  // useEffect(() => {
  //   if (gridRef.current && selectedProductIds.length > 0) {
  //     console.log(selectedProductIds);
  //     selectedProductIds.forEach((id) => {
  //       const node = gridRef.current.api.getRowNode(id);
  //       if (node) {
  //         node.setSelected(true);
  //       }
  //     });
  //   }
  // }, [filteredRowData, selectedProductIds]);

  // Filter the products based on the search query
  // const handleSearch = (event) => {
  //   const query = event.target.value.toLowerCase();
  //   setSearchQuery(query);
  //   if (query) {
  //     const filteredData = rowData.filter(
  //       (product) =>
  //         product.product?.toLowerCase().includes(query) ||
  //         product.id.toString().includes(query)
  //     );
  //     setFilteredRowData(filteredData);
  //   } else {
  //     setFilteredRowData(rowData);
  //   }
  // };

  // useEffect(() => {
  //   if (gridRef.current && gridRef.current.api) {
  //     gridRef.current.api.forEachNode((node) => {
  //       if (node && node.data && node.isSelected()) {
  //         node.setSelected(true);
  //       }
  //     });
  //   }
  // }, [filteredRowData]);

  return (
    <>
      <Navbar />

      <div className="container mt-4">
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search by Product Name or ID"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <div className="text-end p-3">
          <Button variant="primary" onClick={addToCart}>
            Add Selected to Cart
          </Button>
        </div>

        <div
          className="ag-theme-alpine"
          style={{ width: "100%", height: "500px" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            rowSelection={rowSelection}
            domLayout="autoHeight"
            onGridReady={onGridReady}
            frameworkComponents={{ quantityCellEditor: QuantityCellRenderer }}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={[10, 20, 50]}
            getRowId={(params) => params.data.id.toString()} // Provide unique row ID
            immutableData={true} // Enable immutable data mode
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductListing;
