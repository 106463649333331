import React, { useEffect, useState } from "react";
import PaginationFooter from "./PaginationFooter";
import UserService from "../../services/userService";
// import helperfunction from "../../services/helperFunction";
import { Link } from "react-router-dom";
import HelperFunction from "../../services/helperFunction";

const ProductGrid = ({
  selectedBrands,
  selectedCategories,
  searchTerm,
  minPrice,
  maxPrice,
  brandBannerImage,
}) => {
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    UserService.getProducts({
      selectedBrands,
      selectedCategories,
      page,
      limit,
      search: searchTerm,
      minPrice,
      maxPrice,
    }).then((response) => {
      // console.log(minPrice);
      setProducts(response.data.products);
      setTotalProducts(response.data.totalProducts.count);
      setTotalPages(response.data.totalPages);
    });
  }, [
    page,
    limit,
    selectedBrands,
    selectedCategories,
    searchTerm,
    minPrice,
    maxPrice,
  ]);

  return (
    <>
      {brandBannerImage && (
        <section className="">
          <div className="container-fluid px-0">
            <article className="card border-0">
              <picture>
                <source
                  // media="(max-width:1140px)"
                  srcSet={
                    process.env.REACT_APP_BACKEND_SERVER +
                    "/images/" +
                    brandBannerImage
                  }
                />
                <img
                  src={
                    process.env.REACT_APP_BACKEND_SERVER +
                    "/images/" +
                    brandBannerImage
                  }
                  height={200}
                  className="w-100"
                  alt="Interior banner"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                    e.target.src = "/images/default.jpg"; // Set the default image
                  }}
                />
              </picture>
            </article>
          </div>
        </section>
      )}
      <div className="row p-5">
        <span className="d-block py-2">{totalProducts} items</span>

        {products.map((product) => (
          <div key={product.id} className="col-xxl-3 col-xl-4 col-sm-6 col-12">
            <figure className="card card-product-grid">
              <Link
                to={`/product/${encodeURIComponent(
                  HelperFunction.encrypt(product.id)
                )}`}
                className="img-wrap"
              >
                <img
                  src={
                    process.env.REACT_APP_BACKEND_SERVER +
                    "/images/" +
                    product.main_pics
                  }
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                    e.target.src = "/images/default.jpg"; // Set the default image
                  }}
                  alt={"Product " + product.productdisplayname}
                />
              </Link>
              <figcaption className="p-3 border-top">
                <div className="price-wrap mb-1">
                  <strong className=" text-lg">
                    {product.productdisplayname}
                  </strong>
                </div>
                <div className="price-wrap mb-1">
                  <strong
                    className={`price text-lg
                     text-decoration-line-through`}
                  >
                    MRP ₹{product.mrp}
                  </strong>
                </div>

                <div className="price-wrap mb-1">
                  <strong className={`price text-lg`}>
                    ₹{product.call_price} + GST
                  </strong>
                </div>

                <Link
                  to={`/product/${encodeURIComponent(
                    HelperFunction.encrypt(product.id)
                  )}`}
                  className="title"
                >
                  {product.product}
                </Link>
              </figcaption>
            </figure>
          </div>
        ))}
      </div>
      <PaginationFooter
        page={page}
        limit={limit}
        setLimit={setLimit}
        setPage={setPage}
        totalPages={totalPages}
      />
    </>
  );
};

export default ProductGrid;
