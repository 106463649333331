import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ModService from "../../../services/modService"; // Assuming this handles API requests
import { addSalesOrder } from "../../../actions/modertor";
import AsyncSelect from "react-select/async"; // For async dropdowns

const AddSalesOrderPage = () => {
  const dispatch = useDispatch();

  // Sales order data
  const [salesOrderData, setSalesOrderData] = useState({
    client_id: "", // Initialize as an empty string
    sales_order_number: "",
    items: [
      {
        product_id: "", // Initialize as an empty string
        rate: 0,
        net_amount: 0,
        gst: 0,
        gst_amount: 0,
        quantity: 0,
      },
    ],
    quantity: 0,
    total_net_amount: 0,
    total_gst: 0,
    total_amount: 0,
  });
  // State to hold selected client and product for AsyncSelect
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([null]);

  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalNetAmount, setTotalNetAmount] = useState(0);
  const [totalGST, setTotalGST] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  // Fetch client options for auto-complete
  const fetchClients = async (inputValue) => {
    const response = await ModService.getClients({
      search: inputValue,
    }); // Assuming this API fetches clients based on input
    return response.data.clients.map((client) => ({
      value: client.user_id,
      label: client.user_name,
      user_code_d365: client.user_code_d365,
      user_company_name: client.user_company_name,
    }));
  };

  // Fetch product options for auto-complete
  const fetchProducts = async (inputValue) => {
    const response = await ModService.getProduct({ search: inputValue }); // Assuming this API fetches products based on input
    return response.data.products.map((product) => ({
      value: product.id,
      label: product.product,
      rate: product.call_price || 0, // Set a default value to avoid uncontrolled warning
      gst: product.gst || 0, // Set a default value to avoid uncontrolled warning
    }));
  };

  // Handle client selection
  const handleClientSelect = (selectedOption) => {
    setSelectedClient(selectedOption); // Store the selected client
    setSalesOrderData({
      ...salesOrderData,
      client_id: selectedOption.value,
      clientDetails: {
        user_code_d365: selectedOption.user_code_d365,
        user_company_name: selectedOption.user_company_name,
      },
    });
  };

  // Handle item change
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...salesOrderData.items];
    updatedItems[index][field] = value || 0;

    // Auto-calculate fields based on product selection and quantity
    if (field === "quantity" || field === "rate") {
      const netAmount = (
        +updatedItems[index].rate * +updatedItems[index].quantity
      ).toFixed(2);
      const gstAmount = (+netAmount * (+updatedItems[index].gst / 100)).toFixed(
        2
      );
      updatedItems[index].net_amount = netAmount;
      updatedItems[index].gst_amount = gstAmount;
    }

    setSalesOrderData({ ...salesOrderData, items: updatedItems });
    calculateTotals(updatedItems);
  };

  // Handle product select in the sublist
  const handleProductSelect = (index, selectedOption) => {
    const updatedItems = [...salesOrderData.items];
    updatedItems[index].product_id = selectedOption.value || ""; // Set empty string if undefined
    updatedItems[index].rate = selectedOption.rate || 0; // Auto-fill rate
    updatedItems[index].gst = selectedOption.gst || 0; // Auto-fill GST

    // Store the selected product for each item
    const updatedProducts = [...selectedProducts];
    updatedProducts[index] = selectedOption;
    setSelectedProducts(updatedProducts);

    handleItemChange(index, "rate", updatedItems[index].rate); // Recalculate on rate change
  };

  // Add new item to the list
  const addItem = () => {
    setSalesOrderData({
      ...salesOrderData,
      items: [
        ...salesOrderData.items,
        {
          product_id: "",
          rate: 0,
          net_amount: 0,
          gst: 0,
          gst_amount: 0,
          quantity: 0,
        },
      ],
    });
    setSelectedProducts([...selectedProducts, null]);
  };

  // Remove item from the list
  const removeItem = (index) => {
    const updatedItems = [...salesOrderData.items];
    updatedItems.splice(index, 1);
    setSalesOrderData({ ...salesOrderData, items: updatedItems });
    calculateTotals(updatedItems);

    const updatedProducts = [...selectedProducts];
    updatedProducts.splice(index, 1); // Remove the product from the selected products array
    setSelectedProducts(updatedProducts);
  };

  // Calculate totals
  const calculateTotals = (items) => {
    const totalQuantity = items.reduce(
      (sum, item) => sum + (+item.quantity || 0),
      0
    ); // Ensure no undefined value
    const totalNetAmount = items.reduce(
      (sum, item) => sum + (+item.net_amount || 0),
      0
    );
    const totalGST = items.reduce(
      (sum, item) => sum + (+item.gst_amount || 0),
      0
    );
    const totalAmount = (totalGST + totalNetAmount).toFixed(2);
    setTotalQuantity(totalQuantity);
    setTotalNetAmount(totalNetAmount);
    setTotalGST(totalGST);
    setTotalAmount(totalAmount);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (salesOrderData.client_id === "") {
      alert("please select a client");
      return;
    }

    // Create the data object to be submitted, including the calculated totals
    const submissionData = {
      ...salesOrderData,
      quantity: totalQuantity,
      total_net_amount: totalNetAmount,
      total_gst: totalGST,
      total_amount: totalAmount,
    };

    var check = true;
    var productCheck = true;
    salesOrderData.items.forEach((item) => {
      if (item.quantity === 0) {
        check = false;
      }
      if (item.product_id === "") {
        productCheck = false;
      }
    });

    if (check && productCheck) {
      dispatch(addSalesOrder(submissionData))
        .then(() => {
          alert("Sales Order added successfully!");
          // Reset form
          setSalesOrderData({
            client_id: "", // Initialize as an empty string
            sales_order_number: "",
            items: [
              {
                product_id: "", // Initialize as an empty string
                rate: 0,
                net_amount: 0,
                gst: 0,
                gst_amount: 0,
                quantity: 0,
              },
            ],
            quantity: 0,
            total_net_amount: 0,
            total_gst: 0,
            total_amount: 0,
          });
          setTotalQuantity(0);
          setTotalNetAmount(0);
          setTotalGST(0);
          setTotalAmount(0);

          // Reset AsyncSelect selected values
          setSelectedClient(null);
          setSelectedProducts([null]);
        })
        .catch((error) => {
          if (error.status === 501) {
            alert(error.message);
          } else {
            console.error("Error adding sales order", error);
          }
        });
    } else {
      if (!productCheck) {
        alert("Please select the product");
      } else if (!check) {
        alert("quantity can't be 0");
      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card shadow p-4">
            <h2 className="text-center mb-4">Add New Sales Order</h2>

            <form onSubmit={handleSubmit}>
              {/* Select Client */}
              <div className="mb-4">
                <label className="form-label">Client</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={fetchClients}
                  onChange={handleClientSelect}
                  value={selectedClient} // Controlled value
                  placeholder="Select Client"
                />
              </div>
              {/* Display Client Details */}
              {salesOrderData.client_id && (
                <div className="mb-4">
                  <h5>Client Details:</h5>
                  <p>
                    <strong>Sonepar_customer_code:</strong>{" "}
                    {salesOrderData.clientDetails.user_code_d365}
                  </p>
                  <p>
                    <strong>Company Name:</strong>{" "}
                    {salesOrderData.clientDetails.user_company_name}
                  </p>
                </div>
              )}
              <div className="col-md-3">
                <label className="form-label">Sales Order Number</label>
                <input
                  className="form-control"
                  value={salesOrderData.sales_order_number}
                  onChange={(e) =>
                    setSalesOrderData({
                      ...salesOrderData,
                      sales_order_number: e.target.value,
                    })
                  }
                />
              </div>
              {/* Items Sublist */}
              <h4>Order Items</h4>
              {salesOrderData.items.map((item, index) => (
                <div key={index} className="row mb-3">
                  {/* Product Selection */}
                  <div className="col-md-3">
                    <label className="form-label">Product</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={fetchProducts}
                      onChange={(option) => handleProductSelect(index, option)}
                      value={selectedProducts[index]} // Controlled value
                      placeholder="Select Product"
                    />
                  </div>

                  {/* Quantity */}
                  <div className="col-md-2">
                    <label className="form-label">Quantity</label>
                    <input
                      type="number"
                      className="form-control"
                      value={item.quantity || 0} // Always controlled
                      onChange={(e) =>
                        handleItemChange(index, "quantity", e.target.value)
                      }
                    />
                  </div>

                  {/* Rate */}
                  <div className="col-md-2">
                    <label className="form-label">Rate</label>
                    <input
                      type="number"
                      className="form-control"
                      value={item.rate || 0} // Always controlled
                      readOnly
                    />
                  </div>

                  {/* Net Amount */}
                  <div className="col-md-2">
                    <label className="form-label">Net Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      value={item.net_amount || 0} // Always controlled
                      readOnly
                    />
                  </div>

                  {/* GST */}
                  <div className="col-md-1">
                    <label className="form-label">GST %</label>
                    <input
                      type="number"
                      className="form-control"
                      value={item.gst || 0} // Always controlled
                      readOnly
                    />
                  </div>

                  {/* GST Amount */}
                  <div className="col-md-2">
                    <label className="form-label">GST Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      value={item.gst_amount || 0} // Always controlled
                      readOnly
                    />
                  </div>

                  {/* Remove Item Button */}
                  <div className="col-md-1 d-flex align-items-end">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => removeItem(index)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}

              {/* Add Item Button */}
              <div className="mb-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={addItem}
                >
                  Add Item
                </button>
              </div>

              {/* Totals */}
              <h5>Total Quantity: {totalQuantity}</h5>
              <h5>Total Net Amount: {totalNetAmount}</h5>
              <h5>Total GST: {totalGST}</h5>
              <h5>Total Amount: {totalAmount}</h5>

              {/* Submit Button */}
              <button type="submit" className="btn btn-primary w-100">
                Add Sales Order
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSalesOrderPage;
