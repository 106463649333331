import {
  ERROR_MESSAGE,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  UPDATE_CART_ITEM,
} from "./types";
import UserService from "../services/userService";

export const addToCart = (data) => (dispatch) => {
  return UserService.addToCart(data).then(
    () => {
      dispatch({
        type: ADD_TO_CART,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // const id =
      //   error.response && error.response.data && error.response.data.code;

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message });
    }
  );
};

export const removeFromCart = (data) => (dispatch) => {
  return UserService.removeFromCart(data).then(
    () => {
      dispatch({
        type: REMOVE_FROM_CART,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message });
    }
  );
};

export const updateCartItem = (data) => (dispatch) => {
  return UserService.updateCartItem(data).then(
    () => {
      dispatch({
        type: UPDATE_CART_ITEM,
      });
      return Promise.resolve();
    },
    (error) => {
      console.log(error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message });
    }
  );
};

export const clearCart = () => (dispatch) => {
  return UserService.clearCart().then(
    () => {
      dispatch({
        type: CLEAR_CART,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ERROR_MESSAGE,
        payload: message,
      });

      return Promise.reject({ message });
    }
  );
};
