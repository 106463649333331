import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import UserService from "../../../services/userService";
import {
  addBanner,
  updateBanner,
  deleteBanner,
} from "../../../actions/modertor";
import { useDispatch } from "react-redux";
// import { getBanners, addBanner, updateBanner, deleteBanner } from "../../../services/bannerService";

const BannerPage = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [bannerImage, setBannerImage] = useState(null); // for storing uploaded image
  const [bannerLink, setBannerLink] = useState(""); // for storing optional link
  const [seq, setSeq] = useState(1);

  const dispatch = useDispatch();
  // Fetch banners from the backend
  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    setLoading(true);
    try {
      const response = await UserService.getBanners();
      // console.log(response.data.banners);
      setBanners(response.data.banners);
      setSeq(response.data.banners.length);
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
    setLoading(false);
  };

  const handleAddBannerClick = () => {
    if (banners.length >= 4) {
      alert("You can only add up to 4 banners.");
    } else {
      setShowModal(true);
      setSelectedBanner(null);
      setBannerImage(null);
      setBannerLink(""); // Reset link input
    }
  };

  const handleEditBanner = (banner) => {
    setSelectedBanner(banner);
    setBannerImage(null); // Reset image input for edit
    setBannerLink(banner.link || ""); // Set the existing link or empty string
    setShowModal(true);
  };

  const handleRemoveBanner = async (banner) => {
    if (window.confirm("Are you sure you want to delete this banner?")) {
      try {
        await dispatch(deleteBanner({ id: banner.id }))
          .then(() => {
            alert("Banner deleted successfully");
            fetchBanners(); // Refresh banners after submission
          })
          .catch((error) => {
            console.log(error);
          });
        fetchBanners(); // Refresh banners after deletion
      } catch (error) {
        console.error("Error deleting banner:", error);
      }
    }
  };

  const handleFileChange = (e) => {
    setBannerImage(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!bannerImage && !selectedBanner) {
      alert("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    if (bannerImage) {
      formData.append("image_url", bannerImage);
    }
    formData.append("link", bannerLink); // Add optional link to form data

    formData.append("seq", seq + 1);

    try {
      if (selectedBanner) {
        if (!bannerImage) {
          alert("Please select an image to upload.");
          return;
        }
        formData.append("id", selectedBanner.id); // For update
        await dispatch(updateBanner(formData))
          .then(() => {
            alert("Banner updated successfully");
            fetchBanners(); // Refresh banners after submission
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await dispatch(addBanner(formData))
          .then(() => {
            alert("Banner added successfully");
            fetchBanners(); // Refresh banners after submission
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setShowModal(false);
    } catch (error) {
      console.error("Error uploading banner:", error);
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Banner Management</h1>
        <Button variant="primary" onClick={handleAddBannerClick}>
          Add Banner
        </Button>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <table className="table table-hover table-bordered">
          <thead>
            <tr>
              <th>Sequence</th>
              <th>Banner Image</th>
              <th>Link</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {banners.map((banner, index) => (
              <tr key={banner.id}>
                <td>{index + 1}</td>
                <td>
                  <img
                    src={
                      process.env.REACT_APP_BACKEND_SERVER +
                      "/images/" +
                      banner.location
                    }
                    alt={`Banner ${index + 1}`}
                    width="150"
                  />
                </td>
                <td>{banner.link || "No link"}</td>
                <td>
                  <button
                    className="btn btn-link text-primary"
                    onClick={() => handleEditBanner(banner)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-link text-primary"
                    onClick={() => handleRemoveBanner(banner)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal for Adding/Editing Banner */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedBanner ? "Edit Banner" : "Add Banner"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Upload Image</label>
            <input
              type="file"
              className="form-control"
              onChange={handleFileChange}
            />
          </div>
          <div className="form-group mt-3">
            <label>Optional Link</label>
            <input
              type="url"
              className="form-control"
              placeholder="https://example.com"
              value={bannerLink}
              onChange={(e) => setBannerLink(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BannerPage;
