import React, { useState, useEffect, useRef } from "react";
import ModService from "../../../services/modService";
import { addProduct } from "../../../actions/modertor";
import { useDispatch } from "react-redux";

const AddProductPage = () => {
  const [productData, setProductData] = useState({
    ProductCode: "",
    Product: "",
    ProductDisplayName: "",
    Specifications: "",
    UOM: "",
    CallPrice: 0,
    MRP: 0,
    GST: 0,
    MOQ: 0,
    Brand_Id: "",
    Cat_Id: "",
    Sub_Cat_ID: "",
    active: true,
    metaTitle: "",
    metaDescription: "",
    tags: [],
  });

  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [images, setImages] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [mainPicture, setMainPicture] = useState(null);
  const [tagInput, setTagInput] = useState("");
  const [error, setError] = useState(false);
  const mainPictureInputRef = useRef(null);
  const picturesInputRef = useRef(null);
  const catalogsInputRef = useRef(null);
  const topRef = useRef(null); // Ref for scrolling to top
  // Fetch data for dropdowns
  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const categoryResponse = await ModService.getAllCategory();
        setCategories(categoryResponse.data);
      } catch (error) {
        console.error("Error fetching Category data", error);
      }
    };

    const fetchSubCategoryData = async () => {
      try {
        const subCategoryResponse = await ModService.getSubCategoryByCatId(
          productData.Cat_Id
        );
        setSubCategories(subCategoryResponse.data);
      } catch (error) {
        console.error("Error fetching SubCategory data", error);
      }
    };

    const fetchBrandData = async () => {
      try {
        const brandResponse = await ModService.getAllBrand();
        setBrands(brandResponse.data);
      } catch (error) {
        console.error("Error fetching Brand data", error);
      }
    };

    fetchCategoryData();
    fetchSubCategoryData();
    fetchBrandData();
  }, [productData.Cat_Id]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    }));
  };

  // Handle file uploads
  const handleMainPictureUpload = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (file && allowedExtensions.test(file.name)) {
      setMainPicture(file);
    } else {
      alert("Error: Only JPEG, JPG, and PNG formats are allowed.");
    }
  };
  const handleImageUpload = (e) => {
    const acceptedFormats = ["image/jpeg", "image/jpg", "image/png"];
    const files = Array.from(e.target.files);

    // Filter files based on accepted formats
    const validFiles = files.filter((file) =>
      acceptedFormats.includes(file.type)
    );

    // Check if there are any invalid files
    const invalidFiles = files.filter(
      (file) => !acceptedFormats.includes(file.type)
    );

    // If there are invalid files, show an error message
    if (invalidFiles.length > 0) {
      alert("Error: Only JPEG, JPG, and PNG formats are allowed.");
    }

    // Update the images array only with valid files
    setImages([...images, ...validFiles]);
  };
  const handleCatalogUpload = (e) => {
    const acceptedFormat = "application/pdf";
    const files = Array.from(e.target.files);

    // Filter files to accept only PDFs
    const validFiles = files.filter((file) => file.type === acceptedFormat);

    // Check if there are any invalid files
    const invalidFiles = files.filter((file) => file.type !== acceptedFormat);

    // If there are invalid files, show an error message
    if (invalidFiles.length > 0) {
      alert("Error: Only PDF files are allowed.");
    }

    // Update the catalogs array only with valid PDF files
    setCatalogs([...catalogs, ...validFiles]);
  };

  // Remove files
  const handleRemoveMainPicture = () => setMainPicture(null);
  const handleRemoveImage = (index) =>
    setImages(images.filter((_, i) => i !== index));
  const handleRemoveCatalog = (index) =>
    setCatalogs(catalogs.filter((_, i) => i !== index));

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (!mainPicture) {
      alert("Main Picture is required");
      return;
    }

    // Convert tags array to string
    // const tagsString = productData.tags.join(";");

    // Append form data
    // formData.append("tags", tagsString);
    Object.keys(productData).forEach((key) => {
      if (key === "tags") {
        const tempString = productData.tags
          .map((item, index) =>
            index < productData.tags.length - 1 ? item + ";" : item
          )
          .join("");
        formData.append(key, tempString);
      } else {
        formData.append(key, productData[key]);
      }
    });
    formData.append("main_picture", mainPicture);
    images.forEach((image) => formData.append("pictures", image));
    catalogs.forEach((catalog) => formData.append("catalogs", catalog));

    dispatch(addProduct(formData))
      .then(() => {
        alert("Product added successfully!");
        setProductData({
          ProductCode: "",
          Product: "",
          ProductDisplayName: "",
          Specifications: "",
          UOM: "",
          CallPrice: 0,
          MRP: 0,
          GST: 0,
          MOQ: 0,
          Brand_Id: "",
          Cat_Id: "",
          Sub_Cat_ID: "",
          active: true,
          metaTitle: "",
          metaDescription: "",
          tags: [],
        });
        setImages([]);
        setCatalogs([]);
        setMainPicture(null);
        setTagInput("");
        setError(true);
        // Reset file inputs using refs
        if (mainPictureInputRef.current) {
          mainPictureInputRef.current.value = null;
        }
        if (picturesInputRef.current) {
          picturesInputRef.current.value = null;
        }
        if (catalogsInputRef.current) {
          catalogsInputRef.current.value = null;
        }
        // Scroll to top using ref
        topRef.current.scrollIntoView({ behavior: "smooth" });
      })
      .catch((error) => {
        if (error.status === 501) {
          setError(error.message);
        } else {
          console.error("Error adding product", error.message);
        }
        // Scroll to top using ref
        topRef.current.scrollIntoView({ behavior: "smooth" });
      });
  };

  // Tag management
  const handleTagInput = (e) => setTagInput(e.target.value);
  const handleTagKeyPress = (e) => {
    if (e.key === "Enter" && tagInput.trim()) {
      setProductData((prevData) => ({
        ...prevData,
        tags: [...prevData.tags, tagInput.trim()],
      }));
      setTagInput("");
      e.preventDefault();
    }
  };
  const handleRemoveTag = (index) =>
    setProductData((prevData) => ({
      ...prevData,
      tags: prevData.tags.filter((_, i) => i !== index),
    }));

  return (
    <div className="container mt-5">
      <div ref={topRef}></div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow p-4">
            <h2 className="text-center mb-4">Add New Product</h2>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              {/* Product Information */}
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="ProductCode" className="form-label">
                    Product Code<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    required
                    type="text"
                    id="ProductCode"
                    name="ProductCode"
                    className={`form-control ${error ? "is-invalid" : ""}`}
                    value={productData.ProductCode}
                    onChange={handleChange}
                  />
                  {error && <div className="invalid-feedback">{error}</div>}
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="Product" className="form-label">
                    Product<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    required
                    type="text"
                    id="Product"
                    name="Product"
                    className="form-control"
                    value={productData.Product}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="ProductDisplayName" className="form-label">
                    Product Display Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    required
                    type="text"
                    id="ProductDisplayName"
                    name="ProductDisplayName"
                    className="form-control"
                    value={productData.ProductDisplayName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="Specifications" className="form-label">
                    Specifications
                  </label>
                  <textarea
                    id="Specifications"
                    name="Specifications"
                    className="form-control"
                    value={productData.Specifications}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              {/* UOM and Pricing */}
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label htmlFor="UOM" className="form-label">
                    UOM
                  </label>
                  <input
                    type="text"
                    id="UOM"
                    name="UOM"
                    className="form-control"
                    value={productData.UOM}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="CallPrice" className="form-label">
                    Call Price
                  </label>
                  <input
                    type="number"
                    id="CallPrice"
                    name="CallPrice"
                    className="form-control"
                    value={productData.CallPrice}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="MRP" className="form-label">
                    MRP
                  </label>
                  <input
                    type="number"
                    id="MRP"
                    name="MRP"
                    className="form-control"
                    value={productData.MRP}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="GST" className="form-label">
                    GST
                  </label>
                  <input
                    type="number"
                    id="GST"
                    name="GST"
                    className="form-control"
                    value={productData.GST}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="MOQ" className="form-label">
                    MOQ
                  </label>
                  <input
                    type="number"
                    id="MOQ"
                    name="MOQ"
                    className="form-control"
                    value={productData.MOQ}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Dropdowns for Brand, Category, SubCategory */}
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label htmlFor="Brand_Id" className="form-label">
                    Brand
                  </label>
                  <select
                    id="Brand_Id"
                    name="Brand_Id"
                    className="form-select"
                    value={productData.Brand_Id}
                    onChange={handleChange}
                  >
                    <option value="">Select Brand</option>
                    {brands.map((brand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.brand_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="Cat_Id" className="form-label">
                    Category
                  </label>
                  <select
                    id="Cat_Id"
                    name="Cat_Id"
                    className="form-select"
                    value={productData.Cat_Id}
                    onChange={handleChange}
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.cat_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="Sub_Cat_ID" className="form-label">
                    Sub Category
                  </label>
                  <select
                    id="Sub_Cat_ID"
                    name="Sub_Cat_ID"
                    className="form-select"
                    value={productData.Sub_Cat_ID}
                    onChange={handleChange}
                  >
                    <option value="">Select Sub Category</option>
                    {subCategories.map((subCategory) => (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.sub_cat_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Tags Input */}
              <div className="mb-3">
                <label className="form-label">Tags</label>
                <input
                  type="text"
                  className="form-control"
                  value={tagInput}
                  onChange={handleTagInput}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleTagKeyPress(e);
                    }
                  }}
                  placeholder="Type and press Enter to add tags"
                />
                <div className="tags-list mt-2">
                  {productData.tags.map((tag, index) => (
                    <span key={index} className="badge bg-primary me-2">
                      {tag}{" "}
                      <button
                        type="button"
                        className="btn-close btn-sm"
                        onClick={() => handleRemoveTag(index)}
                      ></button>
                    </span>
                  ))}
                </div>

                <div className="mb-3">
                  <label htmlFor="metaTitle" className="form-label">
                    Meta Title
                  </label>
                  <input
                    type="text"
                    id="metaTitle"
                    name="metaTitle"
                    className="form-control"
                    value={productData.metaTitle}
                    onChange={handleChange}
                  />
                </div>
                <div className=" mb-3">
                  <label htmlFor="metaDescription" className="form-label">
                    Meta Description
                  </label>
                  <textarea
                    id="metaDescription"
                    name="metaDescription"
                    className="form-control"
                    value={productData.metaDescription}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              {/* Main Picture */}
              <div className="mb-3">
                <label htmlFor="main_picture" className="form-label">
                  Main Picture <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="file"
                  id="main_picture"
                  className="form-control"
                  accept="image/*"
                  onChange={handleMainPictureUpload}
                  ref={mainPictureInputRef}
                  required
                />
                {mainPicture && (
                  <div className="mt-2">
                    <img
                      src={URL.createObjectURL(mainPicture)}
                      alt="Preview"
                      className="img-thumbnail"
                      style={{ width: "150px" }}
                    />
                    <button
                      type="button"
                      className="btn btn-danger mt-2"
                      onClick={handleRemoveMainPicture}
                    >
                      Remove Main Picture
                    </button>
                  </div>
                )}
              </div>

              {/* Images */}
              <div className="mb-3">
                <label htmlFor="pictures" className="form-label">
                  Pictures
                </label>
                <input
                  type="file"
                  multiple
                  id="pictures"
                  className="form-control"
                  accept="image/*"
                  onChange={handleImageUpload}
                  ref={picturesInputRef}
                />
                <div className="mt-2">
                  {images.map((image, index) => (
                    <div key={index} className="d-inline-block me-2">
                      <img
                        src={URL.createObjectURL(image)}
                        alt="Preview"
                        className="img-thumbnail"
                        style={{ width: "100px" }}
                      />
                      <button
                        type="button"
                        className="btn btn-danger btn-sm mt-1"
                        onClick={() => handleRemoveImage(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Catalogs (PDFs) */}
              <div className="mb-3">
                <label htmlFor="catalogs" className="form-label">
                  Catalog (PDF)
                </label>
                <input
                  type="file"
                  multiple
                  id="catalogs"
                  className="form-control"
                  accept="application/pdf"
                  onChange={handleCatalogUpload}
                  ref={catalogsInputRef}
                />
                <div className="mt-2">
                  {catalogs.map((catalog, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <span>{catalog.name}</span>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm ms-2"
                        onClick={() => handleRemoveCatalog(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Active Checkbox */}
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="active"
                  name="active"
                  checked={productData.active}
                  onChange={handleChange}
                />
                <label htmlFor="active" className="form-check-label">
                  Active
                </label>
              </div>

              {/* Submit Button */}
              <button type="submit" className="btn btn-primary">
                Add Product
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductPage;
