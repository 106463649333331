import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addClient } from "../../../actions/modertor";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import UserService from "../../../services/userService";

const AddClientPage = () => {
  const [clientData, setClientData] = useState({
    user_code_d365: "",
    firstName: "",
    lastName: "",
    user_name: "",
    user_company_name: "",
    city: "",
    state: "",
    country: "",
    email_add: "",
    mobile_number: "",
    business_type: "",
    estimated_MRO_spend: "",
    sonepar_sales_associates: "",
    nature_of_business: "",
    industry: "",
    no_of_employee: "",
    product_looking_for: "",
    password: "",
    active: true,
  });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [passwordValid, setPasswordValid] = useState(true);
  const [errorMessages, setErrorMessages] = useState({
    user_code_d365: "",
    user_name: "",
    email_add: "",
    mobile_number: "",
  });
  const dispatch = useDispatch();
  const [businessTypes, setBusinessTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [MRO, setMRO] = useState([]);
  const [EMPLOYEES, setEmployees] = useState([]);
  const [productNeeded, setProductNeeded] = useState([]);

  useEffect(() => {
    UserService.getBusinessType().then((res) => {
      setBusinessTypes(res.data);
    });
  }, []);

  useEffect(() => {
    UserService.getIndustries().then((res) => {
      setIndustries(res.data || []);
    });
  }, []);

  useEffect(() => {
    UserService.getMROOptions().then((res) => {
      setMRO(res.data || []);
    });
  }, []);

  useEffect(() => {
    UserService.getEmployeeOptions().then((res) => {
      setEmployees(res.data || []);
    });
  }, []);

  useEffect(() => {
    UserService.getProductNeededOptions().then((res) => {
      setProductNeeded(res.data || []);
    });
  }, []);

  // const [businessTypes] = useState([
  //   "Corporation",
  //   "Private Limited",
  //   "Government",
  //   "Joint Venture",
  //   "LLC",
  //   "LLP",
  //   "Partnership",
  //   "Proprietorship",
  //   "Other",
  // ]);
  // const [industries] = useState([
  //   "Aerospace",
  //   "Automotive",
  //   "Boat Building",
  //   "Chemical Manufacturing",
  //   "Construction or Mining Equipment",
  //   "HVAC System Manufacturing",
  //   "General/Industrial Manufacturing",
  //   "PetroChemical",
  //   "Pharmaceutical",
  //   "Rail Transportation",
  //   "Recreational Vehicles",
  //   "Other",
  // ]);

  // const [MRO] = useState(["0 - ₹1Cr", "₹1Cr - ₹5 Cr", "> ₹5Cr"]);

  // const [EMPLOYEES] = useState([
  //   "< 500",
  //   "500 - 1,000",
  //   "1,000 - 2,000",
  //   "2,000 - 5,000",
  //   "5,000 - 10,000",
  //   "> 10,000",
  // ]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "product_looking_for") {
      setClientData((prevData) => ({
        ...prevData,
        product_looking_for: checked
          ? [...prevData.product_looking_for, value]
          : prevData.product_looking_for.filter((product) => product !== value),
      }));
    } else {
      setClientData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }

    // Trigger password validation if changing password
    if (name === "password") {
      validatePassword(value);
    }
  };

  // Validate password function
  const validatePassword = (password) => {
    const isValid = password.length >= 8 && /[\W_]/.test(password);
    setPasswordValid(isValid);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessages({});
    dispatch(addClient(clientData))
      .then(() => {
        alert("Client added successfully!");
        // Reset the form
        setClientData({
          user_code_d365: "",
          user_name: "",
          user_company_name: "",
          city: "",
          state: "",
          country: "",
          email_add: "",
          mobile_number: "",
          business_type: "",
          estimated_MRO_spend: "",
          sonepar_sales_associates: "",
          nature_of_business: "",
          industry: "",
          no_of_employee: "",
          product_looking_for: [],
          password: "",
          active: true,
        });
      })
      .catch((error) => {
        if (error.status === 501) {
          if (error.message.includes(1)) {
            setErrorMessages((prev) => ({
              ...prev,
              user_code_d365: "Duplicate user_code_d365",
            }));
          }
          if (error.message.includes(2)) {
            setErrorMessages((prev) => ({
              ...prev,
              user_name: "Duplicate user_name",
            }));
          }
          if (error.message.includes(3)) {
            setErrorMessages((prev) => ({
              ...prev,
              email_add: "Duplicate email address",
            }));
          }
          if (error.message.includes(4)) {
            setErrorMessages((prev) => ({
              ...prev,
              mobile_number: "Duplicate mobile number",
            }));
          }
        } else {
          console.error("Error adding client", error);
        }
      });
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow p-4">
            <h2 className="text-center mb-4">Add New Client</h2>
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="row">
                {/* Sonepar Customer Code
                 */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Sonepar Customer Code
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="user_code_d365"
                    className={`form-control ${
                      errorMessages.user_code_d365 ? "is-invalid" : ""
                    }`}
                    value={clientData.user_code_d365}
                    onChange={handleChange}
                  />
                  {errorMessages.user_code_d365 && (
                    <div className="invalid-feedback">
                      {errorMessages.user_code_d365}
                    </div>
                  )}
                </div>

                {/* User Name */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    User Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="user_name"
                    className={`form-control ${
                      errorMessages.user_name ? "is-invalid" : ""
                    }`}
                    value={clientData.user_name}
                    onChange={handleChange}
                  />
                  {errorMessages.user_name && (
                    <div className="invalid-feedback">
                      {errorMessages.user_name}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                {/* Company Name */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    First Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="firstName"
                    className="form-control"
                    value={clientData.firstName}
                    onChange={handleChange}
                  />
                </div>

                {/* City */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Last Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="lastName"
                    className="form-control"
                    value={clientData.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row">
                {/* Company Name */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">Company Name</label>
                  <input
                    type="text"
                    name="user_company_name"
                    className="form-control"
                    value={clientData.user_company_name}
                    onChange={handleChange}
                  />
                </div>

                {/* City */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    value={clientData.city}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row">
                {/* State */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">State</label>
                  <input
                    type="text"
                    name="state"
                    className="form-control"
                    value={clientData.state}
                    onChange={handleChange}
                  />
                </div>

                {/* Country */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">Country</label>
                  <input
                    type="text"
                    name="country"
                    className="form-control"
                    value={clientData.country}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row">
                {/* Email */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Email<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    required
                    type="email"
                    name="email_add"
                    className={`form-control ${
                      errorMessages.email_add ? "is-invalid" : ""
                    }`}
                    value={clientData.email_add}
                    onChange={handleChange}
                  />
                  {errorMessages.email_add && (
                    <div className="invalid-feedback">
                      {errorMessages.email_add}
                    </div>
                  )}
                </div>

                {/* Mobile Number */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Mobile Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="mobile_number"
                    className={`form-control ${
                      errorMessages.mobile_number ? "is-invalid" : ""
                    }`}
                    value={clientData.mobile_number}
                    onChange={handleChange}
                  />
                  {errorMessages.mobile_number && (
                    <div className="invalid-feedback">
                      {errorMessages.mobile_number}
                    </div>
                  )}
                </div>
              </div>

              {/* Business Type */}
              <div className="mb-3">
                <label className="form-label">Business Type</label>
                <select
                  name="business_type"
                  className="form-select"
                  value={clientData.business_type}
                  onChange={handleChange}
                >
                  <option value="">Select Business Type</option>
                  {businessTypes.map((item) => (
                    <option key={"business" + item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Industry */}
              <div className="mb-3">
                <label className="form-label">Industry</label>
                <select
                  name="industry"
                  className="form-select"
                  value={clientData.industry}
                  onChange={handleChange}
                >
                  <option value="">Select Industry</option>
                  {industries.map((item) => (
                    <option key={"industries" + item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="row">
                {/* Estimated MRO Spend */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">Estimated MRO Spend</label>
                  <select
                    name="estimated_MRO_spend"
                    className="form-select"
                    value={clientData.estimated_MRO_spend}
                    onChange={handleChange}
                  >
                    <option value="">Select MRO Spend</option>
                    {MRO.map((item) => (
                      <option key={"MRO" + item.name} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Sonepar Sales Associates */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">Sonepar Sales Associates</label>
                  <input
                    type="text"
                    name="sonepar_sales_associates"
                    className="form-control"
                    value={clientData.sonepar_sales_associates}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Nature of Business */}
              <div className="mb-3">
                <label className="form-label">Nature of Business</label>
                <input
                  type="text"
                  name="nature_of_business"
                  className="form-control"
                  value={clientData.nature_of_business}
                  onChange={handleChange}
                />
              </div>

              {/* Number of Employees */}
              <div className="mb-3">
                <label className="form-label">Number of Employees</label>
                <select
                  name="no_of_employee"
                  className="form-select"
                  value={clientData.no_of_employee}
                  onChange={handleChange}
                >
                  <option value="">Select No of Employees</option>
                  {EMPLOYEES.map((item) => (
                    <option key={"employees" + item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Products Looking For */}
              <div className="mb-3">
                <label className="form-label">Products Looking For</label>

                <div className="row mb-3">
                  <div className="col-md-6">
                    {productNeeded.map((item) => (
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="productsServices"
                          value={item.name}
                          onChange={handleChange}
                          className="form-check-input"
                          id={item.name}
                        />
                        <label className="form-check-label" htmlFor={item.name}>
                          {item.name}
                        </label>
                      </div>
                    ))}

                    {/* Add more checkboxes as needed */}
                  </div>
                </div>
              </div>

              {/* Password with show/hide option */}
              <div className="mb-3 position-relative">
                <label className="form-label">
                  Password<span style={{ color: "red" }}>*</span>
                </label>
                <div className="input-group">
                  <input
                    required
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className={`form-control ${
                      !passwordValid ? "is-invalid" : ""
                    }`}
                    value={clientData.password}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <span
                    className="input-group-text"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                  {!passwordValid && (
                    <div className="invalid-feedback d-block">
                      Password must be at least 8 characters long and include a
                      special character.
                    </div>
                  )}
                </div>
              </div>

              <button type="submit" className="btn btn-primary w-100">
                Add Client
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClientPage;
