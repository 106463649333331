import React, { useCallback, useEffect, useMemo, useState } from "react";
import ModService from "../../../services/modService";
import { useTable, useSortBy } from "react-table";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateBrand } from "../../../actions/modertor";

const BrandPage = () => {
  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false); // For Edit brand modal visibility
  const [brandImage, setbrandImage] = useState(null);
  const [brandImageBanner, setbrandImageBanner] = useState(null);
  // Handle file uploads
  // Handle file uploads
  const handleBrandImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setbrandImage(e.target.files[0]);
    } else {
      alert("Error: Only JPEG, JPG, and PNG formats are allowed.");
    }
  };

  // Handle file uploads
  const handleBrandImageBannerUpload = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setbrandImageBanner(e.target.files[0]);
    } else {
      alert("Error: Only JPEG, JPG, and PNG formats are allowed.");
    }
  };

  // Remove files
  const handleRemoveBrandImage = () => setbrandImage(null);

  // Remove files
  const handleRemoveBrandImageBanner = () => setbrandImageBanner(null);

  const navigate = useNavigate();
  // Fetch brands from the backend
  useEffect(() => {
    const fetchBrands = async () => {
      setLoading(true); // Set loading to true when the fetch starts
      try {
        const response = await ModService.getBrand({
          page: currentPage + 1,
          limit: 10,
        });
        setBrands(response.data.brands);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching brands", error);
      }
      setLoading(false); // Set loading to false after the data is loaded
    };

    fetchBrands();
  }, [currentPage]);

  const fetchBrands = async () => {
    setLoading(true); // Set loading to true when the fetch starts
    try {
      const response = await ModService.getBrand({
        page: currentPage + 1,
        limit: 10,
      });
      setBrands(response.data.brands);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching brands", error);
    }
    setLoading(false); // Set loading to false after the data is loaded
  };

  //Handle Edit Product
  const handleEditBrand = useCallback((brand) => {
    // product.catalogs = [];
    setSelectedBrand(brand);
    setbrandImage(brand.brandImage);
    setShowEditModal(true);
  }, []);

  // Column definitions for react-table
  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      {
        Header: "Image",
        accessor: "brandImage",
        Cell: ({ row }) => (
          <img
            src={`${process.env.REACT_APP_BACKEND_SERVER}/images/${row.original.brandImage}`}
            alt="Brand"
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop in case the default image also fails
              e.target.src = "/images/default.jpg"; // Set the default image
            }}
            className="img-thumbnail"
            style={{ width: "50px", height: "50px", objectFit: "cover" }}
          />
        ),
      },

      {
        Header: "Banner",
        accessor: "banenr",
        Cell: ({ row }) => (
          <img
            src={`${process.env.REACT_APP_BACKEND_SERVER}/images/${row.original.banner}`}
            alt="banner"
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop in case the default image also fails
              e.target.src = "/images/default.jpg"; // Set the default image
            }}
            className="img-thumbnail"
            style={{ width: "50px", height: "50px", objectFit: "cover" }}
          />
        ),
      },
      { Header: "Brand Name", accessor: "brand_name" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-link text-primary p-0 ms-2"
              style={{ display: "inline", verticalAlign: "baseline" }}
              onClick={() => handleEditBrand(row.original)}
            >
              Edit
            </button>
          </>
        ),
      },
    ],
    [handleEditBrand]
  );

  // Data for react-table
  const data = useMemo(() => brands, [brands]);

  // Table instance from react-table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns, data },
      useSortBy // Sorting functionality
    );

  // Handle pagination
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  // Handle search
  const handleSearch = async () => {
    setLoading(true); // Set loading to true when search starts
    try {
      const response = await ModService.getBrand({
        page: currentPage + 1,
        limit: 10,
        search: searchTerm,
      });
      setBrands(response.data.brands);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error searching brands", error);
    }
    setLoading(false); // Set loading to false after search completes
  };

  // Navigate to Add Brand page when button is clicked
  const handleAddBrandClick = () => {
    navigate("/addBrandPage"); // Adjust the route to your actual add brand page route
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false); // Close edit modal
    setSelectedBrand(null); // Clear selected product data
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedBrand((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    }));
  };

  // Handle form submission
  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData1 = new FormData();

    if (!brandImage) {
      alert("Brand Image is required");
      return;
    }

    formData1.append("id", selectedBrand.id);
    formData1.append("brand_name", selectedBrand.brand_name);
    formData1.append("brandImage", brandImage);
    formData1.append("brandImageBanner", brandImageBanner);

    dispatch(updateBrand(formData1))
      .then(() => {
        alert("Product Updated successfully!");
        setSelectedBrand(null);
        setbrandImage(null);
        setbrandImageBanner(null);
        fetchBrands();
        setShowEditModal(false);
      })
      .catch((error) => {
        if (error.status === 501) {
          alert(error.message);
        } else {
          console.error("Error adding product", error);
        }
      });
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Brand Page</h1>
        {/* Button on the top-right corner */}
        <button className="btn btn-primary" onClick={handleAddBrandClick}>
          Add Brand
        </button>
      </div>

      {/* Search Bar */}
      <div className="input-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Search brands..."
          value={searchTerm}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch(); // Trigger search when Enter is pressed
            }
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-outline-secondary" onClick={handleSearch}>
          Search
        </button>
      </div>

      {/* Loader */}
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {/* Brand Table */}
          <table
            {...getTableProps()}
            className="table  table-hover table-bordered"
          >
            <thead className="table">
              {headerGroups.map((headerGroup, headerGroupIndex) => {
                const { key: headerGroupKey, ...headerGroupProps } =
                  headerGroup.getHeaderGroupProps(); // Extract key from headerGroup
                return (
                  <tr
                    key={headerGroupKey || headerGroupIndex}
                    {...headerGroupProps}
                  >
                    {headerGroup.headers.map((column, columnIndex) => {
                      const { key: columnKey, ...columnProps } =
                        column.getHeaderProps(column.getSortByToggleProps()); // Extract key from column
                      return (
                        <th
                          key={columnKey || columnIndex} // Fall back to index if key is not available
                          {...columnProps}
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                // Extract the key from the row props
                // const { key: rowKey, ...rowProps } = row.getRowProps();
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} key={cell.column.id}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </>
      )}

      {/* Modal for Editing product details */}
      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedBrand && showEditModal ? (
            <div className="p-4">
              <form onSubmit={handleUpdate} encType="multipart/form-data">
                {/* Product Information */}
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="ProductCode" className="form-label">
                      Brand<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      id="brand_name"
                      name="brand_name"
                      className="form-control"
                      value={selectedBrand.brand_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* Main Picture */}
                <div className="mb-3">
                  <label htmlFor="brandImage" className="form-label">
                    Brand Image <span style={{ color: "red" }}>*</span>
                  </label>
                  {brandImage == null && (
                    <input
                      type="file"
                      id="brandImage"
                      className="form-control"
                      accept="image/*"
                      onChange={handleBrandImageUpload}
                      required
                    />
                  )}
                  {brandImage && (
                    <div className="mt-2">
                      <img
                        src={
                          brandImage instanceof Blob
                            ? URL.createObjectURL(brandImage)
                            : `${process.env.REACT_APP_BACKEND_SERVER}/images/${brandImage}`
                        }
                        onError={(e) => {
                          e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                          e.target.src = "/images/default.jpg"; // Set the default image
                        }}
                        alt="Preview"
                        className="img-thumbnail"
                        style={{ width: "150px" }}
                      />
                      <button
                        type="button"
                        className="btn btn-danger mt-2"
                        onClick={handleRemoveBrandImage}
                      >
                        Remove Brand Image
                      </button>
                    </div>
                  )}
                </div>

                {/* Banner Picture */}
                <div className="mb-3">
                  <label htmlFor="brandImage" className="form-label">
                    Banner Image <span style={{ color: "red" }}>*</span>
                  </label>
                  {brandImageBanner == null && (
                    <input
                      type="file"
                      id="bannerImage"
                      className="form-control"
                      accept="image/*"
                      onChange={handleBrandImageBannerUpload}
                    />
                  )}
                  {brandImageBanner && (
                    <div className="mt-2">
                      <img
                        src={
                          brandImageBanner instanceof Blob
                            ? URL.createObjectURL(brandImageBanner)
                            : `${process.env.REACT_APP_BACKEND_SERVER}/images/${brandImageBanner}`
                        }
                        onError={(e) => {
                          e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                          e.target.src = "/images/default.jpg"; // Set the default image
                        }}
                        alt="Preview"
                        className="img-thumbnail"
                        style={{ width: "150px" }}
                      />
                      <button
                        type="button"
                        className="btn btn-danger mt-2"
                        onClick={handleRemoveBrandImageBanner}
                      >
                        Remove Brand Banner Image
                      </button>
                    </div>
                  )}
                </div>

                {/* Active Checkbox */}
                {/* <div className="mb-3 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="active"
                            name="active"
                            checked={productData.active}
                            onChange={handleChange}
                          />
                          <label htmlFor="active" className="form-check-label">
                            Active
                          </label>
                        </div> */}

                {/* Submit Button */}
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
              </form>
            </div>
          ) : (
            <p>No product selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseEditModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BrandPage;
