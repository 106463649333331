import React from "react";
import { useLocation } from "react-router-dom";
import "./sidebars.css";
import { logout } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user: currentUser } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout())
      .then(() => navigate("/login"))
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to determine if the route is active
  const isActive = (path) => location.pathname === path;

  // Function to determine if any path in a section is active
  const isSectionActive = (paths) => paths.some((path) => isActive(path));

  // Function to filter paths based on user permissions
  const filterPathsByPermissions = (paths) => {
    if (currentUser.roles.includes(1)) {
      // Admin role has access to all paths
      return paths;
    }
    // Filter paths based on user's permissions
    return paths.filter((path) => currentUser.permissions.includes(path));
  };

  // Define paths for each section and filter them
  const dashboardPaths = filterPathsByPermissions([
    "/dashboard",
    "/quickqueries",
  ]);
  const salesOrderPaths = filterPathsByPermissions([
    "/salesOrderList",
    "/approvedSalesOrder",
    "/rejectedOrderList",
    "/dispatchPage",
  ]);
  const productsPaths = filterPathsByPermissions([
    "/productList",
    "/addProductPage",
    "/brandList",
    "/catogorySubCatagory",
  ]);
  const clientsPaths = filterPathsByPermissions([
    "/clientListing",
    "/pendingClientList",
    "/rejectedClientList",
  ]);
  const adminPaths = filterPathsByPermissions([
    "/addEmployeePage",
    "/bulkProductUpload",
    "/employeeListing",
    "/employeeProfile",
    "/bannerList",
    "/businesstype",
    "/industry",
    "/mrospend",
    "/noofemployees",
    "/productneeded",
  ]);
  const reportPaths = filterPathsByPermissions(["/pendingClientReport"]);

  return (
    <div className="sidebar flex-shrink-0 p-3" style={{ width: "280px" }}>
      <a
        href="/"
        className="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom"
      >
        <svg className="bi me-2" width="30" height="24">
          <use xlinkHref="#bootstrap" />
        </svg>
        <span className="fs-5 fw-semibold">
          <img
            src="/Sonepar-logo-White-RGB-600x183.png"
            alt="logo"
            height={75}
          />
        </span>
      </a>
      <ul className="list-unstyled ps-0">
        {/* Dashboard Section */}
        {dashboardPaths.length > 0 && (
          <li className="mb-1">
            <button
              className={`btn btn-toggle align-items-center rounded collapsed link-dark ${
                isSectionActive(dashboardPaths) ? "active" : ""
              }`}
              data-bs-toggle="collapse"
              data-bs-target="#Dashboard-collapse"
              aria-expanded={isSectionActive(dashboardPaths)}
            >
              Dashboard
            </button>
            <div
              className={`collapse ${
                isSectionActive(dashboardPaths) ? "show" : ""
              }`}
              id="Dashboard-collapse"
            >
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                {dashboardPaths.includes("/dashboard") && (
                  <li>
                    <a
                      href="/dashboard"
                      className={`link-dark rounded ${
                        isActive("/dashboard") ? "active" : ""
                      }`}
                    >
                      Dashboard
                    </a>
                  </li>
                )}
              </ul>

              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                {dashboardPaths.includes("/quickqueries") && (
                  <li>
                    <a
                      href="/quickqueries"
                      className={`link-dark rounded ${
                        isActive("/quickqueries") ? "active" : ""
                      }`}
                    >
                      Queries
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* Sales Order Section */}
        {salesOrderPaths.length > 0 && (
          <li className="mb-1">
            <button
              className={`btn btn-toggle align-items-center rounded collapsed link-dark ${
                isSectionActive(salesOrderPaths) ? "active" : ""
              }`}
              data-bs-toggle="collapse"
              data-bs-target="#Order-collapse"
              aria-expanded={isSectionActive(salesOrderPaths)}
            >
              Sales Order
            </button>
            <div
              className={`collapse ${
                isSectionActive(salesOrderPaths) ? "show" : ""
              }`}
              id="Order-collapse"
            >
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                {salesOrderPaths.includes("/salesOrderList") && (
                  <li>
                    <a
                      href="/salesOrderList"
                      className={`link-dark rounded ${
                        isActive("/salesOrderList") ? "active" : ""
                      }`}
                    >
                      Current Order List
                    </a>
                  </li>
                )}
                {salesOrderPaths.includes("/approvedSalesOrder") && (
                  <li>
                    <a
                      href="/approvedSalesOrder"
                      className={`link-dark rounded ${
                        isActive("/approvedSalesOrder") ? "active" : ""
                      }`}
                    >
                      Approved Order List
                    </a>
                  </li>
                )}
                {salesOrderPaths.includes("/dispatchPage") && (
                  <li>
                    <a
                      href="/dispatchPage"
                      className={`link-dark rounded ${
                        isActive("/dispatchPage") ? "active" : ""
                      }`}
                    >
                      Dispatches
                    </a>
                  </li>
                )}
                {salesOrderPaths.includes("/rejectedOrderList") && (
                  <li>
                    <a
                      href="/rejectedOrderList"
                      className={`link-dark rounded ${
                        isActive("/rejectedOrderList") ? "active" : ""
                      }`}
                    >
                      Cancelled Order List
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* Products Section */}
        {productsPaths.length > 0 && (
          <li className="mb-1">
            <button
              className={`btn btn-toggle align-items-center rounded collapsed link-dark ${
                isSectionActive(productsPaths) ? "active" : ""
              }`}
              data-bs-toggle="collapse"
              data-bs-target="#Products-collapse"
              aria-expanded={isSectionActive(productsPaths)}
            >
              Products
            </button>
            <div
              className={`collapse ${
                isSectionActive(productsPaths) ? "show" : ""
              }`}
              id="Products-collapse"
            >
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                {productsPaths.includes("/productList") && (
                  <li>
                    <a
                      href="/productList"
                      className={`link-dark rounded ${
                        isActive("/productList") ? "active" : ""
                      }`}
                    >
                      View Products List
                    </a>
                  </li>
                )}
                {productsPaths.includes("/addProductPage") && (
                  <li>
                    <a
                      href="/addProductPage"
                      className={`link-dark rounded ${
                        isActive("/addProductPage") ? "active" : ""
                      }`}
                    >
                      Add New Product
                    </a>
                  </li>
                )}
                {productsPaths.includes("/brandList") && (
                  <li>
                    <a
                      href="/brandList"
                      className={`link-dark rounded ${
                        isActive("/brandList") ? "active" : ""
                      }`}
                    >
                      Brand
                    </a>
                  </li>
                )}
                {productsPaths.includes("/catogorySubCatagory") && (
                  <li>
                    <a
                      href="/catogorySubCatagory"
                      className={`link-dark rounded ${
                        isActive("/catogorySubCatagory") ? "active" : ""
                      }`}
                    >
                      Category/SubCategory
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* Clients Section */}
        {clientsPaths.length > 0 && (
          <li className="mb-1">
            <button
              className={`btn btn-toggle align-items-center rounded collapsed link-dark ${
                isSectionActive(clientsPaths) ? "active" : ""
              }`}
              data-bs-toggle="collapse"
              data-bs-target="#Clients-collapse"
              aria-expanded={isSectionActive(clientsPaths)}
            >
              Clients
            </button>
            <div
              className={`collapse ${
                isSectionActive(clientsPaths) ? "show" : ""
              }`}
              id="Clients-collapse"
            >
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                {clientsPaths.includes("/clientListing") && (
                  <li>
                    <a
                      href="/clientListing"
                      className={`link-dark rounded ${
                        isActive("/clientListing") ? "active" : ""
                      }`}
                    >
                      View Clients List
                    </a>
                  </li>
                )}
                {clientsPaths.includes("/pendingClientList") && (
                  <li>
                    <a
                      href="/pendingClientList"
                      className={`link-dark rounded ${
                        isActive("/pendingClientList") ? "active" : ""
                      }`}
                    >
                      Pending Client Approval
                    </a>
                  </li>
                )}
                {clientsPaths.includes("/rejectedClientList") && (
                  <li>
                    <a
                      href="/rejectedClientList"
                      className={`link-dark rounded ${
                        isActive("/rejectedClientList") ? "active" : ""
                      }`}
                    >
                      Rejected Client List
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* Admin Section */}
        {adminPaths.length > 0 && (
          <li className="mb-1">
            <button
              className={`btn btn-toggle align-items-center rounded collapsed link-dark ${
                isSectionActive(adminPaths) ? "active" : ""
              }`}
              data-bs-toggle="collapse"
              data-bs-target="#admin-collapse"
              aria-expanded={isSectionActive(adminPaths)}
            >
              Admin
            </button>
            <div
              className={`collapse ${
                isSectionActive(adminPaths) ? "show" : ""
              }`}
              id="admin-collapse"
            >
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                {adminPaths.includes("/addEmployeePage") && (
                  <li>
                    <a
                      href="/addEmployeePage"
                      className={`link-dark rounded ${
                        isActive("/addEmployeePage") ? "active" : ""
                      }`}
                    >
                      Add New Employee
                    </a>
                  </li>
                )}
                {adminPaths.includes("/employeeListing") && (
                  <li>
                    <a
                      href="/employeeListing"
                      className={`link-dark rounded ${
                        isActive("/employeeListing") ? "active" : ""
                      }`}
                    >
                      View Employee List
                    </a>
                  </li>
                )}
                {adminPaths.includes("/employeeProfile") && (
                  <li>
                    <a
                      href="/employeeProfile"
                      className={`link-dark rounded ${
                        isActive("/employeeProfile") ? "active" : ""
                      }`}
                    >
                      Profile
                    </a>
                  </li>
                )}
                {adminPaths.includes("/bannerList") && (
                  <li>
                    <a
                      href="/bannerList"
                      className={`link-dark rounded ${
                        isActive("/bannerList") ? "active" : ""
                      }`}
                    >
                      Banners
                    </a>
                  </li>
                )}

                {adminPaths.includes("/businesstype") && (
                  <li>
                    <a
                      href="/businesstype"
                      className={`link-dark rounded ${
                        isActive("/businesstype") ? "active" : ""
                      }`}
                    >
                      Business Types
                    </a>
                  </li>
                )}
                {adminPaths.includes("/industry") && (
                  <li>
                    <a
                      href="/industry"
                      className={`link-dark rounded ${
                        isActive("/industry") ? "active" : ""
                      }`}
                    >
                      Industries
                    </a>
                  </li>
                )}

                {adminPaths.includes("/mrospend") && (
                  <li>
                    <a
                      href="/mrospend"
                      className={`link-dark rounded ${
                        isActive("/mrospend") ? "active" : ""
                      }`}
                    >
                      MRO SPEND
                    </a>
                  </li>
                )}
                {adminPaths.includes("/noofemployees") && (
                  <li>
                    <a
                      href="/noofemployees"
                      className={`link-dark rounded ${
                        isActive("/noofemployees") ? "active" : ""
                      }`}
                    >
                      NO OF EMPL.
                    </a>
                  </li>
                )}

                {adminPaths.includes("/productneeded") && (
                  <li>
                    <a
                      href="/productneeded"
                      className={`link-dark rounded ${
                        isActive("/productneeded") ? "active" : ""
                      }`}
                    >
                      Product/Services
                    </a>
                  </li>
                )}
                {adminPaths.includes("/bulkProductUpload") && (
                  <li>
                    <a
                      href="/bulkProductUpload"
                      className={`link-dark rounded ${
                        isActive("/bulkProductUpload") ? "active" : ""
                      }`}
                    >
                      Product Bulk Upload
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* Reports Section */}
        {reportPaths.length > 0 && (
          <li className="mb-1">
            <button
              className={`btn btn-toggle align-items-center rounded collapsed link-dark ${
                isSectionActive(reportPaths) ? "active" : ""
              }`}
              data-bs-toggle="collapse"
              data-bs-target="#report-collapse"
              aria-expanded={isSectionActive(reportPaths)}
            >
              Reports
            </button>
            <div
              className={`collapse ${
                isSectionActive(reportPaths) ? "show" : ""
              }`}
              id="report-collapse"
            >
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                {reportPaths.includes("/pendingClientReport") && (
                  <li>
                    <a
                      href="/pendingClientReport"
                      className={`link-dark rounded ${
                        isActive("/pendingClientReport") ? "active" : ""
                      }`}
                    >
                      Pending Clients Report
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        <li className="border-top my-3"></li>
        <button
          onClick={handleLogout}
          className="btn align-items-center rounded link-dark"
        >
          Logout
        </button>
      </ul>
    </div>
  );
}

export default Sidebar;
