import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateEmployee } from "../../../actions/modertor";
const EditEmployeePage = ({ employee, setIsUpdated }) => {
  const dispatch = useDispatch();
  const [emailValid, setEmailValid] = useState(true);
  const [mobileValid, setMobileValid] = useState(true);
  const [errorMessages, setErrorMessages] = useState({
    email_add: "",
    mobile_number: "",
  });
  const [employeeData, setEmployeeData] = useState({
    user_name: "",
    email_add: "",
    firstName: "",
    lastName: "",
    mobile_number: "",
    allowedPages: [],
  });

  const permissionIndexes = useMemo(
    () => ({
      Dashboard: 1,
      Orders: 2,
      Products: 3,
      Clients: 4,
      Admin: 5,
      Reports: 6,
    }),
    []
  );

  const permissionLabels = Object.keys(permissionIndexes);

  useEffect(() => {
    if (employee) {
      setEmployeeData({
        user_id: employee.user_id,
        user_name: employee.user_name || "",
        email_add: employee.email_add || "",
        firstName: employee.firstName || "",
        lastName: employee.lastName || "",
        mobile_number: employee.mobile_number || "",
        allowedPages: employee.permissions.map(
          (item) => permissionIndexes[item]
        ),
      });
    }
  }, [employee, permissionIndexes]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    if (!isValid) {
      setErrorMessages((prev) => ({
        ...prev,
        email_add: "Email address not valid",
      }));
      setEmailValid(false);
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        email_add: null,
      }));
      setEmailValid(true);
    }
  };

  const validateMobile = (mobile) => {
    const mobileRegex = /^\d{10}$/;
    const isValid = mobileRegex.test(mobile);

    if (!isValid) {
      setErrorMessages((prev) => ({
        ...prev,
        mobile_number: "Mobile Number not valid",
      }));
      setMobileValid(false);
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        mobile_number: null,
      }));
      setMobileValid(true);
    }
  };

  const handlePermissionsChange = (e) => {
    const { value, checked } = e.target;
    const pageId = parseInt(value, 10);

    setEmployeeData((prevData) => ({
      ...prevData,
      allowedPages: checked
        ? [...prevData.allowedPages, pageId]
        : prevData.allowedPages.filter((id) => id !== pageId),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail && validateMobile) {
      dispatch(updateEmployee(employeeData))
        .then(() => {
          alert("Employee updated successfully!");
          setIsUpdated(true);
        })
        .catch((error) => {
          console.log(error);
          if (error.message.includes(1)) {
            setErrorMessages((prev) => ({
              ...prev,
              email_add: "Duplicate email address",
            }));
          }
          if (error.message.includes(2)) {
            setErrorMessages((prev) => ({
              ...prev,
              mobile_number: "Duplicate mobile number",
            }));
          }
        });
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Employee username<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  readOnly
                  type="text"
                  name="user_name"
                  className="form-control"
                  value={employeeData.user_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  name="email_add"
                  className={`form-control ${
                    errorMessages.email_add ? "is-invalid" : ""
                  }`}
                  value={employeeData.email_add}
                  onChange={(e) => {
                    handleChange(e);
                    validateEmail(e.target.value);
                  }}
                  required
                />
                {errorMessages.email_add && (
                  <div className="invalid-feedback">
                    {errorMessages.email_add}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Mobile Number<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="mobile_number"
                  className={`form-control ${
                    errorMessages.mobile_number ? "is-invalid" : ""
                  }`}
                  value={employeeData.mobile_number}
                  onChange={(e) => {
                    handleChange(e);
                    validateMobile(e.target.value);
                  }}
                  required
                />
                {errorMessages.mobile_number && (
                  <div className="invalid-feedback">
                    {errorMessages.mobile_number}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  First Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  className={`form-control `}
                  value={employeeData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Last Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  className={`form-control `}
                  value={employeeData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Permissions</label>
              {permissionLabels.map((label) => {
                const pageId = permissionIndexes[label];
                return (
                  <div key={pageId} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={pageId}
                      id={`page-${pageId}`}
                      onChange={handlePermissionsChange}
                      checked={employeeData.allowedPages.includes(pageId)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`page-${pageId}`}
                    >
                      {label}
                    </label>
                  </div>
                );
              })}
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Update Employee
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditEmployeePage;
