import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { forgotPassword } from "../../actions/auth";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      dispatch(forgotPassword(email)).then(() => {
        alert("Password reset email sent successfully");
        // setMessage(response.data.message);
      });
      //   const response = await axios.post("/api/user/forgotPassword", { email });
      //   setMessage(response.data.message);
    } catch (err) {
      alert("Password reset email sent successfully");
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mt-5 " style={{ minHeight: "50vh" }}>
        <h2>Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label>Email Address</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Send Reset Email
          </button>
        </form>
        {message && <p className="text-success mt-3">{message}</p>}
        {error && <p className="text-danger mt-3">{error}</p>}
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
