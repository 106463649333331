import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";
import ReactPaginate from "react-paginate";
import ModService from "../../../services/modService";
import { Modal } from "react-bootstrap"; // Modal for viewing details
import moment from "moment";

const DispatchPage = () => {
  const [salesOrders, setSalesOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showDispatcDetailhModal, setShowDispatchDetailModal] = useState(false);
  const [selectedDispatch, setSelectedDispatch] = useState(null);

  useEffect(() => {
    const fetchSalesOrders = async () => {
      setLoading(true);
      try {
        const response = await ModService.getDispatchedItems({
          page: currentPage + 1,
          limit: 10,
          status: "approved",
        });

        // console.log(response.data.dispatches);

        setSalesOrders(response.data.dispatches);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching sales orders", error);
      }
      setLoading(false);
    };
    fetchSalesOrders();
  }, [currentPage]);

  const columns = useMemo(
    () => [
      { Header: "Order ID", accessor: "order_id" },
      { Header: "Sales Or. No", accessor: "sales_order_number" },
      { Header: "Client Code", accessor: "client_name" },
      {
        Header: "Cust. Name",
        accessor: "fullName", // Virtual accessor (not directly in the data)
        Cell: ({ row }) => `${row.original.firstName} ${row.original.lastName}`, // Combine first and last name
      },

      {
        Header: "Order Date",
        accessor: "order_date",
        Cell: ({ value }) => moment(value).format("DD MMM YYYY"),
      },
      {
        Header: "Dispatch Date",
        accessor: "dispatch_date",
        Cell: ({ value }) => moment(value).format("DD MMM YYYY"),
      },
      { Header: "Total Amount", accessor: "dispatch_total_amount" },
      { Header: "Invoice", accessor: "invoice_id" },
      { Header: "Tracking", accessor: "tracking_id" },

      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-link text-primary p-0 me-2"
              style={{ display: "inline", verticalAlign: "baseline" }}
              onClick={() =>
                handleViewDispatch(row.original.items, row.original)
              }
            >
              View
            </button>
          </>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => salesOrders, [salesOrders]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleSearch = async () => {
    setLoading(true); // Set loading to true when search starts
    try {
      const response = await ModService.getDispatchedItems({
        page: currentPage + 1,
        limit: 10,
        search: searchTerm,
        status: "approved",
      });
      setSalesOrders(response.data.orders);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error searching clients", error);
    }
    setLoading(false); // Set loading to false after search completes
  };

  const handleViewDispatch = (dispatch, order) => {
    // console.log(dispatch);
    setSelectedOrder(order); // Set the selected order for dispatch
    setSelectedDispatch(dispatch);
    setShowDispatchDetailModal(true);
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Sales Orders</h1>
      </div>

      <div className="input-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Search sales orders..."
          value={searchTerm}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-outline-secondary" onClick={handleSearch}>
          Search
        </button>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <table
            {...getTableProps()}
            className="table table-hover table-bordered"
          >
            <thead className="table">
              {headerGroups.map((headerGroup, headerGroupIndex) => {
                const { key, ...rest } = headerGroup.getHeaderGroupProps(); // Extract the key
                return (
                  <tr key={`headerGroup-${headerGroupIndex}`} {...rest}>
                    {headerGroup.headers.map((column, columnIndex) => {
                      const { key, ...restColumnProps } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <th
                          key={`header-${columnIndex}`} // Use key explicitly
                          {...restColumnProps} // Spread the rest of the props
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                const { key, ...rest } = row.getRowProps(); // Destructure key
                return (
                  <React.Fragment
                    key={`order-row-${row.original.id || rowIndex}`}
                  >
                    <tr key={`row-${rowIndex}`} {...rest}>
                      {row.cells.map((cell, cellIndex) => {
                        const { key, ...restCellProps } = cell.getCellProps(); // Destructure key for cells
                        return (
                          <td
                            key={`cell-${rowIndex}-${cellIndex}`}
                            {...restCellProps}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>

          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </>
      )}

      {/* Dispatch Details Modal */}
      <Modal
        show={showDispatcDetailhModal}
        onHide={() => setShowDispatchDetailModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Dispatch Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDispatch && selectedOrder && (
            <div>
              <h5>Order ID: {selectedOrder.id}</h5>
              <p>
                <strong>Order Number:</strong>{" "}
                {selectedOrder.sales_order_number}
              </p>
              <p>
                <strong>Client Code:</strong> {selectedOrder.client_name}
              </p>
              <p>
                <strong>Order Date:</strong>{" "}
                {moment(selectedOrder.created_at).format("DD MMM YYYY")}
              </p>
              <p>
                <strong>Order Total Amount:</strong> ₹
                {selectedOrder.order_total_amount}
              </p>
              <p>Invoice ID: {selectedOrder.invoice_id}</p>
              <p>Tracking ID: {selectedOrder.tracking_id}</p>
              <p>Net Amount: {selectedOrder.dispatch_net_amount}</p>
              <p>Total Gst: {selectedOrder.dispatch_total_gst}</p>
              <p>Total Amount: ₹{selectedOrder.dispatch_total_amount}</p>
              {/* Add more dispatch details here */}
              {/* Dispatched Summary */}
              <div className="mt-4">
                <h6>Dispatched Summary</h6>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Product_code</th>
                      <th>Product Name</th>
                      <th>Quantity </th>
                      <th>Net Amt (₹)</th>
                      <th>GST (₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedDispatch?.map((dispatchedItem, index) => (
                      <tr key={"DispatchedItems-" + index}>
                        <td>{dispatchedItem.product_code}</td>
                        <td>{dispatchedItem.product_name}</td>
                        <td>{dispatchedItem.dispatched_quantity}</td>
                        <td>{dispatchedItem.item_net_amount}</td>
                        <td>{dispatchedItem.item_total_gst}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DispatchPage;
