import React, { useState, useEffect } from "react";
import ModService from "../../../services/modService";
import { useDispatch } from "react-redux";
import { addSubCategory } from "../../../actions/modertor";

const AddSubCategoryPage = () => {
  const [subCategoryData, setSubCategoryData] = useState({
    subcategory_name: "",
    category_id: "",
  });
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();

  // Fetch categories for the dropdown
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await ModService.getAllCategory();
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories", error);
      }
    };

    fetchCategories();
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubCategoryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(addSubCategory(subCategoryData))
      .then(() => {
        setError(false);
        alert("Subcategory added successfully!");
        setSubCategoryData({ subcategory_name: "", category_id: "" }); // Reset form
      })
      .catch((error) => {
        if (error.status === 501) {
          alert(error.message);
          setError(error.message);
        } else {
          console.error("Error adding subcategory:", error);
        }
      });
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow p-4">
            <h2 className="text-center mb-4">Add New Subcategory</h2>
            <form onSubmit={handleSubmit}>
              {/* Subcategory Name Input */}
              <div className="mb-3">
                <label htmlFor="subcategory_name" className="form-label">
                  Subcategory Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="subcategory_name"
                  name="subcategory_name"
                  value={subCategoryData.subcategory_name}
                  onChange={handleChange}
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  placeholder="Enter subcategory name"
                  required
                />
                {error && <div className="invalid-feedback">{error}</div>}
              </div>

              {/* Category Dropdown */}
              <div className="mb-3">
                <label htmlFor="category_id" className="form-label">
                  Category
                </label>
                <select
                  id="category_id"
                  name="category_id"
                  value={subCategoryData.category_id}
                  onChange={handleChange}
                  className="form-select"
                  required
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.cat_name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Submit Button */}
              <div className="d-grid">
                <button type="submit" className="btn btn-primary">
                  Add Subcategory
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubCategoryPage;
