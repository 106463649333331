// reducers/cartReducer.js

import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_ITEM,
  CLEAR_CART,
} from "../actions/types";

// import produce from "immer";

// const cart = JSON.parse(localStorage.getItem("cartItems"));

const initialState = { count: 0 };

export default function Cart(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case ADD_TO_CART:
      return { ...state, count: state.count + 1 };

    case REMOVE_FROM_CART:
      return { ...state, count: state.count - 1 };

    case UPDATE_CART_ITEM:
      return { ...state, count: state.count + 1 };

    case CLEAR_CART:
      return { ...state, count: state.count - 1 };

    default:
      return state;
  }
}

// export default function Cart(state = initialState, action) {
//   return produce(state, (draft) => {
//     const { type, payload } = action;
//     switch (type) {
//       case ADD_TO_CART:
//         // Ensure payload is an array
//         const itemsToAdd = Array.isArray(payload) ? payload : [payload];

//         itemsToAdd.forEach((item) => {
//           const existingItem = draft.items.find((i) => i.id === item.id);
//           if (existingItem) {
//             existingItem.quantity += item.quantity;
//           } else {
//             draft.items.push({ ...item });
//           }
//         });
//         break;

//       case REMOVE_FROM_CART:
//         draft.items = draft.items.filter((item) => item.id !== payload);
//         break;

//       case UPDATE_CART_ITEM:
//         const itemToUpdate = draft.items.find((item) => item.id === payload.id);
//         if (itemToUpdate) {
//           itemToUpdate.quantity = payload.quantity;
//         }
//         break;

//       case CLEAR_CART:
//         localStorage.removeItem("cartItems");
//         break;

//       default:
//         return draft;
//     }
//   });
// }
