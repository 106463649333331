import React, { useEffect, useState } from "react";

const BannerCarousel = ({ banners }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [banners.length]);

  if (banners.length === 0) return null;

  return (
    <section>
      <div className="container-fluid px-0">
        <article className="card border-0">
          <picture>
            <source
              srcSet={
                process.env.REACT_APP_BACKEND_SERVER +
                "/images/" +
                banners[currentIndex].location
              }
            />
            <img
              src={
                process.env.REACT_APP_BACKEND_SERVER +
                "/images/" +
                banners[currentIndex].location
              }
              style={{ height: "400px" }}
              className="w-100"
              alt={`Banner ${currentIndex + 1}`}
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                e.target.src = "/images/default.jpg"; // Set the default image
              }}
            />
          </picture>
        </article>
        {/* Navigation Buttons */}
        <div className="d-flex justify-content-center mt-2">
          {banners.map((_, index) => (
            <button
              key={index}
              className={`mx-1 ${
                index === currentIndex ? "bg-primary" : "bg-secondary"
              } rounded-circle`}
              style={{ width: 10, height: 10, border: "none" }}
              onClick={() => setCurrentIndex(index)}
            ></button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BannerCarousel;
